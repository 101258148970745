export const convertRoundName = (roundId: string) => {
    switch (roundId) {
        case 'upcoming':
            return 'Upcoming';
        case 'r128':
            return 'Round of 128';
        case 'r64':
            return 'Round of 64';
        case 'r32':
            return 'Round of 32';
        case 'r16':
            return 'Round of 16';
        case 'r8':
            return 'Quarterfinals';
        case 'r4':
            return 'Semifinals';
        case 'r2':
            return 'Final';
        case 'completed':
            return 'Complete';
        default:
            return '';
    }
};