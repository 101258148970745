import {MatchDataType, RoundDataType} from '../api/types';

export const matchSorter = (a: MatchDataType, b: MatchDataType) => {
    //matches have structure 12, 34, 56, 78, etc. for each tennis match in the draw
    //sorting them by the number value will sort them like shown in the tournament draw
    return parseInt(a.id) > parseInt(b.id) ? 1 : -1;
};

export const roundSorter = (a: RoundDataType, b: RoundDataType) => {
    //rounds have structure r32, r16, r8, r4, etc. for each round in the tournament
    //sorting them by the number value after the r will sort them in chronological order
    return parseInt(a.id.substring(1)) < parseInt(b.id.substring(1)) ? 1 : -1;
};