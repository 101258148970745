import {createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import {StatisticSelectionType} from '../types';
import {RootState} from 'app/redux/store';

const playerSelectionAdapter = createEntityAdapter<StatisticSelectionType>({
    selectId: (statisticSelection) => statisticSelection.statistic.id,
});

const initialState = playerSelectionAdapter.getInitialState({
    unsavedChanges: false,
});

const playerBuildingSlice = createSlice({
    name: 'playerBuilding',
    initialState,
    reducers: {
        setPlayerSelection: playerSelectionAdapter.setAll,
        updatePlayerInSelection: playerSelectionAdapter.updateOne,
        setUnsavedChanges: (state, action) => {
            state.unsavedChanges = action.payload;
        }
    },
});

export const {
    selectAll: getPlayerSelection,
    selectById: getPlayerSelectionById,
} = playerSelectionAdapter.getSelectors<RootState>(state => state.playerBuilding);

export const {
    setPlayerSelection,
    updatePlayerInSelection,
    setUnsavedChanges,
} = playerBuildingSlice.actions;

export default playerBuildingSlice.reducer;