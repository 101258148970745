import {fb_firestore} from "../../../../App";
import {doc, setDoc} from "firebase/firestore";

interface CreateUserStorageProps {
    userUid: string;
    newPlayerName: string,
}

export const createUserStorage = async ({userUid, newPlayerName}: CreateUserStorageProps) => {

    const newUserRef = doc(fb_firestore, `users/${userUid}`);
    await setDoc(newUserRef, {playerName: newPlayerName})
}