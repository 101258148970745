import React, {useEffect, useState} from 'react';
import {Center} from 'native-base';
import AppBackground from 'components/essential/AppBackground';
import {useAppSelector} from '../../../app/redux/hooks';
import RegisterComponent from '../components/RegisterComponent';
import VerifyEmailComponent from '../components/VerifyEmailComponent';
import LoginComponent from '../components/LoginComponent';
import ColorModeSwitchButton from '../../theme/components/ColorModeSwitchButton';
import {AuthenticationScreenProps, possibleAuthComponents} from '../types';
import VersionIndicator from '../../main/helper/VersionIndicator';

//------------------------------------------------------------------------------------- Auth screens
/**
 * components screens
 * Displays either the login or the register component, depending on the current AuthState
 * @param route
 * @param navigation
 * @constructor
 */
const AuthenticationScreen = ({route, navigation}: AuthenticationScreenProps) => {

    const {initialAuthState} = route.params;

    //state
    const [currentAuthState, setCurrentAuthState] = useState(initialAuthState);

    //auth listener to show verify email
    const userLoggedIn = useAppSelector(state => state.auth.userLoggedIn);
    const user = useAppSelector(state => state.auth.user);
    useEffect(() => {
        if (userLoggedIn && !user.emailVerified && currentAuthState !== possibleAuthComponents.verifyEmail)
            setCurrentAuthState(possibleAuthComponents.verifyEmail);
    }, [userLoggedIn, user]);

    const switchAuthComponent = (newState: possibleAuthComponents) => {
        setCurrentAuthState(newState);
    };

    //render components based on authState
    const renderAuthComponent = () => {
        //check for register state
        if (currentAuthState === possibleAuthComponents.register)
            return <RegisterComponent switchAuthComponent={switchAuthComponent}/>;

        if (currentAuthState === possibleAuthComponents.verifyEmail)
            return <VerifyEmailComponent switchAuthComponent={switchAuthComponent}/>;

        return <LoginComponent switchAuthComponent={switchAuthComponent} navigation={navigation}/>;
    };

    return (
        <AppBackground safeArea>
            <Center alignItems={'center'} flexGrow={1} padding={['2', '4', '6']} py={20}>
                {renderAuthComponent()}
            </Center>
            <ColorModeSwitchButton position={'absolute'} right={5} top={5} _ios={{top: 16}}/>
            <VersionIndicator position={'absolute'} right={5} bottom={5}/>
        </AppBackground>
    );
};

export default AuthenticationScreen;