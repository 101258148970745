import {createSlice} from "@reduxjs/toolkit";
import {possibleUserDataStatus} from "./types";
import {getUserData} from "../../dashboard/functions/GetUserData";

const initialState = {
    status: possibleUserDataStatus.idle,
    error: '',
    joinedLeagues: [] as string[],
}

export const userDataSlice = createSlice({
    name: "userData",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUserData.pending, (state) => {
                state.status = possibleUserDataStatus.loading;
            })
            .addCase(getUserData.fulfilled, (state, action) => {
                state.status = possibleUserDataStatus.succeeded;
                state.joinedLeagues = action.payload.joinedLeagues;
            })
            .addCase(getUserData.rejected, (state, action) => {
                state.status = possibleUserDataStatus.failed;
                state.error = String(action.payload);
            });
    }
})

export default userDataSlice.reducer;