import {configureStore} from '@reduxjs/toolkit';
import authReducer from '../../features/authentication/authSlice';
import tournamentsReducer from '../../features/tournaments/allTournaments/api/tournamentInfoSlice';
import leaguesReducer from '../../features/tournaments/leagues/api/leagueSlice';
import athletesReducer from '../../features/tournaments/athletes/api/athleteSlice';
import tournamentDataReducer from '../../features/tournaments/tournament/api/tournamentDataSlice';
import statisticsReducer from '../../features/tournaments/statistics/api/statisticsSlice';
import playerBuildingReducer from '../../features/tournaments/leagues/vPlayerBuilding/redux/playerBuildingSlice';
import userDataReducer from '../../features/account/userData/userDataSlice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        tournaments: tournamentsReducer,
        tournamentData: tournamentDataReducer,
        leagues: leaguesReducer,
        athletes: athletesReducer,
        statistics: statisticsReducer,
        playerBuilding: playerBuildingReducer,
        userData: userDataReducer,
    },
    devTools: process.env.NODE_ENV !== 'production', //TODO check
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch