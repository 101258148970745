import {possibleRoundStates, RoundDataType} from '../../../../tournament/api/types';
import {ColorMode, HStack, Link, Pressable, Text} from 'native-base';
import {convertRoundName} from '../../../../../../app/helper/nameConversionHelper';
import React from 'react';
import {PossibleLeagueSortOptions} from '../types';

interface PlayerListPointInfoHeaderProps {
    selectedRound: RoundDataType | null,
    colorMode: ColorMode,
    isScreenBig: boolean,
    sortCallback: (sortBy: PossibleLeagueSortOptions) => void,
    scrollToPlayerItemCallback: () => void,
    isPlayerPart: boolean
}

export const PlayerListPointInfoHeader = ({
                                              selectedRound,
                                              colorMode,
                                              isScreenBig,
                                              sortCallback,
                                              scrollToPlayerItemCallback,
                                              isPlayerPart
                                          }: PlayerListPointInfoHeaderProps) => {

    if (!isScreenBig) {
        if (isPlayerPart) {
            return (
                <HStack mb={6} justifyContent={'flex-start'}>
                    <Link onPress={scrollToPlayerItemCallback} alignSelf={'center'} px={5} py={1} rounded={'full'}
                          bg={colorMode === 'light' ? 'light.layoutShade' : 'dark.layoutShade'}>
                        <Text fontSize='sm' textAlign='left'
                              color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                            Find me
                        </Text>
                    </Link>
                </HStack>
            );
        } else {
            return <></>;
        }
    }

    return (
        <HStack pr={['4', '5', '6']} mb='4' justifyContent={isPlayerPart ? 'space-between' : 'flex-end'}>
            {
                isPlayerPart ?
                    <Link onPress={scrollToPlayerItemCallback} alignSelf={'center'} px={5} py={1} rounded={'full'}
                          bg={colorMode === 'light' ? 'light.layoutShade' : 'dark.layoutShade'} mb={2}>
                        <Text fontSize='sm' textAlign='left'
                              color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                            Find me
                        </Text>
                    </Link>
                    : <></>
            }
            <HStack justifyContent='flex-end' space='3' alignItems='start'>
                {
                    selectedRound?.status === possibleRoundStates.completed ?
                        <Pressable display='flex' flexDir='row' alignItems='baseline' w='112px'
                                   onPress={() => sortCallback(PossibleLeagueSortOptions.roundPoints)}>
                            <Text fontSize='sm' textAlign='left'
                                  color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                                Points for {convertRoundName(selectedRound.id)} ↓
                            </Text>
                        </Pressable>
                        : <></>
                }
                <Pressable display='flex' flexDir='row' alignItems='baseline' w='112px'
                           onPress={() => sortCallback(PossibleLeagueSortOptions.totalPoints)}>
                    <Text fontSize='sm' textAlign='right'
                          color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                        Total Points ↓
                    </Text>
                </Pressable>
            </HStack>
        </HStack>
    );
};
