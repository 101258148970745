// noinspection ExceptionCaughtLocallyJS

import {createAsyncThunk} from '@reduxjs/toolkit';
import {fb_firestore} from '../../../../../App';
import {
    FinalWinnerData, MatchAthleteDataType,
    MatchDataType, possibleRoundIds,
    RoundDataType,
    TournamentDataType
} from '../types';
import {doc, getDoc} from 'firebase/firestore';
import {matchSorter, roundSorter} from '../../helper/tournamentDataHelper';

export const fetchTournamentData = createAsyncThunk(
    'tournamentData/fetchTournamentData',
    async (tournamentId: string, {rejectWithValue}) => {
        try {
            // console.log(`************ fetchTournamentData for tournamentId: ${tournamentId}`);

            //get all rounds for this tournament data from firestore
            // const getSubCollections = httpsCallable(fb_functions, 'getSubCollections');
            // const subCollectionsPromise = await getSubCollections({docPath: `tournamentsData/${tournamentId}`});
            // const subCollectionsData = subCollectionsPromise.data as { collections: {}[] };
            // const roundIds = subCollectionsData.collections as string[];
            const roundIds = Object.entries(possibleRoundIds).map(([value]) => value);

            // console.log(`get sub collections for tournamentId: ${tournamentId}`);
            // console.log(roundIds);

            //safety
            if (roundIds.length <= 0) {
                throw new Error(`Tournament ${tournamentId} has not been setup yet.`);
            }

            //setup rounds
            const rounds: RoundDataType[] = [];
            let finalWinnerData: FinalWinnerData = {athlete: ''};
            //iterate over the found rounds and get the data
            await Promise.all(roundIds.map(async (roundId) => {

                //catch the first round with a different structure
                if (roundId === possibleRoundIds.completed) {
                    const infoRef = doc(fb_firestore, `tournamentsData/${tournamentId}/${roundId}`, 'infos');
                    const infoSnapshot = await getDoc(infoRef);
                    if (infoSnapshot.exists())
                        finalWinnerData = infoSnapshot.data() as FinalWinnerData;
                    return false;
                }

                //get match and info data for this round
                const infoRef = doc(fb_firestore, `tournamentsData/${tournamentId}/${roundId}`, 'infos');
                const matchesRef = doc(fb_firestore, `tournamentsData/${tournamentId}/${roundId}`, 'matches');

                //load the data
                const infoSnapshot = await getDoc(infoRef);
                const matchesSnapshot = await getDoc(matchesRef);

                //safety
                if (!infoSnapshot.exists()) {
                    // throw new Error('No data found for roundId: ' + roundId);
                } else {
                    //get the round info from the snapshot
                    const roundInfoData = infoSnapshot.data();
                    const roundData: RoundDataType = {
                        id: roundId,
                        startDate: roundInfoData.startDate.toDate().toJSON(),
                        endDate: roundInfoData.endDate.toDate().toJSON(),
                        status: roundInfoData.status,
                        matches: [],
                        isEntry: roundInfoData.isEntry,
                    };

                    if (matchesSnapshot.exists()) {
                        //iterate over the items in the matchesSnapshot
                        Object.entries(matchesSnapshot.data()).map(([key, match]) => {
                            //setup the match data for this iterated object
                            const matchData: MatchDataType = {
                                id: key,
                                athleteOne: {id: '', performance: []},
                                athleteTwo: {id: '', performance: []},
                            };
                            //read the athletes from the match object
                            Object.entries(match).map(([key, performance]: [string, any]) => {

                                const athleteData: MatchAthleteDataType = {
                                    id: key.substring(2), //remove 0_ or 1_ used for sorting in database
                                    performance: [],
                                };
                                //read the performance data
                                Object.entries(performance).map(([key, value]) => {
                                    athleteData.performance.push({
                                        id: key,
                                        value: value as number,
                                    });
                                    return true;
                                });

                                // console.log(`setting: ${matchData.id} - key: ${key} -  player: ${key.startsWith('0_') ? '0' : '1'} - id: ${key} - setting: ${athleteData.id}`)

                                // matchData.athletes.push(athleteData);
                                if (key.startsWith('0_')) {
                                    matchData.athleteOne = athleteData;
                                } else if (key.startsWith('1_')) {
                                    matchData.athleteTwo = athleteData;
                                }

                                return true;
                            });

                            roundData.matches.push(matchData);
                            return true;
                        });

                        //sort matches
                        roundData.matches.sort(matchSorter);
                    }

                    //add the round data to the rounds array
                    rounds.push(roundData);
                }
            }));

            //sort rounds
            rounds.sort(roundSorter);

            // console.log(rounds);
            // console.log(finalRoundInfos);

            //create tournamentData object
            const tournamentData: TournamentDataType = {
                id: tournamentId,
                rounds,
                finalRoundInfos: finalWinnerData,
            };

            //return data to redux
            return tournamentData;

        } catch (e) {
            console.log('error in tournament Id get');
            console.log(e.message);
            return rejectWithValue(e.message);
        }
    },
);