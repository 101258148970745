import React, {useEffect, useRef} from 'react';
import {
    Box,
    Flex,
    useColorMode, useTheme,
} from 'native-base';
import AppBackground from 'components/essential/AppBackground';
import {useAppDispatch, useAppSelector} from '../../app/redux/hooks';
import {useWindowDimensions} from 'react-native';
import {
    getTournamentById,
    getTournamentInfo,
    possibleTournamentInfoStatus
} from '../tournaments/allTournaments/api/tournamentInfoSlice';
import {DashboardProps, possibleMainScreens} from '../main/types';
import {possibleTournamentScreens} from '../tournaments/types';
import DashboardPlayerHeader from './components/DashboardPlayerHeader';
import FeaturedTournamentCard from './components/FeaturedTournamentCard';
import TrophyModal from './components/TrophyModal';
import LeagueList from './components/LeagueList';
import Survey from './components/Survey';
import {possibleUserDataStatus} from '../account/userData/types';
import {getUserData} from './functions/GetUserData';
import {fetchLeaguesByIds} from '../tournaments/leagues/api/functions/fetchLeaguesByIds';
import {selectLeagueByUserUid} from '../tournaments/leagues/api/leagueSlice';
import {store} from '../../app/redux/store';

const Dashboard = ({navigation}: DashboardProps) => {

    //helper
    const {colorMode} = useColorMode();
    const {colors} = useTheme();
    const dispatch = useAppDispatch();

    //data
    const user = useAppSelector(state => state.auth.user);

    //tournament
    const tournamentId = 'geneva-2022';
    const tournamentInfo = useAppSelector(state => getTournamentById(state, tournamentId));
    const tournamentInfoStatus = useAppSelector(state => state.tournaments.status);
    //dates
    const [startDate, setStartDate] = React.useState<Date>();
    const [endDate, setEndDate] = React.useState<Date>();

    //userData
    const userDataStatus = useAppSelector(state => state.userData.status);
    const usersJoinedLeagues = useAppSelector(state => state.userData.joinedLeagues);

    //leagues
    //!!! needed to re-render dashboard on league fetch
    const leaguesInfoStatus = useAppSelector(state => state.leagues.status);
    //@ts-ignore
    const playersLeagues = selectLeagueByUserUid(store.getState(), user.uid);

    //ui
    const isScreenBig = useWindowDimensions().width > 768;
    const isSurveyVisible = false;
    const [trophyModalOpen, setTrophyModalOpen] = React.useState(false);

    //-------------------------------------------------------------------- startup
    //get the user data and tournament info
    const initialFetchDone = useRef(false);
    useEffect(() => {
        return () => {
            if (!initialFetchDone.current) {
                initialFetchDone.current = true;
                //get tournament Info
                if (tournamentInfoStatus === possibleTournamentInfoStatus.idle || tournamentInfo === undefined)
                    dispatch(getTournamentInfo());
                if (userDataStatus === possibleUserDataStatus.idle || usersJoinedLeagues.length === 0)
                    dispatch(getUserData({userUid: user.uid}));
            }
        };
    }, []);

    //get the leagues once the user data is fetched
    useEffect(() => {
        if (userDataStatus === possibleUserDataStatus.succeeded && usersJoinedLeagues.length > 0) {
            dispatch(fetchLeaguesByIds({leagueIds: usersJoinedLeagues, userUid: user.uid}));
        }
    }, [usersJoinedLeagues]);

    //helper to convert dates once the tournament info is fetched
    useEffect(() => {
        if (tournamentInfo !== undefined) {
            setStartDate(new Date(tournamentInfo.startDate));
            setEndDate(new Date(tournamentInfo.endDate));
        }
    }, [tournamentInfo]);

    //-------------------------------------------------------------------- callbacks
    const viewTournamentCallback = () => {
        navigation.navigate(possibleMainScreens.Tournaments, {
            screen: possibleTournamentScreens.Tournament,
            params: {
                tournamentId: tournamentId
            }
        });
    };

    const viewLeagueCallback = (leagueId: string, tournamentId: string) => {
        // console.log('goto league: ' + leagueId)
        navigation.navigate(possibleMainScreens.Tournaments, {
            screen: possibleTournamentScreens.League,
            params: {
                leagueId: leagueId,
                tournamentId: tournamentId
            }
        });
    };

    if (trophyModalOpen) {
        return (
            <TrophyModal colorMode={colorMode} colors={colors} setTrophyModalOpen={setTrophyModalOpen}/>
        )
    }

    return (
        <>
            <AppBackground px={['4', '10', '16']} py={['8', '10', '16']}>
                <Flex flexGrow={1} maxW={'1024px'} pb={10}>
                    {/* Header */}
                    <DashboardPlayerHeader isScreenBig={isScreenBig} colorMode={colorMode} userName={user.userName}/>
                    <Box bg={colorMode === 'light' ? 'light.contrastLow' : 'dark.contrastLow'} h={'2px'}
                         mx={['15px', '25px', '35px']} mb={10}/>
                    {/* Tournament info */}
                    <FeaturedTournamentCard isScreenBig={isScreenBig} colorMode={colorMode} startDate={startDate}
                                            endDate={endDate} tournamentInfo={tournamentInfo}
                                            setTrophyModalOpen={setTrophyModalOpen}
                                            viewTournamentCallback={viewTournamentCallback}/>
                    <Box mb={10}/>
                    {/* Leagues */}
                    <Flex direction={isScreenBig ? 'row' : 'column'}>
                        <Box w={isScreenBig ? (isSurveyVisible ? '1/2' : 'full') : 'full'}
                             pr={isScreenBig && isSurveyVisible ? 5 : 0}
                             mb={isScreenBig ? 0 : 10}>
                            <LeagueList colorMode={colorMode} leaguesList={playersLeagues} userUid={user.uid}
                                        viewLeagueCallback={viewLeagueCallback}/>
                        </Box>
                        {
                            isSurveyVisible ?
                                <Box w={isScreenBig ? '1/2' : 'full'} pl={isScreenBig ? 5 : 0}>
                                    <Survey colorMode={colorMode}/>
                                </Box>
                                : <></>
                        }
                    </Flex>
                </Flex>
            </AppBackground>
        </>
    );
};

export default Dashboard;