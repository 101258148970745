import React from 'react';
import {Box, Flex, HStack, Pressable, ScrollView, Text, useColorMode, useTheme} from "native-base";
import {AntDesign} from "@expo/vector-icons";

interface AutoSelectHelpModalProps {
    closeModal: () => void
}

const AutoSelectHelpModal = ({closeModal}: AutoSelectHelpModalProps) => {

    const {colorMode} = useColorMode();
    const {colors} = useTheme();

    return (
        <Flex position={'absolute'} top={0} bottom={0} left={0} right={0} zIndex={10}
              justifyContent={'center'} alignItems={'center'} p={10}>
            <Pressable bg={colorMode === 'light' ? 'light.contrast' : 'dark.contrast'} position={'absolute'} top={0}
                       bottom={0} left={0} right={0} opacity={0.7} onPress={closeModal}/>
            <Box bg={colorMode === 'light' ? 'light.layout' : 'dark.layout'} rounded={'2xl'} flexShrink={1}>
                <ScrollView contentContainerStyle={{padding: '24px'}}>
                    <HStack justifyContent={'space-between'}>
                        <Text mr={4} fontSize={['2xl', '3xl', '4xl']}>
                            Auto-Select
                        </Text>
                        <Pressable onPress={closeModal}>
                            {
                                ({isPressed, isHovered}) => (
                                    <AntDesign name="closecircle" size={28}
                                               color={isPressed || isHovered ? (colorMode === 'light' ? colors['light']['error'] : colors['dark']['error']) : colors['primary']}/>
                                )
                            }
                        </Pressable>
                    </HStack>
                    <Text textAlign={'left'} fontSize={['lg', 'xl', '2xl']}
                          color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                        Once the matches start, the player building will be closed and the selection is final.
                        {'\n'}
                        Statistics without a selected athlete will be automatically selected by the system. This is
                        temporary to the MVP of digital tennis tour and will be removed in the future.
                        {'\n'}
                        {'\n'}
                        The idea is to avoid receiving 0 points when missing the selection time window, as the app
                        does not feature push notifications for the selection yet.
                        {'\n'}
                        Auto-select uses an algorithm to select an athlete with a lower-end average performance to avoid
                        advantages in comparison to players with a hand-made selection, while still providing a fair
                        amount
                        of points to be competitive in future rounds.
                    </Text>
                </ScrollView>
            </Box>
        </Flex>
    );
};

export default AutoSelectHelpModal;