import React from 'react';
import {Box, ColorMode, HStack, Text, VStack} from 'native-base';

interface CasualLeagueInfoAlertProps {
    colorMode: ColorMode,
}

const CasualLeagueInfoAlert = ({colorMode}: CasualLeagueInfoAlertProps) => {
    return (
        <HStack flexShrink={1} mt={16} mb={6}>
            <Box bg={colorMode === 'light' ? 'light.warning' : 'dark.warning'} w={'4px'} mr={4}
                 rounded={'full'}/>
            <VStack flexShrink={1}>
                <Text textAlign={'left'} fontSize={['lg', 'xl', '2xl']}
                      color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                    Casual League
                </Text>
                <Text textAlign={'left'} fontSize={['sm', 'md', 'lg']}
                      color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                    This league is not under competitive ruleset. You can join this league at any
                    time. Ranking is not official and the league is used for fun and beginners.
                </Text>
            </VStack>
        </HStack>
    );
};

export default CasualLeagueInfoAlert;