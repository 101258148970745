// noinspection ExceptionCaughtLocallyJS

import {TournamentDataType} from '../../../tournament/api/types';
import React from 'react';
import {AthleteDataType} from '../../../athletes/api/types';
import {getAthleteById} from '../../../athletes/api/athleteSlice';
import {store} from '../../../../../app/redux/store';
import {StatisticSelectionMatchType} from '../types';

export interface GetAthletesForThisRoundProps {
    tournamentData: TournamentDataType;
    setMatches: React.Dispatch<React.SetStateAction<StatisticSelectionMatchType[]>>;
    setMatchesError: React.Dispatch<React.SetStateAction<string>>;
    selectedRoundId: string;
}

export const GetAthleteMatchesForRound = ({
                                              tournamentData,
                                              setMatches,
                                              setMatchesError,
                                              selectedRoundId
                                          }: GetAthletesForThisRoundProps) => {

    try {
        //get round
        const round = tournamentData.rounds.find(r => r.id === selectedRoundId);
        if (!round) {
            throw new Error('Round not found');
        }

        //get athlete ids from the round match data
        const athleteIds: { matchId: string, a1id: string, a2id: string }[] = [];
        round.matches.map(match => {
            if (match.athleteTwo.id !== '' || round.isEntry) {
                athleteIds.push({
                    matchId: match.id,
                    a1id: match.athleteOne.id,
                    a2id: match.athleteTwo.id
                });
            }
        });

        if (athleteIds.length <= 0) {
            throw new Error('No athletes found');
        }

        // console.table(athleteIds);

        const athletesMatches: StatisticSelectionMatchType[] = [];
        athleteIds.forEach(match => {
            const athleteOne = getAthleteById(store.getState(), match.a1id);
            const athleteTwo = getAthleteById(store.getState(), match.a2id);
            if (athleteOne && athleteTwo) {
                athletesMatches.push({matchId: match.matchId, athleteOne, athleteTwo});
            }
            //if the entry list is used as the draw is not available yet
            else if (round.isEntry && athleteOne) {
                athletesMatches.push({matchId: match.matchId, athleteOne, athleteTwo: null});
            }
        });

        // console.log('athleteMatches');
        // console.table(athletesMatches);

        if (athletesMatches.length <= 0) {
            throw new Error('No athlete data found');
        }

        setMatches(athletesMatches);
        setMatchesError('');
    } catch (e) {
        console.log('catched error');
        console.log(e.message);
        setMatchesError(String(e.message));
    }
};
