import React from 'react';
import AppBackground from 'components/essential/AppBackground';
import {Container, Text, VStack, useColorMode, Pressable, Input, Flex} from 'native-base';
import {useAppDispatch, useAppSelector} from '../../app/redux/hooks';
import {sendEmailVerification, sendPasswordResetEmail, signOut, updateEmail} from 'firebase/auth';
import {fb_auth} from '../../App';
import {Alert, NativeSyntheticEvent, Platform, TextInputChangeEventData, useWindowDimensions} from 'react-native';
import {validateEmail} from '../authentication/components/helper/AuthComponentHelper';
import {userLoggedOut} from '../authentication/authSlice';

const AccountScreen = () => {

    const user = useAppSelector(state => state.auth.user);

    const [newEmail, setNewEmail] = React.useState<string>(fb_auth.currentUser?.email ?? '');
    const [emailInfoText, setEmailInfoText] = React.useState<string>('');

    const [passwordInfoText, setPasswordInfoText] = React.useState<string>('');

    const {colorMode} = useColorMode();

    const isScreenBig = useWindowDimensions().width > 768;

    const dispatch = useAppDispatch();

    //---------------------------------------------------- logout
    const logoutCallback = () => {
        signOutUser().then();
    };

    const signOutUser = async () => {

        return signOut(fb_auth)
            .catch((error) => {
                console.log('error during sign out:');
                console.log(error.message);
                return;
            });
    };

    //---------------------------------------------------- reset mail
    const resetEmailCallback = () => {

        if (newEmail === '') {
            setEmailInfoText('Please enter a new email');
        } else if (newEmail === fb_auth.currentUser?.email) {
            setEmailInfoText('New email is the same as the old one');
        } else if (!validateEmail(newEmail)) {
            setEmailInfoText('Please enter a valid email');
        } else {
            setEmailInfoText('');
            if (ResetEmail())
                resetEmail(newEmail).then();
        }
    };

    const handleEmailChange = (e: NativeSyntheticEvent<TextInputChangeEventData>) => {
        setNewEmail(e.nativeEvent.text);
    };

    const resetEmail = async (email: string) => {
        // console.log('reset email');
        // console.log(email)
        if (fb_auth.currentUser !== null) {
            return updateEmail(fb_auth.currentUser, email)
                .then(() => {
                    // console.log('email reset');
                    if (fb_auth.currentUser !== null) {
                        sendEmailVerification(fb_auth.currentUser)
                            .then(() => {
                                dispatch(userLoggedOut());
                            });
                    }
                })
                .catch((error: any) => {
                    console.log('error during email reset:');
                    console.log(error.message);
                });
        }
    };

    //---------------------------------------------------- reset password

    const resetPasswordCallback = () => {
        if (SendResetEmailAlert())
            resetPassword().then();
    };

    const resetPassword = async () => {
        if (fb_auth.currentUser && fb_auth.currentUser.email !== null) {
            sendPasswordResetEmail(fb_auth, fb_auth.currentUser.email)
                .then(() => {
                    fb_auth.signOut();
                })
                .catch((e: any) => {
                    setPasswordInfoText(e.message);
                });
        } else {
            setPasswordInfoText('Cannot reset - please login again');
        }
    };

    return (
        <AppBackground px={['4', '10', '16']} pt={['8', '10', '16']}>
            <Container centerContent={true}>
                <VStack alignItems='stretch' space='2' width='full'>
                    <Flex direction={isScreenBig ? 'row' : 'column'} justifyContent={'space-between'}
                          alignItems={'flex-start'} mb={10}>
                        <VStack alignItems={'flex-start'}>
                            <Text fontSize={['xs', 'sm', 'md']}
                                  color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                                Logged in as
                            </Text>
                            <Text fontSize={['2xl', '3xl', '4xl']}>
                                {user.userName}
                            </Text>
                        </VStack>
                        <Pressable bg={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}
                                   display={'flex'} justifyContent={'center'} alignItems={'center'} p={2} px={6}
                                   _pressed={{bg: 'primary'}} _hover={{bg: 'primary'}} rounded={'xl'}
                                   onPress={logoutCallback}>
                            {
                                ({isPressed, isHovered}) => (
                                    <Text fontSize={'md'}
                                          color={isPressed || isHovered ?
                                              'primaryPartner' : colorMode === 'light' ? 'light.contrast' : 'dark.contrast'}>
                                        Logout
                                    </Text>
                                )
                            }
                        </Pressable>
                    </Flex>
                    {/* Reset Email */}
                    <Text fontSize={['lg', 'xl', '2xl']} alignSelf={'flex-start'}
                          color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                        Reset email
                    </Text>
                    <Flex direction={isScreenBig ? 'row' : 'column'}>
                        <Input placeholder='Email' mr={isScreenBig ? 2 : 0} minWidth={'0px'} w={'auto'}
                               flex={'1 1 auto'} value={newEmail} onChange={handleEmailChange} mb={isScreenBig ? 0 : 2}
                               bg={colorMode === 'light' ? 'light.layoutShade' : 'dark.layoutShade'}/>
                        <Pressable bg={colorMode === 'light' ? 'light.contrast' : 'dark.contrast'}
                                   display={'flex'} justifyContent={'center'} alignItems={'center'} p={2} px={6}
                                   _pressed={{bg: 'primary'}} _hover={{bg: 'primary'}} rounded={'2xl'}
                                   onPress={resetEmailCallback}>
                            {
                                ({isPressed, isHovered}) => (
                                    <Text fontSize={'md'}
                                          color={isPressed || isHovered ?
                                              'primaryPartner' : colorMode === 'light' ? 'light.contrastPartner' : 'dark.contrastPartner'}>
                                        Reset
                                    </Text>
                                )
                            }
                        </Pressable>
                    </Flex>
                    {
                        emailInfoText !== '' ?
                            <Text alignSelf={'flex-start'} fontSize={['md', 'lg', 'xl']}
                                  color={colorMode === 'light' ? 'light.error' : 'dark.error'}>
                                {emailInfoText}
                            </Text>
                            : <></>
                    }
                    {/* Reset Password */}
                    <Text fontSize={['lg', 'xl', '2xl']} alignSelf={'flex-start'} mt={10}
                          color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                        Reset password
                    </Text>
                    <Pressable bg={colorMode === 'light' ? 'light.contrast' : 'dark.contrast'}
                               h={isScreenBig ? '56px' : 'auto'}
                               display={'flex'} justifyContent={'center'} alignItems={'center'} p={2} px={6}
                               _pressed={{bg: 'primary'}} _hover={{bg: 'primary'}} rounded={'2xl'}
                               onPress={resetPasswordCallback}>
                        {
                            ({isPressed, isHovered}) => (
                                <Text fontSize={'md'}
                                      color={isPressed || isHovered ?
                                          'primaryPartner' : colorMode === 'light' ? 'light.contrastPartner' : 'dark.contrastPartner'}>
                                    Send password reset email
                                </Text>
                            )
                        }
                    </Pressable>
                    {
                        passwordInfoText !== '' ?
                            <Text alignSelf={'flex-start'} fontSize={['md', 'lg', 'xl']}
                                  color={colorMode === 'light' ? 'light.error' : 'dark.error'}>
                                {passwordInfoText}
                            </Text>
                            : <></>
                    }
                </VStack>
            </Container>
        </AppBackground>
    );
};

const ResetEmail = () => {
    if (Platform.OS === 'web') {
        if (confirm('Do you want to reset your email?')) {
            return true;
        }
    } else {
        return Alert.alert(
            'Reset email',
            'Do you want to reset your email?',
            [
                {
                    text: 'Cancel',
                    onPress: () => false,
                    style: 'cancel',
                },
                {
                    text: 'OK',
                    onPress: () => true
                },
            ],
        );
    }
};
const SendResetEmailAlert = () => {
    if (Platform.OS === 'web') {
        if (confirm('Do you want to reset your password?')) {
            return true;
        }
    } else {
        return Alert.alert(
            'Reset password',
            'Do you want to send a password reset email?',
            [
                {
                    text: 'Cancel',
                    onPress: () => false,
                    style: 'cancel',
                },
                {
                    text: 'OK',
                    onPress: () => true
                },
            ],
        );
    }
};

export default AccountScreen;