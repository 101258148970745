import React from 'react';
import {Entypo} from '@expo/vector-icons';
import {Box, ColorMode, Flex, Text} from 'native-base';

interface TournamentCompleteInfoProps {
    colorMode: ColorMode,
    colors: any
}

export const TournamentCompleteInfo = ({colorMode, colors}: TournamentCompleteInfoProps) => {
    return (
        <Flex direction='row' alignItems='center' mt='4'>
            <Entypo name='info-with-circle' size={22}
                    color={colorMode === 'light' ? colors['light']['success'] : colors['dark']['success']}/>
            <Text fontSize={['xs', 'sm', 'md']} alignSelf={'flex-start'} ml='2' textAlign='left'
                  color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                This tournament is completed. Finals results are shown below.
            </Text>
        </Flex>
    );
};

interface TournamentUpcomingInfoProps {
    colorMode: ColorMode,
    startDate: Date
}

export const TournamentUpcomingInfo = ({colorMode, startDate}: TournamentUpcomingInfoProps) => {
    return (
        <Flex direction='row' mb={5}>
            <Box w='1' bg={'primary'}
                 alignSelf={'stretch'}
                 rounded='full' mr='2'/>
            <Text fontSize={['md', 'lg', 'xl']} textAlign='center'
                  color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                Tournament starts on {startDate.toLocaleDateString()}
            </Text>
        </Flex>
    );
};