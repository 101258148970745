export enum possibleLeagueApiStatus {
    idle = 'idle',
    loading = 'loading',
}

export enum LeagueVisibilityOptions {
    public = 'public',
    private = 'private',
}

export type LeagueDataType = {
    id: string,
    name: string,
    tournamentId: string,
    visibility: LeagueVisibilityOptions,
    canJoinLater: boolean,
    selectedStatistics: string[],
    players?: JoinedPlayerType[]
}

export type JoinedPlayerType = {
    userUid: string,
    playerName: string,
    currentScore: number
    performance: PlayerPerformanceType[],
    selection: PlayerSelectionType[],
    autoSelection: PlayerSelectionType[],
    cancelledSelection: PlayerSelectionType[],
}

export type PlayerPerformanceType = {
    roundId: string,
    SCORE: number,
}

export type PlayerSelectionType = {
    roundId: string,
    selection: PlayerSelectionItemType[]
}

export type PlayerSelectionItemType = {
    statisticId: string,
    athleteId: string,
}