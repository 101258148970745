import {createEntityAdapter, createSelector, createSlice} from '@reduxjs/toolkit';
import {RootState} from 'app/redux/store';
import {LeagueDataType, possibleLeagueApiStatus} from './types';
import {addPlayerToLeague} from './functions/addPlayerToLeague';
import {getAllLeaguesForTournamentId} from './functions/getAllLeaguesForTournamentId';
import {uploadAthleteSelection} from '../../athletes/api/functions/uploadAthleteSelection';
import {getPlayerSelectionForRound} from "./functions/getPlayerSelectionForRound";
import {fetchLeaguesByIds} from "./functions/fetchLeaguesByIds";

const leagueAdapter = createEntityAdapter<LeagueDataType>({
    selectId: (league) => league.id,
});

const initialState = leagueAdapter.getInitialState({
    status: possibleLeagueApiStatus.idle,
    getSelectionStatus: possibleLeagueApiStatus.idle,
    currentError: '',
});

const leagueSlice = createSlice({
    name: 'leagues',
    initialState,
    reducers: {
        resetErrorMessage(state) {
            state.currentError = '';
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllLeaguesForTournamentId.pending, (state) => {
                state.status = possibleLeagueApiStatus.loading;
            })
            .addCase(getAllLeaguesForTournamentId.fulfilled, (state, action) => {
                state.status = possibleLeagueApiStatus.idle;
                leagueAdapter.upsertMany(state, action.payload);
            })
            .addCase(getAllLeaguesForTournamentId.rejected, (state, action) => {
                state.status = possibleLeagueApiStatus.idle;
                state.currentError = String(action.payload);
            });
        builder
            .addCase(fetchLeaguesByIds.pending, (state) => {
                state.status = possibleLeagueApiStatus.loading;
            })
            .addCase(fetchLeaguesByIds.fulfilled, (state, action) => {
                state.status = possibleLeagueApiStatus.idle;
                leagueAdapter.upsertMany(state, action.payload);
            })
            .addCase(fetchLeaguesByIds.rejected, (state, action) => {
                state.status = possibleLeagueApiStatus.idle;
                state.currentError = String(action.payload);
            })
        builder
            .addCase(addPlayerToLeague.pending, (state) => {
                state.status = possibleLeagueApiStatus.loading;
            })
            .addCase(addPlayerToLeague.fulfilled, (state, action) => {
                state.status = possibleLeagueApiStatus.idle;
                leagueAdapter.upsertOne(state, action.payload);
            })
            .addCase(addPlayerToLeague.rejected, (state, action) => {
                state.status = possibleLeagueApiStatus.idle;
                state.currentError = String(action.payload);
            });
        builder
            .addCase(getPlayerSelectionForRound.pending, (state) => {
                state.getSelectionStatus = possibleLeagueApiStatus.loading;
            })
            .addCase(getPlayerSelectionForRound.fulfilled, (state, action) => {
                state.getSelectionStatus = possibleLeagueApiStatus.idle;
                leagueAdapter.upsertOne(state, action.payload);
            })
            .addCase(getPlayerSelectionForRound.rejected, (state, action) => {
                state.getSelectionStatus = possibleLeagueApiStatus.idle;
                state.currentError = String(action.payload);
            });
        builder
            .addCase(uploadAthleteSelection.pending, (state) => {
                state.status = possibleLeagueApiStatus.loading;
            })
            .addCase(uploadAthleteSelection.fulfilled, (state, action) => {
                state.status = possibleLeagueApiStatus.idle;
                if (action.payload === null)
                    return;
                leagueAdapter.upsertOne(state, action.payload);
            })
            .addCase(uploadAthleteSelection.rejected, (state, action) => {
                state.status = possibleLeagueApiStatus.idle;
                state.currentError = String(action.payload);
            });
    }
});

export const {
    selectAll: getAllLeagues,
    selectById: getLeagueById,
    selectIds: getLeagueIds,
} = leagueAdapter.getSelectors<RootState>(state => state.leagues);

export const {resetErrorMessage} = leagueSlice.actions;

export const selectLeaguesByTournament = createSelector(
    [getAllLeagues, (state, tournamentId) => tournamentId],
    (leagues, tournamentId) => leagues.filter(league => league.tournamentId === tournamentId)
);

export const selectLeagueByUserUid = createSelector(
    [getAllLeagues, (state, userUid) => userUid],
    (leagues, userUid) => leagues.filter(league => league.players?.find(player => player.userUid === userUid))
)

export default leagueSlice.reducer;