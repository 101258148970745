import {createAsyncThunk, createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import {possibleStatisticsInfoStatus, StatisticType, StatisticValueOptions} from './types';
import {RootState} from '../../../../app/redux/store';
import {collection, getDocs, query} from 'firebase/firestore';
import {fb_firestore} from '../../../../App';

const statisticsAdapter = createEntityAdapter<StatisticType>({
    selectId: (statistic: StatisticType) => statistic.id,
    sortComparer: (a: StatisticType, b: StatisticType) => a.order >= b.order ? 1 : -1,
});

const initialState = statisticsAdapter.getInitialState({
    status: possibleStatisticsInfoStatus.idle,
    error: '',
});

export const getStatisticInfo = createAsyncThunk(
    'statistics/getStatisticInfo',
    async (empty, {rejectWithValue}) => {
        try {
            //query for all statistics
            const q = query(collection(fb_firestore, 'statistics'));
            const querySnapshot = await getDocs(q);

            const statistics: StatisticType[] = [];
            querySnapshot.forEach(doc => {
                const statistic = doc.data();
                statistics.push({
                    id: doc.id,
                    displayName: statistic.displayName,
                    performanceMultiplier: statistic.multiplier,
                    subtractValue: statistic.subtractValue ?? 0,
                    subtractMultiplier: statistic.subtractMultiplier ?? 0,
                    infoText: statistic.infoText,
                    value: statistic.value as StatisticValueOptions,
                    order: statistic.order,
                });
            });

            statistics.sort((a, b) => a.order >= b.order ? 1 : -1);

            // console.log('loaded');
            // console.table(statistics);

            return statistics;

        } catch (error) {
            console.log('error in statistics fetch');
            console.log(error);
            return rejectWithValue(error);
        }
    },
);

export const statisticsSlice = createSlice({
    name: 'statistics',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getStatisticInfo.pending, (state) => {
                state.status = possibleStatisticsInfoStatus.loading;
            })
            .addCase(getStatisticInfo.fulfilled, (state, action) => {
                state.status = possibleStatisticsInfoStatus.succeeded;
                statisticsAdapter.setAll(state, action.payload);
            })
            .addCase(getStatisticInfo.rejected, (state, action) => {
                state.status = possibleStatisticsInfoStatus.failed;
                state.error = String(action.payload);
            });
    }
});

export const {
    selectAll: getAllStatistics,
    selectById: getStatisticById,
    selectIds: getStatisticIds,
} = statisticsAdapter.getSelectors<RootState>(state => state.statistics);

export default statisticsSlice.reducer;
