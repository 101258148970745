// noinspection ExceptionCaughtLocallyJS

import {createAsyncThunk} from '@reduxjs/toolkit';
import {collection, getDocs, query} from 'firebase/firestore';
import {fb_firestore} from '../../../../../App';
import {JoinedPlayerType, LeagueDataType, PlayerSelectionItemType, PlayerSelectionType} from '../types';

interface getPlayerSelectionForRoundProps {
    leagueInfo: LeagueDataType;
    userUid: string;
    roundId: string;
}

export const getPlayerSelectionForRound = createAsyncThunk(
    'leagues/getPlayerSelectionForRound',
    async ({leagueInfo, userUid, roundId}: getPlayerSelectionForRoundProps, {rejectWithValue}) => {
        try {

            // console.log('fetching player selection for round', roundId);

            //get the player selection
            const q = query(collection(fb_firestore, `leagues/${leagueInfo.id}/players/${userUid}/selection`));
            const snapshot = await getDocs(q);

            //get auto selection
            const qAuto = query(collection(fb_firestore, `leagues/${leagueInfo.id}/players/${userUid}/autoSelection`));
            const snapshotAuto = await getDocs(qAuto);

            //get cancelled selection
            const qCancelled = query(collection(fb_firestore, `leagues/${leagueInfo.id}/players/${userUid}/cancelledSelection`));
            const snapshotCancelled = await getDocs(qCancelled);

            //safety
            if (snapshot.docs.length === 0 && snapshotAuto.docs.length === 0 && snapshotCancelled.docs.length === 0) {
                throw new Error('no player selection found');
            }

            if (leagueInfo.players === undefined) {
                throw new Error('no players found');
            }

            const playerIndex = leagueInfo.players?.findIndex(player => player.userUid === userUid);
            if (playerIndex === undefined || playerIndex === -1) {
                throw new Error('player not found');
            }

            let playerSelection: PlayerSelectionType[] = [];
            let autoSelection: PlayerSelectionType[] = [];
            let cancelledSelection: PlayerSelectionType[] = [];

            if (snapshot.docs.length > 0) {
                snapshot.forEach((doc) => {
                    const roundSelection: PlayerSelectionItemType[] = [];
                    Object.entries(doc.data()).forEach(([key, value]: [string, string]) => {
                        roundSelection.push({
                            statisticId: key,
                            athleteId: value
                        });
                    });
                    playerSelection.push({
                        roundId: doc.id,
                        selection: roundSelection
                    });
                });
            }

            if (snapshotAuto.docs.length > 0) {
                snapshotAuto.forEach((doc) => {
                    const roundSelection: PlayerSelectionItemType[] = [];
                    Object.entries(doc.data()).forEach(([key, value]: [string, string]) => {
                        roundSelection.push({
                            statisticId: key,
                            athleteId: value
                        });
                    });
                    autoSelection.push({
                        roundId: doc.id,
                        selection: roundSelection
                    });
                });
            }

            if (snapshotCancelled.docs.length > 0) {
                snapshotCancelled.forEach((doc) => {
                    const roundSelection: PlayerSelectionItemType[] = [];
                    Object.entries(doc.data()).forEach(([key, value]: [string, string]) => {
                        roundSelection.push({
                            statisticId: key,
                            athleteId: value
                        });
                    });
                    cancelledSelection.push({
                        roundId: doc.id,
                        selection: roundSelection
                    });
                });
            }

            let newPlayersArray: JoinedPlayerType[] = [...leagueInfo.players];
            newPlayersArray[playerIndex] = {
                ...leagueInfo.players[playerIndex],
                selection: playerSelection,
                autoSelection: autoSelection,
                cancelledSelection: cancelledSelection
            };

            const newLeagueObject: LeagueDataType = {
                ...leagueInfo,
                players: newPlayersArray
            };

            return newLeagueObject;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);