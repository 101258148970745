import React, {useState} from 'react';
import {
    Box,
    Button,
    Container,
    FormControl,
    Heading,
    HStack,
    Input,
    KeyboardAvoidingView,
    Link, Spinner,
    Text, useColorMode,
    useTheme, VStack,
} from 'native-base';
import {createUserWithEmailAndPassword, getAuth, updateProfile, sendEmailVerification} from 'firebase/auth';
import {InputFieldErrorMessage, validateEmail} from './helper/AuthComponentHelper';
import {possibleAuthComponents} from '../types';
import {createUserStorage} from './helper/LogicHelper';

/**
 * Interface
 */
type RegisterComponentProps = {
    switchAuthComponent: (newState: possibleAuthComponents) => void,
}

enum possibleAuthStatus {
    idle = 'idle',
    loading = 'loading',
    succeeded = 'succeeded',
    failed = 'failed'
}

interface IUserData {
    name: string,
    email: string,
    password: string,
    repeatPassword: string
}

interface IValidation {
    name: string,
    email: string,
    password: string,
    repeatPassword: string,
    server: string
}

/**
 * Register Component
 * @param switchAuthComponent callback to switch components in AuthScreen via authStates Enum
 * @constructor
 */
const RegisterComponent = ({switchAuthComponent}: RegisterComponentProps) => {

    //---------------------------------------------------------------------------------------- state
    //state
    const [userData, setUserData] = useState<IUserData>({name: '', email: '', password: '', repeatPassword: ''});

    //early access
    const [earlyAccessGranted, setEarlyAccessGranted] = useState(false);
    const [earlyAccessCode, setEarlyAccessCode] = useState('');

    /*
    each form has a corresponding string in this useState object - whenever the form is submitted the checks are made
    and once an error for that form is detected, the corresponding string is set to that message
    the form can only be submitted if all strings of this object are empty, and therefore none of the checks gave back
    an error code
     */
    const [validation, setValidation] = useState<IValidation>({
        name: '',
        email: '',
        password: '',
        repeatPassword: '',
        server: ''
    });

    const [registerStatus, setRegisterStatus] = useState<string>(possibleAuthStatus.idle);

    //for fontawesome icon buttons
    const {colors} = useTheme();
    const {colorMode} = useColorMode();

    //---------------------------------------------------------------------------------------- callbacks
    //navigation callbacks
    const switchToLoginCallback = () => {
        switchAuthComponent(possibleAuthComponents.login);
    };

    const checkEarlyAccessCode = () => {
        // console.log(`checking ${earlyAccessCode}`);
        if (earlyAccessCode === '8812') {
            setEarlyAccessGranted(true);
        }
    };

    //register button callback
    const registerButtonCallback = () => {
        /* check if the database request is already dispatched (either it is loading, then display server message
        - or the status is 'succeeded' then the navigation displays the next screen anyway) */
        if (!(registerStatus === possibleAuthStatus.idle || registerStatus === possibleAuthStatus.failed)) {
            // console.log(`cant login because of auth status ${registerStatus}`);
            setValidation({
                name: '',
                email: '',
                password: '',
                repeatPassword: '',
                server: 'Server is loading, please wait or reload the page.'
            });
            return;
        }

        //check name
        let name = '';
        if (userData.name === '')
            name = 'Please choose a name.';

        //check email
        let email = '';
        if (userData.email === '')
            email = 'Please provide your email address.';
        else if (!validateEmail(userData.email))
            email = 'Please provide a valid email format, like \'name@example.com\'';

        //check password
        let password = '';
        if (userData.password === '')
            password = 'Please provide a password.';
        else if (userData.password.length < 6)
            password = 'Password is at least 6 characters.';

        //check repeat password
        let repeatPassword = '';
        if (userData.repeatPassword === '')
            repeatPassword = 'Please repeat your password.';
        else if (userData.repeatPassword !== userData.password)
            repeatPassword = 'Passwords do not match.';

        //update form
        setValidation({name, email, password, repeatPassword, server: ''});

        //block the request here if any of the input forms has an info message
        if (![email, password, repeatPassword].every((input) => input === '')) {
            console.log('email, password or repeat-password not correct');
            return;
        }

        // dispatch(registerUser({email: userData.email, password: userData.password}));
        registerUser({
            name: userData.name,
            email: userData.email,
            password: userData.password
        }).catch((registerError) => {
            setRegisterStatus(possibleAuthStatus.failed);

            const empty = {name: '', email: '', password: '', repeatPassword: '', server: ''};

            //check for email errors
            if (registerError === 'auth/invalid-email')
                setValidation({...empty, email: 'Email not formatted correctly.'});
            else if (registerError === 'auth/email-already-in-use')
                setValidation({...empty, email: 'A user with this email is already registered.'});
            //check for password errors
            else if (registerError === 'auth/invalid-password')
                setValidation({...empty, password: 'Invalid password. Password is at least 6 characters.'});
            //auth errors
            else if (registerError === 'auth/too-many-requests')
                setValidation({
                    ...empty, server: 'Access to this account has been temporarily disabled due to ' +
                        'many failed login attempts. You can immediately restore it by resetting your password or ' +
                        'you can try again later.'
                });
            //else set server error message
            else
                setValidation({...empty, server: registerError?.replace('auth/', '') ?? ''});
        });
    };

    const registerUser = async ({name, email, password}: { name: string, email: string, password: string }) => {
        //catch double inputs
        if (registerStatus === possibleAuthStatus.succeeded || registerStatus === possibleAuthStatus.loading) {
            throw 'Server is loading. Please wait or refresh the page.';
        }

        setRegisterStatus(possibleAuthStatus.loading);

        const auth = getAuth();
        //firebase create user
        return createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                //firebase update profile with name
                return updateProfile(userCredential.user, {displayName: name})
                    .then(() => {
                        //firebase send email verification
                        return sendEmailVerification(userCredential.user)
                            .then(() => {
                                //create player
                                return createUserStorage({userUid: userCredential.user.uid, newPlayerName: name})
                                    .then(() => {
                                        //set status to succeeded
                                        // console.log('registration succeeded');
                                    })
                                    .catch((error) => {
                                        throw error.code;
                                    });
                            })
                            .catch((sendEmailError) => {
                                throw sendEmailError.code;
                            });
                    })
                    .catch((error) => {
                        throw error.code;
                    });
            })
            .catch((error) => {
                throw error.code;
            });
    };

    const renderRegisterForm = () => (
        <>
            <HStack space={2} mb={10} mr='auto'>
                <Text>Already a member?</Text>
                <Link
                    _text={{color: 'primary'}}
                    onPress={switchToLoginCallback}
                >
                    Login
                </Link>
            </HStack>
            <VStack space='5' mb='10' pl='2' pr='2' width='full'>
                {/*Username*/}
                <FormControl isInvalid={validation.name !== ''}>
                    <FormControl.Label>Name</FormControl.Label>
                    <Input value={userData.name}
                           onChangeText={value => setUserData({...userData, name: value})}/>
                    {
                        validation.name === '' ? <></> :
                            <InputFieldErrorMessage colors={colors} colorMode={colorMode ?? ''}
                                                    message={validation.name} isSuccess={false}/>
                    }
                </FormControl>
                {/*Email*/}
                <FormControl isInvalid={validation.email !== ''}>
                    <FormControl.Label>Email</FormControl.Label>
                    <Input placeholder='my-email@mail.com' value={userData.email}
                           onChangeText={value => setUserData({...userData, email: value})}/>
                    {
                        validation.email === '' ? <></> :
                            <InputFieldErrorMessage colors={colors} colorMode={colorMode ?? ''}
                                                    message={validation.email} isSuccess={false}/>
                    }
                </FormControl>
                {/*Password*/}
                <FormControl isInvalid={validation.password !== ''}>
                    <FormControl.Label>Password</FormControl.Label>
                    <Input type='password' value={userData.password}
                           onChangeText={value => setUserData({...userData, password: value})}/>
                    {
                        validation.password === '' ? <></> :
                            <InputFieldErrorMessage colors={colors} colorMode={colorMode ?? ''}
                                                    message={validation.password} isSuccess={false}/>
                    }
                </FormControl>
                {/*Repeat Password*/}
                <FormControl isInvalid={validation.repeatPassword !== ''}>
                    <FormControl.Label>Repeat Password</FormControl.Label>
                    <Input type='password' value={userData.repeatPassword}
                           onChangeText={value => setUserData({...userData, repeatPassword: value})}/>
                    {
                        validation.repeatPassword === '' ? <></> :
                            <InputFieldErrorMessage colors={colors} colorMode={colorMode ?? ''}
                                                    message={validation.repeatPassword} isSuccess={false}/>
                    }
                </FormControl>
            </VStack>
            <Box width='full' display='flex' flexDir='column' alignItems='stretch'>
                <Button onPress={registerButtonCallback} w={'full'}>
                    {
                        registerStatus !== possibleAuthStatus.loading ? 'Register' :
                            <Spinner size={'lg'}
                                     color={colorMode === 'light' ? 'light.contrastPartner' : 'dark.contrastPartner'}/>
                    }
                </Button>
                {
                    validation.server === '' ? <></> :
                        <Text fontSize={['sm', 'md', 'lg']} width={'full'} mt={2} mb={0} textAlign={'center'}
                              color={colorMode === 'light' ? 'light.error' : 'dark.error'}>
                            {validation.server}
                        </Text>
                }
            </Box>
        </>
    );

    return (
        <KeyboardAvoidingView>
            <Container>
                <Heading textAlign='left' mr='auto'>
                    Register
                </Heading>
                {
                    earlyAccessGranted ?
                        renderRegisterForm()
                        :
                        <>
                            <HStack space={2} mb={10} mr='auto'>
                                <Text>Already a member?</Text>
                                <Link
                                    _text={{color: 'primary'}}
                                    onPress={switchToLoginCallback}
                                >
                                    Login
                                </Link>
                            </HStack>
                            {/*<Input type='password' value={userData.repeatPassword}*/}
                            {/*       onChangeText={value => setUserData({...userData, repeatPassword: value})}/>*/}
                            <Text>
                                Enter early access code to register.
                            </Text>
                            <Input type='password' mb={4} value={earlyAccessCode}
                                   onChangeText={value => setEarlyAccessCode(value)}/>
                            <Button onPress={checkEarlyAccessCode} w={'full'}>
                                confirm
                            </Button>
                        </>
                }
            </Container>
        </KeyboardAvoidingView>
    );
};

export default RegisterComponent;