import React, {useState} from 'react';
import {
    Box,
    Button,
    Container,
    FormControl,
    Heading, HStack, Input,
    KeyboardAvoidingView, Link, ScrollView, Text,
    useColorMode, useColorModeValue, useTheme, VStack
} from 'native-base';
import AppBackground from 'components/essential/AppBackground';
import {getAuth, sendPasswordResetEmail} from 'firebase/auth';
import {InputFieldErrorMessage, validateEmail} from '../components/helper/AuthComponentHelper';
import {PasswordResetScreenProps} from '../types';

const PasswordResetScreen = ({navigation}: PasswordResetScreenProps) => {

    //state
    const [email, setEmail] = useState<string>('');

    const [validationMessage, setValidationMessage] = useState<string>('');

    const [emailSent, setEmailSent] = useState<boolean>(false);

    //colors for comps where hooks are not available
    const {colors} = useTheme();
    const {colorMode} = useColorMode();

    //callback
    const passwordResetButtonCallback = () => {

        //check email field
        let checkMessage = '';
        if (email === '')
            checkMessage = 'Please provide your email address.';
        else if (!validateEmail(email))
            checkMessage = 'Please provide a valid email format, like `name@example.com`';

        setValidationMessage(checkMessage);

        if (checkMessage !== '')
            return;

        //send reset email
        const auth = getAuth();
        sendPasswordResetEmail(auth, email)
            .then(() => {
                setEmailSent(true);
                setValidationMessage('Email sent!');
                waitThenGoBack().then();
            })
            .catch((error) => {
                setEmailSent(false);
                let errorMessage = error.code;
                if (errorMessage === 'auth/user-not-found')
                    errorMessage = 'No account with this email is known.';
                else
                    errorMessage = errorMessage.replace('auth/', '');
                setValidationMessage(errorMessage);
            });
    };

    const waitThenGoBack = async () => {
        setTimeout(goBackToLoginButtonCallback, 800);
    };

    const goBackToLoginButtonCallback = () => {
        // navigation.navigate(possibleAuthScreens.Authentication);
        navigation.goBack();
    };

    return (
        <AppBackground>
            <KeyboardAvoidingView flexGrow={1} width='full' justifyContent='center'>
                <ScrollView
                    _contentContainerStyle={{
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: ['4', '6', '8']
                    }}>
                    <Container maxWidth='512px'>
                        <VStack space='2' mb='10' ml='2' mr='2'>
                            <Heading textAlign='left' mr='auto'>
                                Password Reset
                            </Heading>
                            {/*Email*/}
                            <FormControl isInvalid={validationMessage !== '' && !emailSent}>
                                <FormControl.Label>Email</FormControl.Label>
                                <Input placeholder='my-email@mail.com' value={email}
                                       onChangeText={value => setEmail(value)}/>
                                {
                                    validationMessage === '' ? <></> :
                                        <InputFieldErrorMessage colors={colors} colorMode={colorMode ?? ''}
                                                                message={validationMessage} isSuccess={emailSent}/>
                                }
                            </FormControl>
                        </VStack>
                        <Box width='full' display='flex' flexDir='row' alignItems='stretch'>
                            <Button _text={{fontSize: 'xl'}} w={'full'}
                                    onPress={() => {
                                        emailSent ? goBackToLoginButtonCallback() : passwordResetButtonCallback();
                                    }}
                                    variant={emailSent ? 'secondary' : 'default'} mb='5'>
                                {
                                    emailSent ? 'Go back' : 'Reset Password'
                                }
                            </Button>
                        </Box>
                        <Link onPress={goBackToLoginButtonCallback} width='full'>
                            <HStack alignItems='center' justifyContent='center' width='full' space={1}>
                                {/*<View style={{transform: [{translateY: 1}]}}>*/}
                                {/*    <AntDesign name='arrowleft' size={18}*/}
                                {/*               color={colorMode === 'light' ? colors['light']['contrastMid'] : colors['dark']['contrastMid']}/>*/}
                                {/*</View>*/}
                                <Text fontSize='sm'
                                      color={useColorModeValue('light.contrastMid', 'dark.contrastMid')}>
                                    go back
                                </Text>
                            </HStack>
                        </Link>
                    </Container>
                </ScrollView>
            </KeyboardAvoidingView>
        </AppBackground>
    );
};

export default PasswordResetScreen;