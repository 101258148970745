import React from 'react';
import {Box, ColorMode, HStack, Link, Text, VStack} from 'native-base';

interface LeagueDescriptionInfoProps {
    colorMode: ColorMode,

}

const LeagueDescriptionInfo = ({colorMode}: LeagueDescriptionInfoProps) => {

    const [moreInfoShown, setMoreInfoShown] = React.useState(false);

    return (
        <VStack mt={1} alignItems={'flex-start'}>
            <Text textAlign='left' fontSize={['sm', 'md', 'lg']}
                  color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                Leagues provide ranking for players during a tournament.
            </Text>
            {
                !moreInfoShown ?
                    <Link _text={{
                        textAlign: 'left',
                        fontSize: ['2xs', 'xs', 'sm'],
                        mt: 2,
                        color: (colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid')
                    }} onPress={() => setMoreInfoShown(!moreInfoShown)}>
                        More info ...
                    </Link>
                    : <></>
            }
            {
                moreInfoShown ?
                    <VStack>
                        <HStack alignItems='stretch' mt={2}>
                            <Box w='2px' mr={2}
                                 bg={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}/>
                            <Text textAlign='left' fontSize={['sm', 'md', 'lg']}
                                  color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                                Upcoming rounds are greyed out, meaning the draw for this round is
                                not
                                uploaded
                                yet. Once a round is open, the small pen icon indicates your chance
                                to build
                                your player. Click on your player name to build your player for this
                                round.
                                {'\n'}
                                Once the round is live, indicated by the red icon, the matches are
                                underway.
                                After all matches are complete, the performances will be calculated,
                                the
                                round
                                will receive a checkmark icon, and the ranking will be updated.
                                Click on
                                your
                                player for any completed round to view your performance points.
                            </Text>
                        </HStack>
                        <Link _text={{
                            textAlign: 'left',
                            fontSize: ['2xs', 'xs', 'sm'],
                            mt: 2,
                            color: (colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid')
                        }} onPress={() => setMoreInfoShown(false)}>
                            Hide info.
                        </Link>
                    </VStack>
                    : <></>
            }
        </VStack>
    );
};

export default LeagueDescriptionInfo;