import {ColorMode} from 'native-base';

export const appComponents = {
    //--------------------------------------------- Heading
    Heading: {
        baseStyle: ({colorMode}: { colorMode: ColorMode }) => {
            return {
                color: colorMode === 'light' ? 'light.title' : 'dark.title',
                fontFamily: 'heading',
                textAlign: 'center'
            };
        },
        defaultProps: {
            fontSize: ['70px', '82px', '8xl'],
        },
    },
    //--------------------------------------------- Text
    Text: {
        baseStyle: ({colorMode}: { colorMode: ColorMode }) => {
            return {
                color: colorMode === 'light' ? 'light.body' : 'dark.body',
                fontFamily: 'body',
                textAlign: 'center'
            };
        },
        defaultProps: {
            fontSize: ['lg', 'xl', '2xl']
        },
        variants: {
            link: {
                underline: true
            }
        }
    },
    //--------------------------------------------- Button
    Button: {
        //base button
        baseStyle: ({colorMode}: { colorMode: ColorMode }) => {
            return {
                bg: colorMode === 'light' ? 'light.contrast' : 'dark.contrast',
                rounded: 'full',
                pl: '25px',
                pr: '25px',
                pt: '12px',
                pb: '12px',
                minWidth: '150px',
                _text: {
                    color: colorMode === 'light' ? 'light.contrastPartner' : 'dark.contrastPartner',
                    fontFamily: 'body',
                },
                _hover: {
                    bg: 'primary',
                    _text: {
                        color: 'primaryPartner'
                    }
                },
                _pressed: {
                    bg: 'primary',
                    _text: {
                        color: 'primaryPartner'
                    },
                    _web: {
                        opacity: 40,
                    }
                }
            };
        },
        //default variant and font sizes
        defaultProps: {
            variant: 'default',
            _text: {
                fontSize: ['lg', 'xl', '2xl']
            }
        },
        //variants
        variants: {
            secondary: ({colorMode}: { colorMode: ColorMode }) => {
                return {
                    bg: colorMode === 'light' ? 'light.bg' : 'dark.bg',
                    borderColor: colorMode === 'light' ? 'light.contrast' : 'dark.contrast',
                    borderWidth: 2,
                    _text: {
                        color: colorMode === 'light' ? 'light.contrast' : 'dark.contrast',
                    },
                    _hover: {
                        borderColor: 'primary',
                        _text: {
                            color: 'primaryPartner'
                        }
                    },
                    _pressed: {
                        _text: {
                            color: 'primaryPartner'
                        }
                    }
                };
            },
            squared: () => {
                return {
                    rounded: '2px'
                };
            },
            disabled: ({colorMode}: { colorMode: ColorMode }) => {
                return {
                    bg: colorMode === 'light' ? 'light.contrastLow' : 'dark.contrastLow',
                    _text: {
                        color: colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid',
                    },
                    _hover: {
                        bg: colorMode === 'light' ? 'light.contrastLow' : 'dark.contrastLow',
                        _text: {
                            color: colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid',
                        }
                    },
                    _pressed: {
                        bg: colorMode === 'light' ? 'light.contrastLow' : 'dark.contrastLow',
                        _text: {
                            color: colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid',
                        }
                    },
                };
            },
            attention: ({colorMode}: { colorMode: ColorMode }) => {
                return {
                    bg: 'primary',
                    _text: {
                        color: 'primaryPartner'
                    },
                    _hover: {
                        bg: 'primaryPartner',
                        _text: {
                            color: 'primary'
                        }
                    },
                    _pressed: {
                        bg: 'primaryPartner',
                        _text: {
                            color: 'primary'
                        }
                    }
                };
            },
        },
    },
    //--------------------------------------------- Container
    Container: {
        baseStyle: ({colorMode}: { colorMode: ColorMode }) => {
            return {
                pl: ['40px', '50px', '60px'],
                pr: ['40px', '50px', '60px'],
                pt: ['40px', '50px', '60px'],
                pb: ['40px', '50px', '60px'],
                bg: colorMode === 'light' ? 'light.layout' : 'dark.layout',
                rounded: '50px',
                // ml: '15px',
                // mr: '15px',
                maxWidth: 'none'
            };
        },
        defaultProps: {
            variant: 'default',
        },
        variants: {
            secondary: ({colorMode}: { colorMode: ColorMode }) => {
                return {
                    bg: colorMode === 'light' ? 'light.layoutShade' : 'dark.layoutShade',
                };
            }
        }
    },
    Input: {
        baseStyle: ({colorMode}: { colorMode: ColorMode }) => {
            return {
                fontFamily: 'body',
                borderColor: colorMode === 'light' ? 'light.contrastLow' : 'dark.contrastLow',
                bg: colorMode === 'light' ? 'light.layoutShade' : 'dark.layoutShade',
                borderRadius: '16',
                padding: '2',
                pl: '4',
                pr: '4',
                width: '100%',
                minWidth: '100%',
                _focus: {
                    borderColor: 'primary',
                },
            };
        },
        defaultProps: {
            fontSize: ['lg', 'xl', '2xl'],
            borderWidth: '2',
            _focus: {
                // borderWidth: '4'
            }
        }
    },
    FormControlLabel: {
        baseStyle: ({colorMode}: { colorMode: ColorMode }) => {
            return {
                _text: {
                    color: colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid',
                }
            };
        },
        defaultProps: {
            _text: {
                fontSize: ['sm', 'md', 'lg']
            }
        }
    },
    Badge: {
        baseStyle: ({colorMode}: { colorMode: ColorMode }) => {
            return {
                p: '1',
                bg: 'primary',
            };
        },
        defaultProps: {
            _text: {
                // fontSize: ['10px', '12px', '15px']
                fontSize: '12px'
            }
        }
    }
};