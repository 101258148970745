import * as React from 'react';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import AuthenticationScreen from './screens/AuthenticationScreen';
import PasswordResetScreen from './screens/PasswordResetScreen';
import {PublicStackParamList, possiblePublicScreens} from './types';
import WelcomeScreen from "../welcome/WelcomeScreen";
import InformationScreen from "../welcome/InformationScreen";

/**
 * The navigator including the navigation props
 */
const PublicStack = createNativeStackNavigator<PublicStackParamList>();

const PublicNavigator = () => {

    return (
        <PublicStack.Navigator screenOptions={{headerShown: false}}>
            <PublicStack.Screen name={possiblePublicScreens.Welcome} component={WelcomeScreen}/>
            <PublicStack.Screen name={possiblePublicScreens.Authentication} component={AuthenticationScreen}/>
            <PublicStack.Group screenOptions={{presentation: 'modal'}}>
                <PublicStack.Screen name={possiblePublicScreens.PasswordReset} component={PasswordResetScreen}/>
                <PublicStack.Screen name={possiblePublicScreens.Information} component={InformationScreen}/>
            </PublicStack.Group>
        </PublicStack.Navigator>
    );
};

export default PublicNavigator;