import {NativeStackScreenProps} from '@react-navigation/native-stack';

//---------------------------------------------------------------------------------------------- Types

/**
 * Possible Screens for Auth
 * Used to determine which screen to show in stack navigator
 */
export enum possiblePublicScreens {
    Welcome = 'Welcome',
    Authentication = 'Authentication',
    PasswordReset = 'PasswordReset',
    Information = 'Information',
}

/**
 * Possible Components to Show on AuthScreen
 */
export enum possibleAuthComponents {
    login,
    register,
    verifyEmail
}

//---------------------------------------------------------------------------------------------- Navigation
/**
 * The Navigator Stack Params List
 */
export type PublicStackParamList = {
    Welcome: undefined,
    Authentication: { initialAuthState: possibleAuthComponents },
    PasswordReset: undefined,
    Information: undefined,
}

export type WelcomeScreenProps = NativeStackScreenProps<PublicStackParamList, possiblePublicScreens.Welcome>

/**
 * Type for props of the components screen
 */
export type AuthenticationScreenProps = NativeStackScreenProps<PublicStackParamList, possiblePublicScreens.Authentication>

/**
 * Type for props of the components screen
 */
export type PasswordResetScreenProps = NativeStackScreenProps<PublicStackParamList, possiblePublicScreens.PasswordReset>

export type InformationScreenProps = NativeStackScreenProps<PublicStackParamList, possiblePublicScreens.Information>