import React from 'react';
import {Box, Flex, HStack, Pressable, ScrollView, Text, useColorMode, useTheme} from 'native-base';
import {AntDesign} from '@expo/vector-icons';

interface ReplacementHelpModalProps {
    closeModal: () => void;
}

const ReplacementHelpModal = ({closeModal}: ReplacementHelpModalProps) => {

    const {colorMode} = useColorMode();
    const {colors} = useTheme();

    return (
        <Flex position={'absolute'} top={0} bottom={0} left={0} right={0} zIndex={10}
              justifyContent={'center'} alignItems={'center'} p={10}>
            <Pressable bg={colorMode === 'light' ? 'light.contrast' : 'dark.contrast'} position={'absolute'} top={0}
                       bottom={0} left={0} right={0} opacity={0.7} onPress={closeModal}/>
            <Box bg={colorMode === 'light' ? 'light.layout' : 'dark.layout'} rounded={'2xl'} flexShrink={1}>
                <ScrollView contentContainerStyle={{padding: '24px'}}>
                    <HStack justifyContent={'space-between'}>
                        <Text mr={4} fontSize={['2xl', '3xl', '4xl']}>
                            Replacement
                        </Text>
                        <Pressable onPress={closeModal}>
                            {
                                ({isPressed, isHovered}) => (
                                    <AntDesign name='closecircle' size={28}
                                               color={isPressed || isHovered ? (colorMode === 'light' ? colors['light']['error'] : colors['dark']['error']) : colors['primary']}/>
                                )
                            }
                        </Pressable>
                    </HStack>
                    <Text textAlign={'left'} fontSize={['lg', 'xl', '2xl']}
                          color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                        Replacements are chosen for cancelled matches / not played matches.
                        {'\n'}
                        As a replacement, the most average tennis athlete for this round is chosen. Meaning, all
                        performances of all athletes in that round are averaged and the next fitting athlete is
                        selected.
                    </Text>
                </ScrollView>
            </Box>
        </Flex>
    );
};

export default ReplacementHelpModal;