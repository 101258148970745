import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import {memo} from 'react';
import {Factory} from 'native-base';

const Polygon = (props) => {
    const PolygonNb = Factory(PolygonRaw);
    return <PolygonNb {...props} />;
};

const PolygonRaw = (props) => {
    return (
        <Svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 329.1 285.01' {...props}>
            <Path
                style={{
                    fill: props.iconcolor,
                }}
                d='M246.82 0H82.27L0 142.5l82.27 142.51h164.55L329.1 142.5 246.82 0z'
            />
        </Svg>
    );
};

const Memo = memo(Polygon);
export default Memo;