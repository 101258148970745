import React, {useEffect} from 'react';
import {Box, Flex, Heading, Text, useColorMode, useTheme} from 'native-base';
import AppBackground from 'components/essential/AppBackground';
import {TournamentScreenProps} from '../types';
import {useAppDispatch, useAppSelector} from 'app/redux/hooks';
import {getTournamentById} from '../allTournaments/api/tournamentInfoSlice';
import {store} from 'app/redux/store';
import {getTournamentDataById} from './api/tournamentDataSlice';
import ErrorScreen from '../../main/helper/ErrorScreen';
import {
    possibleRoundIds, possibleTournamentDataState,
} from './api/types';
import {fetchTournamentData} from './api/functions/fetchTournamentData';
import {convertMonth, getEnding} from '../allTournaments/helper/dateHelper';
import {Entypo} from '@expo/vector-icons';
import CurrentRoundIndicator from './components/CurrentRoundIndicator';
import {TournamentLeaguesComponent} from './components/TournamentLeaguesComponent';

const TournamentScreen = ({route, navigation}: TournamentScreenProps) => {

        //params
        const {tournamentId} = route.params;

        // const tournamentId = 'monte-carlo-2022';

        //helper
        const dispatch = useAppDispatch();
        const {colorMode} = useColorMode();
        const {colors} = useTheme();

        //get state
        const tournamentInfo = getTournamentById(store.getState(), tournamentId);

        //dates
        const startDate = new Date(tournamentInfo?.startDate ?? '');
        const endDate = new Date(tournamentInfo?.endDate ?? '');

        //------------------------------------------------------------------------------ get tournamentData
        const tournamentData = useAppSelector(state => getTournamentDataById(state, tournamentId));
        const tournamentDataApiStatus = useAppSelector(state => state.tournamentData.status);
        const tournamentDataError = useAppSelector(state => state.tournamentData.error);
        useEffect(() => {
            if (!tournamentData) {
                dispatch(fetchTournamentData(tournamentId));
            }
        }, [tournamentData, dispatch, tournamentId]);

        //------------------------------------------------------------------------------ catch errors
        if (!tournamentInfo)
            return <ErrorScreen errorMessage={'Tournament not found'}/>;

        if (!tournamentData && tournamentInfo.currentRound !== possibleRoundIds.upcoming && tournamentDataApiStatus !== possibleTournamentDataState.loading) {
            return (
                <ErrorScreen errorMessage={tournamentDataError}/>
            );
        }

        //------------------------------------------------------------------------------ render
        const renderRoundStatus = () => {
            if (tournamentInfo.currentRound === possibleRoundIds.completed) {
                return (
                    <Flex direction='row' alignItems='center'>
                        <Entypo name='info-with-circle' size={22}
                                color={colorMode === 'light' ? colors['light']['success'] : colors['dark']['success']}/>
                        <Text fontSize={['xs', 'sm', 'md']} alignSelf={'flex-start'} ml='2'
                              color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                            This tournament is already completed.
                        </Text>
                    </Flex>
                );
            }
            if (tournamentInfo.currentRound === possibleRoundIds.upcoming) {
                return (
                    <Flex direction='row' alignItems='center'>
                        <Entypo name='info-with-circle' size={22}
                                color={colorMode === 'light' ? colors['light']['error'] : colors['dark']['error']}/>
                        <Text fontSize={['xs', 'sm', 'md']} alignSelf={'flex-start'} ml='2'
                              color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                            This tournament has not yet started
                        </Text>
                    </Flex>
                );
            }

            if (!tournamentData)
                return <></>;

            return <CurrentRoundIndicator rounds={tournamentData.rounds} currentRound={tournamentInfo.currentRound}
                                          colorMode={colorMode} colors={colors}/>;
        };

        return (
            <AppBackground p={['4', '10', '16']} py={['8', '10', '16']}>
                <Box>
                    <Heading fontSize={['6xl', '7xl', '8xl']} alignSelf={['center', 'flex-start']}
                             textAlign='left' lineHeight='1em'>
                        {tournamentInfo?.name}
                    </Heading>
                    <Text fontSize={['xl', '2xl', '3xl']} alignSelf={'flex-start'} textAlign='left'
                          color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                        {`${startDate.getDate()}${getEnding(startDate.getDate())} ${convertMonth(startDate.getMonth())} - ${endDate.getDate()}${getEnding(endDate.getDate())} ${convertMonth(endDate.getMonth())}`}
                    </Text>
                    <Box mt='4'>
                        {renderRoundStatus()}
                    </Box>
                </Box>
                <Box w='full' h='4px' my={[8, 10, 12]} bg={colorMode === 'light' ? 'light.contrastLow' : 'dark.contrastLow'}
                     rounded={'full'}/>
                <TournamentLeaguesComponent tournamentId={tournamentId}
                                            currentTournamentRound={tournamentInfo.currentRound}
                                            navigation={navigation}/>
            </AppBackground>
        );
    }
;

export default TournamentScreen;