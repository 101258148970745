import React from 'react';
import {Button, Center, Flex, Heading, Text, useColorModeValue} from 'native-base';
import AppBackground from 'components/essential/AppBackground';

const ErrorScreen = ({errorMessage}: { errorMessage: string }) => {

    return (
        <AppBackground safeArea>
            <Flex flex={1} alignItems='center' justifyContent='center'>
                <Heading fontSize='4xl' color={useColorModeValue('light.error', 'dark.error')}>
                    Error
                </Heading>
                <Text>
                    {errorMessage}
                </Text>
            </Flex>
        </AppBackground>
    );
};

export default ErrorScreen;