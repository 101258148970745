import React, {MutableRefObject, useEffect, useRef, useState} from 'react';
import {Box, ColorMode, Flex, Text, useBreakpointValue} from 'native-base';
import {FlatList, ScrollView} from 'react-native';
import {JoinedPlayerType} from '../../api/types';
import {useAppSelector} from 'app/redux/hooks';
import {possibleRoundIds, possibleRoundStates, RoundDataType, TournamentDataType} from '../../../tournament/api/types';
import PlayerListItem from './PlayerListItem';
import {LeagueNavigationProp, possibleTournamentScreens} from '../../../types';
import {getLeagueById} from '../../api/leagueSlice';
import CurrentRoundIndicator from '../../../tournament/components/CurrentRoundIndicator';
import {PlayerListRoundInfoHeader} from './helper/PlayerListRoundInfoHeader';
import {PossibleLeagueSortOptions} from './types';
import {PlayerListPointInfoHeader} from './helper/PlayerListPointInfoHeader';

interface LeaguePlayerListProps {
    leagueId: string,
    tournamentData: TournamentDataType | undefined,
    selectedRound: RoundDataType | null,
    currentRound: possibleRoundIds,
    colorMode: ColorMode,
    colors: any,
    navigation: LeagueNavigationProp,
    scrollViewRef: MutableRefObject<ScrollView | undefined>,
    isPlayerPart: boolean,
    hasTournamentStarted: boolean,
}

const LeaguePlayerList = ({
                              leagueId,
                              tournamentData,
                              selectedRound,
                              currentRound,
                              colorMode,
                              colors,
                              navigation,
                              scrollViewRef,
                              isPlayerPart,
                              hasTournamentStarted
                          }: LeaguePlayerListProps) => {

    //setup player list
    const leagueInfo = useAppSelector(state => getLeagueById(state, leagueId));
    const user = useAppSelector(state => state.auth.user);
    const [players, setPlayers] = useState<JoinedPlayerType[]>([]);
    const [hasPlayerMadeSelection, setHasPlayerMadeSelection] = useState<boolean>(false);

    //ui
    const isScreenBig = useBreakpointValue({base: false, md: true});
    const currentPlayerScrollPos = useRef(0);

    useEffect(() => {
        if (leagueInfo && user && leagueInfo.players !== undefined) {

            //set player
            sortAndUpdatePlayers(leagueInfo.players, PossibleLeagueSortOptions.totalPoints);

            if (hasTournamentStarted && selectedRound) {
                //check if player has made selection
                let thisPlayer = leagueInfo.players.find(player => player.userUid === user.uid);
                if (thisPlayer) {
                    let roundSelection = thisPlayer.selection.find(selection => selection.roundId === selectedRound.id);
                    if (roundSelection) {
                        setHasPlayerMadeSelection(Object.keys(roundSelection.selection).length > 0);
                        //TODO add check that selection coming from database has all the statistics needed for this league
                    } else {
                        setHasPlayerMadeSelection(false);
                    }
                }
            }
        }
    }, [leagueInfo, user, selectedRound]);

    //-------------------------------------------------------------------- sort
    const sortAndUpdatePlayers = (players: JoinedPlayerType[], sortLeagueBy: PossibleLeagueSortOptions) => {
        if (players.length > 0) {
            if (sortLeagueBy === PossibleLeagueSortOptions.totalPoints) {
                let sortedPlayers = [...players];
                sortedPlayers.sort((a, b) => {
                    return b.currentScore - a.currentScore;
                });
                setPlayers(sortedPlayers);
            } else if (sortLeagueBy === PossibleLeagueSortOptions.roundPoints && selectedRound !== null) {
                let sortedPlayers = [...players];
                sortedPlayers.sort((a, b) =>
                    (b.performance.find(r => r.roundId === selectedRound.id)?.SCORE ?? 0) - (a.performance.find(r => r.roundId === selectedRound.id)?.SCORE ?? 0));
                setPlayers(sortedPlayers);
            }
        }
    };

    //-------------------------------------------------------------------- player item
    const playerClickedForSelection = (playerInfo: JoinedPlayerType) => {
        if (leagueInfo !== undefined && selectedRound !== null && selectedRound.status === possibleRoundStates.open && tournamentData !== undefined) {
            navigation.push(possibleTournamentScreens.VPlayerBuilding, {
                playerInfo,
                leagueId: leagueInfo.id,
                tournamentId: tournamentData.id,
                selectedRound
            });
        }
    };

    const playerClickedForPerformance = (playerInfo: JoinedPlayerType) => {
        if (leagueInfo !== undefined && selectedRound !== null && selectedRound.status === possibleRoundStates.completed && tournamentData !== undefined) {
            navigation.push(possibleTournamentScreens.VPlayerPerformance, {
                playerUid: playerInfo.userUid,
                leagueId: leagueInfo.id,
                tournamentId: tournamentData.id,
                selectedRound
            });
        }
    };

    //-------------------------------------------------------------------- scroll to player item
    //callback from player list player item - used for scrollTo feature
    const setCurrentPlayerItemPosY = (posY: number) => {
        currentPlayerScrollPos.current = posY;
    };

    const scrollToPlayerItem = () => {
        scrollViewRef.current?.scrollTo({x: 0, y: currentPlayerScrollPos.current - 200, animated: true});
    };

    //-------------------------------------------------------------------- render
    if (leagueInfo === undefined || players === undefined || players.length === 0 || (selectedRound === null && hasTournamentStarted) || tournamentData === undefined) {
        return (
            <Text color={colorMode === 'light' ? 'light.contrastLow' : 'dark.contrastLow'} mx={'auto'}>
                No players available
            </Text>
        );
    }

    const renderPlayerItem = ({playerInfo, index}: { playerInfo: JoinedPlayerType, index: number }) => (
        <PlayerListItem playerInfo={playerInfo} index={index} currentUserId={user.uid}
                        hasPlayerMadeSelection={hasPlayerMadeSelection} setPlayerItemPosY={setCurrentPlayerItemPosY}
                        colorMode={colorMode} colors={colors} selectedRound={selectedRound}
                        onPressSelection={playerClickedForSelection} onPressPerformance={playerClickedForPerformance}/>
    );

    return (
        <FlatList
            data={players}
            renderItem={({item, index}) => renderPlayerItem({playerInfo: item, index})}
            keyExtractor={item => item.userUid}
            ItemSeparatorComponent={() => <Box h='2px' rounded='full' mx='4' zIndex='-1'
                                               bg={colorMode === 'light' ? 'light.layoutShade' : 'dark.layoutShade'}/>}
            style={{width: '100%', overflow: 'hidden'}}
            contentContainerStyle={{height: '100%', paddingBottom: '5px'}}
            ListHeaderComponent={() =>
                <>
                    {
                        !isPlayerPart ?
                            (
                                tournamentData.rounds.length > 1 ?
                                    <Flex direction='row' justifyContent='center' mb='12' mt={2}>
                                        <CurrentRoundIndicator rounds={tournamentData.rounds}
                                                               currentRound={currentRound}
                                                               colorMode={colorMode} colors={colors}/>
                                    </Flex>
                                    : <></>
                            )
                            :
                            <PlayerListRoundInfoHeader selectedRound={selectedRound} colorMode={colorMode}
                                                       isPlayerReady={hasPlayerMadeSelection} colors={colors}
                                                       playerInfo={leagueInfo.players?.find(player => player.userUid === user.uid)}
                                                       buildPlayerCallback={playerClickedForSelection}/>
                    }
                    <PlayerListPointInfoHeader selectedRound={selectedRound} colorMode={colorMode}
                                               isScreenBig={isScreenBig}
                                               sortCallback={(sortBy: PossibleLeagueSortOptions) => sortAndUpdatePlayers(players, sortBy)}
                                               scrollToPlayerItemCallback={scrollToPlayerItem}
                                               isPlayerPart={isPlayerPart}/>
                </>
            }
        />
    );
};

export default LeaguePlayerList;