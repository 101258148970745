import React from 'react';
import {StatisticType, StatisticValueOptions} from '../../../statistics/api/types';
import {Box, ColorMode, Flex, HStack, Link, Text, useBreakpointValue, VStack} from 'native-base';
import {PerformanceInfoType} from '../types';
import {Feather, FontAwesome} from '@expo/vector-icons';

interface StatisticPerformanceComponentProps {
    item: PerformanceInfoType,
    index: number,
    colorMode: ColorMode,
    colors: any,
    isScreenBig: boolean,
    statHelpCallback: (statistic: StatisticType) => void;
    multiplierHelpCallback: () => void;
    subtractHelpCallback: () => void;
    autoSelectHelpCallback: () => void;
    replacementHelpCallback: () => void;
    statAmount: number;
    isShownPlayerUser: boolean;
}

const StatisticPerformanceComponent = ({
                                           item,
                                           index,
                                           colorMode,
                                           colors,
                                           statHelpCallback,
                                           isScreenBig,
                                           multiplierHelpCallback,
                                           subtractHelpCallback,
                                           autoSelectHelpCallback,
                                           replacementHelpCallback,
                                           statAmount,
                                           isShownPlayerUser
                                       }: StatisticPerformanceComponentProps) => {

        const renderSelectionInfo = () => {
            if (!isShownPlayerUser) {
                return (
                    <></>
                );
            }

            if (item.replaced) {
                return (
                    <HStack>
                        <Box mt={'4px'} mr={2}>
                            <FontAwesome name='warning' size={useBreakpointValue([10, 12, 14])}
                                         color={colorMode === 'light' ? colors['light']['error'] : colors['dark']['error']}/>
                        </Box>
                        <Text textAlign={'left'} fontSize={['xs', 'sm', 'md']}
                              color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                            Replacement {item.originalPlayer ? 'for ' + item.originalPlayer.displayName : ''}
                        </Text>
                        <Link ml={1} mt={'4px'} onPress={() => replacementHelpCallback()}>
                            <Feather name='help-circle' size={useBreakpointValue([10, 12, 14])}
                                     color={colorMode === 'light' ? colors['light']['contrastMid'] : colors['dark']['contrastMid']}/>
                        </Link>
                    </HStack>
                );
            }
            if (item.autoSelected) {
                return (
                    <VStack>
                        <HStack>
                            <Box mt={'4px'} mr={2}>
                                <FontAwesome name='warning' size={useBreakpointValue([10, 12, 14])}
                                             color={colorMode === 'light' ? colors['light']['warning'] : colors['dark']['warning']}/>
                            </Box>
                            <Text textAlign={'left'} fontSize={['xs', 'sm', 'md']}
                                  color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                                Auto-Selected
                            </Text>
                            <Link ml={1} mt={'4px'} onPress={() => autoSelectHelpCallback()}>
                                <Feather name='help-circle' size={useBreakpointValue([10, 12, 14])}
                                         color={colorMode === 'light' ? colors['light']['contrastMid'] : colors['dark']['contrastMid']}/>
                            </Link>
                        </HStack>
                    </VStack>
                );
            }

            return (
                <Text textAlign={'left'} fontSize={['xs', 'sm', 'md']} mr={['90px', '150px', '210px']}
                      color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                    For {item.statistic.displayName} you selected:
                </Text>
            );
        };

        return (
            <Flex position={'relative'}>
                <VStack bg={colorMode === 'light' ? 'light.layout' : 'dark.layout'} p={[6, 8, 10]}
                        rounded={'25px'} justifyContent={'space-between'}
                        style={{
                            shadowColor: '#505050',
                            shadowRadius: 15,
                            shadowOpacity: 0.05,
                        }}
                >
                    {/* Statistic + Athlete Info */}
                    {renderSelectionInfo()}
                    <Text textAlign={'left'} fontSize={['2xl', '3xl', '4xl']} mb={6} mr={['90px', '150px', '210px']}>
                        {item.selectedAthlete.displayName}
                    </Text>
                    <Text textAlign={'left'} fontSize={['xs', 'sm', 'md']} mb={2} underline={true}
                          color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                        Performance received:
                    </Text>
                    {/* Calculation */}
                    <HStack justifyContent={'space-between'}>
                        {
                            item.statistic.value === StatisticValueOptions.absolute || item.statistic.value === StatisticValueOptions.percentage ?
                                <RenderPositiveCalculation item={item} colorMode={colorMode} colors={colors}
                                                           multiplierHelpCallback={multiplierHelpCallback}
                                                           statHelpCallback={statHelpCallback} isScreenBig={isScreenBig}/>
                                :
                                <RenderNegativeCalculation item={item} colorMode={colorMode} colors={colors}
                                                           statHelpCallback={statHelpCallback}
                                                           multiplierHelpCallback={multiplierHelpCallback}
                                                           subtractHelpCallback={subtractHelpCallback}
                                                           isScreenBig={isScreenBig}/>
                        }
                    </HStack>
                </VStack>
                <HStack h={12} w={'full'} justifyContent={'flex-end'} alignItems={'stretch'} px={[6, 8, 10]}
                        zIndex={1}>
                    <Flex w={['80px', '140px', '200px']}
                          bg={colorMode === 'light' ? 'light.contrast' : 'dark.contrast'}
                          justifyContent={'center'}>
                        <Text color={index !== statAmount - 1 ? 'primary' : 'dark.contrastMid'}>
                            {index !== statAmount - 1 ? '+' : '='}
                        </Text>
                    </Flex>
                </HStack>
            </Flex>
        );
    }
;

interface RenderPositiveCalculationProps {
    item: PerformanceInfoType
    colorMode: ColorMode,
    colors: any,
    isScreenBig: boolean,
    statHelpCallback: (statistic: StatisticType) => void,
    multiplierHelpCallback: () => void,
}

const RenderPositiveCalculation = ({
                                       item,
                                       colorMode,
                                       statHelpCallback,
                                       multiplierHelpCallback
                                   }: RenderPositiveCalculationProps) => {

    return (
        <>
            <HStack maxWidth={'100%'} justifyContent={'space-evenly'} flex={'1 1 70%'} mt={['4px', '2px', '0px']}>
                <VStack alignItems={'center'} flexShrink={1}>
                    <Text fontSize={['xl', '2xl', '4xl']}>
                        {item.selectedAthlete.performanceValue}
                    </Text>
                    <Link ml={1} mt={'4px'} onPress={() => statHelpCallback(item.statistic)}>
                        <Text fontSize={['2xs', 'xs', 'md']}
                              color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                            {item.statistic.displayName + ' 🛈'}
                        </Text>
                    </Link>
                </VStack>
                <Text fontSize={['sm', 'md', 'xl']} mt={['3px', '6px', '9px']} mx={1}
                      color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                    x
                </Text>
                <VStack alignItems={'center'} flexShrink={1}>
                    <Text fontSize={['xl', '2xl', '4xl']}>
                        {item.statistic.performanceMultiplier}
                    </Text>
                    <Link ml={1} mt={'4px'} onPress={() => multiplierHelpCallback()} flexShrink={1}>
                        <Text fontSize={['2xs', 'xs', 'md']}
                              color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                            multiplier 🛈
                        </Text>
                    </Link>
                </VStack>
                <Text fontSize={['sm', 'md', 'xl']} mt={['3px', '6px', '9px']} ml={1}
                      color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                    =
                </Text>
            </HStack>
            {/* Points */}
            <VStack w={['80px', '140px', '200px']}>
                <Text fontSize={['2xl', '3xl', '4xl']}>
                    {item.selectedAthlete.performanceValue * item.statistic.performanceMultiplier}
                </Text>
                <Text fontSize={['xs', 'sm', 'md']}
                      color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                    Points
                </Text>
            </VStack>
        </>
    );
};


interface RenderNegativeCalculationProps {
    item: PerformanceInfoType
    colorMode: ColorMode,
    colors: any,
    isScreenBig: boolean,
    statHelpCallback: (statistic: StatisticType) => void,
    subtractHelpCallback: () => void,
    multiplierHelpCallback: () => void,
}

const RenderNegativeCalculation = ({
                                       item,
                                       colorMode,
                                       statHelpCallback,
                                       subtractHelpCallback,
                                       multiplierHelpCallback
                                   }: RenderNegativeCalculationProps) => {

    return (
        <>
            <HStack flex={'1 1 70%'} justifyContent={'space-evenly'} mt={['4px', '6px', '8px']}>
                {/* Base Value */}
                <VStack alignItems={'center'} flexShrink={1} w={'30%'}>
                    <Text fontSize={['xl', '2xl', '4xl']}>
                        {item.statistic.subtractValue}
                    </Text>
                    <Link ml={1} mt={'4px'} onPress={() => subtractHelpCallback()} flexShrink={1}>
                        <Text fontSize={['2xs', 'xs', 'md']}
                              color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                            base value 🛈
                        </Text>
                    </Link>
                </VStack>
                <Text fontSize={['sm', 'md', 'xl']} mt={['3px', '6px', '9px']} mx={1}
                      color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                    -
                </Text>
                {/* Statistic */}
                <HStack w={'40%'}>
                    <VStack alignItems={'center'} flexShrink={1}>
                        <HStack>
                            <Text fontSize={['xl', '2xl', '4xl']}
                                  color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                                {'('}
                            </Text>
                            <Text fontSize={['xl', '2xl', '4xl']}>
                                {item.selectedAthlete.performanceValue}
                            </Text>
                        </HStack>
                        <Link ml={1} mt={'4px'} onPress={() => statHelpCallback(item.statistic)} flexShrink={1}>
                            <Text
                                color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}
                                fontSize={['2xs', 'xs', 'md']}>
                                {item.statistic.displayName + ' 🛈'}
                            </Text>
                        </Link>
                    </VStack>
                    <Text fontSize={['sm', 'md', 'xl']} mt={['3px', '6px', '9px']} mx={1}
                          color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                        x
                    </Text>
                    {/* Subtract Weight */}
                    <VStack alignItems={'center'} flexShrink={1}>
                        <HStack>
                            <Text fontSize={['xl', '2xl', '4xl']}>
                                {item.statistic.subtractMultiplier}
                            </Text>
                            <Text fontSize={['xl', '2xl', '4xl']}
                                  color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                                {')'}
                            </Text>
                        </HStack>
                        <Link ml={1} mt={'4px'} onPress={() => subtractHelpCallback()} flexShrink={1}>
                            <Text fontSize={['2xs', 'xs', 'md']}
                                  color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                                subtract weight 🛈
                            </Text>
                        </Link>
                    </VStack>
                </HStack>
                <Text fontSize={['sm', 'md', 'xl']} mt={['3px', '6px', '9px']} mx={1}
                      color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                    x
                </Text>
                {/* Performance Multiplier */}
                <VStack alignItems={'center'} flexShrink={1} w={'20%'}>
                    <Text fontSize={['xl', '2xl', '4xl']}>
                        {item.statistic.performanceMultiplier}
                    </Text>
                    <Link ml={1} mt={'4px'} onPress={() => multiplierHelpCallback()} flexShrink={1}>
                        <Text fontSize={['2xs', 'xs', 'md']}
                              color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                            multiplier 🛈
                        </Text>
                    </Link>
                </VStack>
                <Text fontSize={['xs', 'sm', 'xl']} mt={['3px', '6px', '9px']} ml={1}
                      color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                    =
                </Text>
            </HStack>
            {/* Points */}
            <VStack w={['80px', '140px', '200px']} ml={[2, 6, 10]}>
                <Text fontSize={['2xl', '3xl', '4xl']}>
                    {item.selectedAthlete.performanceValue * item.statistic.performanceMultiplier}
                </Text>
                <Text fontSize={['xs', 'sm', 'md']}
                      color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                    Points
                </Text>
            </VStack>
        </>
    );
};

export default StatisticPerformanceComponent;