import React from 'react';
import {Box, Flex, HStack, Pressable, ScrollView, Text, useColorMode, useTheme} from 'native-base';
import {AntDesign} from '@expo/vector-icons';

interface SubtractHelpModalProps {
    closeModal: () => void;
}

const SubtractHelpModal = ({closeModal}: SubtractHelpModalProps) => {

    const {colorMode} = useColorMode();
    const {colors} = useTheme();

    return (
        <Flex position={'absolute'} top={0} bottom={0} left={0} right={0} zIndex={10}
              justifyContent={'center'} alignItems={'center'} p={10}>
            <Pressable bg={colorMode === 'light' ? 'light.contrast' : 'dark.contrast'} position={'absolute'} top={0}
                       bottom={0} left={0} right={0} opacity={0.7} onPress={closeModal}/>
            <Box bg={colorMode === 'light' ? 'light.layout' : 'dark.layout'} rounded={'2xl'} flexShrink={1}>
                <ScrollView contentContainerStyle={{padding: '24px'}}>
                    <HStack justifyContent={'space-between'}>
                        <Text mr={4} fontSize={['2xl', '3xl', '4xl']} textAlign={'left'} mb={2}>
                            Subtract Points Calculation
                        </Text>
                        <Pressable onPress={closeModal}>
                            {
                                ({isPressed, isHovered}) => (
                                    <AntDesign name='closecircle' size={28}
                                               color={isPressed || isHovered ? (colorMode === 'light' ? colors['light']['error'] : colors['dark']['error']) : colors['primary']}/>
                                )
                            }
                        </Pressable>
                    </HStack>
                    <Text textAlign={'left'} fontSize={['lg', 'xl', '2xl']}
                          color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                        Some statistics have a reversed weighting. For example, it is better to have no double
                        faults at all than to have multiple double faults.
                        {'\n'}
                        To calculate digital points accordingly, the subtract-points system is used. A fixed default
                        value is used as a base value. The performance during the match is then subtracted from the base
                        value. The result is then multiplied by the weighting of the statistic.
                        {'\n'}
                        Example:
                        {'\n'}
                        The base value for double faults is 100 digital points.
                        {'\n'}
                        If you chose Rafael Nadal for the statistic 'double faults' and he performs no double faults,
                        you receive 100 digital points.
                        {'\n'}
                        Each double fault is then weighted and subtracted from the base value. So, if Nadal does 1
                        double
                        fault, an example calculation would be: 100 base points - (1 double fault * 10 weighting) = 90
                        digital
                        points.
                    </Text>
                </ScrollView>
            </Box>
        </Flex>
    );
};

export default SubtractHelpModal;