import React from 'react';
import {ColorMode, Flex, HStack, Text} from "native-base";

interface DashboardPlayerHeaderProps {
    isScreenBig: boolean,
    colorMode: ColorMode,
    userName: string,
}

const DashboardPlayerHeader = ({isScreenBig, colorMode, userName}: DashboardPlayerHeaderProps) => {
    return (
        <Flex direction={isScreenBig ? 'row' : 'column'} alignItems={isScreenBig ? 'center' : 'flex-start'}
              justifyContent={'space-between'} px={['15px', '25px', '35px']}>
            <HStack mr={isScreenBig ? 6 : 0} mb={isScreenBig ? 0 : 1}>
                <Text fontSize={['xl', 'xl', '2xl']}
                      color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                    Welcome, {userName}
                </Text>
            </HStack>
        </Flex>
    );
};

export default DashboardPlayerHeader;