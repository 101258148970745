import React from 'react';
import {Center, Image, Text, useColorMode} from 'native-base';

const LoadingScreen = () => {

    return (
        <Center justifyContent={'center'} flexGrow={1}>
            <Image w={'20%'} h={'20%'} source={require('assets/icon.png')}/>
            <Text color={'primaryPartner'}>
                Loading...
            </Text>
        </Center>
    );
};

export default LoadingScreen;