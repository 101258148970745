import React, {useEffect, useRef, useState} from 'react';
import {LeagueScreenProps} from '../types';
import {Box, Center, Container, HStack, Link, Pressable, Text, useColorMode, useTheme, VStack,} from 'native-base';
import {getLeagueById} from './api/leagueSlice';
import AppBackground from 'components/essential/AppBackground';
import {useAppDispatch, useAppSelector} from 'app/redux/hooks';
import LeaguePlayerList from './components/PlayerList/LeaguePlayerList';
import {getTournamentById} from '../allTournaments/api/tournamentInfoSlice';
import {addPlayerToLeague} from './api/functions/addPlayerToLeague';
import {store} from 'app/redux/store';
import {getTournamentDataById} from '../tournament/api/tournamentDataSlice';
import LeagueRoundPicker from './components/LeagueRoundPicker';
import {possibleRoundIds, possibleRoundStates, RoundDataType} from '../tournament/api/types';
import {useWindowDimensions, ScrollView, Platform, Alert} from 'react-native';
import FinalRankingList from './components/PlayerList/FinalRankingList';
import {AntDesign} from '@expo/vector-icons';
import {fetchTournamentData} from '../tournament/api/functions/fetchTournamentData';
import LeagueInfoHeader from './components/LeagueInfoHeader';
import JoinLeagueComponent from './components/LeagueScreen/JoinLeagueComponent';

const LeagueScreen = ({route, navigation}: LeagueScreenProps) => {

    const {leagueId, tournamentId} = route.params;

    //temp
    // const leagueId = 'zJcMEq6tUOnLxdizHPGG';
    // const tournamentId = 'monte-carlo-2022';
    // // @ts-ignore
    // const leagues = useAppSelector(state => selectLeaguesByTournament(state, tournamentId));
    // const leagueApiStatus = useAppSelector(state => state.leagues.status);
    // useEffect(() => {
    //     if (leagues.length <= 0 && leagueApiStatus !== possibleLeagueApiStatus.loading)
    //         dispatch(getAllLeaguesForTournamentId(tournamentId));
    //     if (!tournamentData)
    //         dispatch(fetchTournamentData(tournamentId));
    // }, []);
    //end temp

    //helper
    const {colorMode} = useColorMode();
    const {colors} = useTheme();
    const dimensions = useWindowDimensions();
    const isScreenBig = dimensions.width >= 768;
    const dispatch = useAppDispatch();

    //get league info
    //useAppSelector to re-render the league screen when the league info changes (i.e. when a player joins)
    const leagueInfo = useAppSelector(state => getLeagueById(state, leagueId));

    //get tournament info for displaying available rounds and players
    const tournamentInfo = getTournamentById(store.getState(), tournamentId);
    const tournamentData = useAppSelector(state => getTournamentDataById(state, tournamentId));

    //get current user to check if user is part or to add user to league
    const user = useAppSelector(state => state.auth.user);

    //UI State
    const [isPlayerPart, setIsPlayerPart] = useState(false);
    const [canPlayerJoin, setCanPlayerJoin] = useState(false);
    const [selectedRound, setSelectedRound] = useState<RoundDataType | null>(null);
    const [hasTournamentStarted, setHasTournamentStarted] = useState(false);
    const [showFinalRanking, setShowFinalRanking] = useState(false);

    // player scroll
    const scrollRef = useRef<ScrollView>();

    //----------------------------------------------------------------- Initial Setup
    //initial setup of league status (is player part, can player join, etc)
    useEffect(() => {
        if (leagueInfo !== undefined && tournamentInfo !== undefined) {
            //is player part?
            let isPart = leagueInfo.players?.find(player => player.userUid === user.uid) !== undefined;
            setIsPlayerPart(isPart);
            //can player join?
            if (!isPart) {
                let canJoin = tournamentInfo.currentRound === possibleRoundIds.upcoming || leagueInfo.canJoinLater;
                setCanPlayerJoin(canJoin);
            }
            //has tournament started?
            let hasStarted = tournamentInfo.currentRound !== possibleRoundIds.upcoming;
            setHasTournamentStarted(hasStarted);
        }
    }, [user, leagueInfo, tournamentInfo]);

    //get first selected round and check if the tournament is complete and final ranking should be displayed
    useEffect(() => {
        if (tournamentData && tournamentData.rounds.length > 0 && selectedRound === null) {

            //set selected round for the round picker
            const arrayForReverse = [...tournamentData.rounds];
            arrayForReverse.reverse();
            let first = arrayForReverse.findIndex(round => round.status !== possibleRoundStates.upcoming);
            if (first !== -1) {
                setSelectedRound(arrayForReverse[first]);
            } else {
                setSelectedRound(tournamentData.rounds[0]);
            }

            //check if final screen should be shown
            if (tournamentData.finalRoundInfos.athlete !== '') {
                setShowFinalRanking(true);
            }
        } else if (!tournamentData) {
            dispatch(fetchTournamentData(tournamentId));
        }
    }, [tournamentData]);

    //----------------------------------------------------------------- Callbacks
    const handleJoinLeagueWish = () => {
        if (JoinLeagueConfirmAlert())
            joinLeague();
    };

    const joinLeague = () => {
        if (user && leagueInfo)
            dispatch(addPlayerToLeague({leagueInfo, userUid: user.uid, playerName: user.userName}));
    };

    const roundSelectedCallback = (round: RoundDataType) => {
        setSelectedRound(round);
    };

    const scrollToTop = () => {
        scrollRef.current?.scrollTo({x: 0, y: 0, animated: true});
    };

    //----------------------------------------------------------------- Render States
    if (!leagueInfo || !tournamentInfo || (!hasTournamentStarted && !tournamentData)) {
        return (
            <AppBackground>
                <Center>
                    <Text color={colorMode === 'light' ? 'light.error' : 'dark.error'}>
                        This league is not available on the server
                    </Text>
                </Center>
            </AppBackground>
        );
    }

    return (
        <AppBackground position='relative'>
            <ScrollView ref={ref => scrollRef.current = ref ?? undefined}
                        style={{height: '80vh'}}> {/* style={{height: '85vh'}} */}
                {/* Header */}
                <Box px={['4', '10', '16']} pt={['8', '10', '16']}>
                    <LeagueInfoHeader leagueInfo={leagueInfo} tournamentInfo={tournamentInfo} colorMode={colorMode}
                                      colors={colors} hasTournamentStarted={hasTournamentStarted}
                                      showFinalRanking={showFinalRanking}/>
                </Box>
                {/* Body */}
                <Box maxW='1280px' p={['4', '10', '16']} py={['8', '10', '16']} mb={10}>
                    {/* Info Header*/}
                    {
                        isPlayerPart ?
                            //League Round Picker
                            (
                                hasTournamentStarted && tournamentData ?
                                    (
                                        !showFinalRanking ?
                                            <LeagueRoundPicker rounds={tournamentData.rounds} isScreenBig={isScreenBig}
                                                               onWinnerPress={() => setShowFinalRanking(true)}
                                                               onPress={roundSelectedCallback}
                                                               selectedRound={selectedRound}
                                                               isTournamentComplete={tournamentInfo.currentRound === possibleRoundIds.completed}/>
                                            : <></>
                                    )
                                    :
                                    <></>
                            )
                            :
                            //Join League Component
                            (
                                canPlayerJoin ?
                                    <JoinLeagueComponent joinLeagueCallback={handleJoinLeagueWish} colorMode={colorMode}
                                                         colors={colors}/>
                                    : <></>
                            )
                    }
                    {/* Player List*/}
                    {
                        !showFinalRanking ?
                            <Container display='flex' flexGrow='1' p={['4', '6', '8']}
                                       rounded={['30px', '40px', '50px']}>
                                <LeaguePlayerList colorMode={colorMode} colors={colors}
                                                  currentRound={tournamentInfo.currentRound}
                                                  scrollViewRef={scrollRef} isPlayerPart={isPlayerPart}
                                                  leagueId={leagueId} tournamentData={tournamentData}
                                                  hasTournamentStarted={hasTournamentStarted}
                                                  selectedRound={selectedRound} navigation={navigation}/>
                            </Container>
                            :
                            <VStack>
                                {
                                    isPlayerPart ?
                                        <HStack justifyContent={'flex-end'} mb={'2'} px={['4', '6', '8']}
                                                alignItems={'flex-end'}>
                                            <Link
                                                _text={{
                                                    fontSize: 'xs',
                                                    color: colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'
                                                }}
                                                onPress={() => setShowFinalRanking(false)}
                                            >
                                                show performances
                                            </Link>
                                        </HStack>
                                        : <></>
                                }
                                <Container display='flex' flexGrow='1' minH='640px' p={['4', '6', '8']}
                                           rounded={['30px', '40px', '50px']}>
                                    <FinalRankingList colorMode={colorMode} colors={colors} leagueId={leagueId}
                                                      scrollViewRef={scrollRef}/>
                                </Container>
                            </VStack>
                    }
                </Box>
            </ScrollView>
            {/* Up Arrow */}
            <Pressable flex={1} justifyContent='center' alignItems='center' position='absolute' right={7} bottom={7}
                       p={2} rounded='full' bg={colorMode === 'light' ? 'light.layoutShade' : 'dark.layoutShade'}
                       onPress={scrollToTop}>
                <AntDesign name='arrowup' size={20}
                           color={colorMode === 'light' ? colors['light']['contrast'] : colors['dark']['contrast']}/>
            </Pressable>
        </AppBackground>
    );
};

const JoinLeagueConfirmAlert = () => {
    if (Platform.OS === 'web') {
        if (confirm('Do you want to join this league?')) {
            return true;
        }
    } else {
        return Alert.alert(
            'Join league',
            'Do you want to join this league?',
            [
                {
                    text: 'Cancel',
                    onPress: () => false,
                    style: 'cancel',
                },
                {
                    text: 'OK',
                    onPress: () => true
                },
            ],
        );
    }
};

export default LeagueScreen;