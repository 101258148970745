import {NativeStackNavigationProp, NativeStackScreenProps} from '@react-navigation/native-stack';
import {JoinedPlayerType} from './leagues/api/types';
import {RoundDataType} from './tournament/api/types';
import {StatisticSelectionMatchType, StatisticSelectionType} from './leagues/vPlayerBuilding/types';
import {StatisticType} from './statistics/api/types';

export enum possibleTournamentScreens {
    AllTournaments = 'AllTournaments',
    Tournament = 'Tournament',
    League = 'League',
    VPlayerBuilding = 'VPlayerBuilding',
    AthleteSelection = 'AthleteSelection',
    StatisticHelp = 'StatisticHelp',
    VPlayerPerformance = 'VPlayerPerformance',
}

export type TournamentNavigatorParamList = {
    AllTournaments: undefined,
    Tournament: { tournamentId: string }
    League: { leagueId: string, tournamentId: string }
    VPlayerBuilding: { playerInfo: JoinedPlayerType, leagueId: string, tournamentId: string, selectedRound: RoundDataType }
    AthleteSelection: { matches: StatisticSelectionMatchType[], statisticSelection: StatisticSelectionType, isEntry: boolean }
    StatisticHelp: { statistic: StatisticType }
    VPlayerPerformance: { playerUid: string, leagueId: string, tournamentId: string, selectedRound: RoundDataType }
}

export type AllTournamentsScreenProps = NativeStackScreenProps<TournamentNavigatorParamList, possibleTournamentScreens.AllTournaments>
export type AllTournamentsNavigationProp = NativeStackNavigationProp<TournamentNavigatorParamList, possibleTournamentScreens.AllTournaments>

export type TournamentScreenProps = NativeStackScreenProps<TournamentNavigatorParamList, possibleTournamentScreens.Tournament>
export type TournamentNavigationProp = NativeStackNavigationProp<TournamentNavigatorParamList, possibleTournamentScreens.Tournament>

export type LeagueScreenProps = NativeStackScreenProps<TournamentNavigatorParamList, possibleTournamentScreens.League>
export type LeagueNavigationProp = NativeStackNavigationProp<TournamentNavigatorParamList, possibleTournamentScreens.League>

export type VPlayerBuildingScreenProps = NativeStackScreenProps<TournamentNavigatorParamList, possibleTournamentScreens.VPlayerBuilding>
export type VPlayerBuildingNavigationProp = NativeStackNavigationProp<TournamentNavigatorParamList, possibleTournamentScreens.VPlayerBuilding>

export type AthleteSelectionScreenProps = NativeStackScreenProps<TournamentNavigatorParamList, possibleTournamentScreens.AthleteSelection>
export type AthleteSelectionNavigationProp = NativeStackNavigationProp<TournamentNavigatorParamList, possibleTournamentScreens.AthleteSelection>

export type StatisticHelpScreenProps = NativeStackScreenProps<TournamentNavigatorParamList, possibleTournamentScreens.StatisticHelp>
export type StatisticHelpNavigationProp = NativeStackNavigationProp<TournamentNavigatorParamList, possibleTournamentScreens.StatisticHelp>

export type VPlayerPerformanceScreenProps = NativeStackScreenProps<TournamentNavigatorParamList, possibleTournamentScreens.VPlayerPerformance>
export type VPlayerPerformanceNavigationProp = NativeStackNavigationProp<TournamentNavigatorParamList, possibleTournamentScreens.VPlayerPerformance>