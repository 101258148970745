import {Button, useColorMode, useColorModeValue, useTheme} from 'native-base';
import {View} from 'react-native';
import {Feather, FontAwesome} from '@expo/vector-icons';
import React from 'react';

const ColorModeSwitchButton = (props: any) => {

    //color mode toggle
    const {toggleColorMode, colorMode} = useColorMode();

    //for fontawesome icon buttons
    const {colors} = useTheme();

    return (
        <Button onPress={toggleColorMode} p={0} rounded={'full'} minWidth={0} w={10} h={10}
                bg={useColorModeValue('light.contrastLow', 'dark.contrastLow')} {...props}
            // position={"absolute"} right={5} top={5} _ios={{top: 16}}>
        >
            {
                colorMode === 'light' ?
                    <View style={{transform: [{translateX: 1}, {translateY: 1}]}}>
                        <FontAwesome name='moon-o' size={18} color={colors['light']['contrast']}/>
                    </View>
                    :
                    <Feather name='sun' size={18} color={colors['dark']['contrast']}/>}
        </Button>
    );
};

export default ColorModeSwitchButton;