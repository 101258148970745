const firebaseConfig = {
    apiKey: 'AIzaSyBb4RjfXxHIaXBQosKQuaGdSNQpjnqA0I4',
    authDomain: 'digital-tennis-tour.firebaseapp.com',
    projectId: 'digital-tennis-tour',
    storageBucket: 'digital-tennis-tour.appspot.com',
    messagingSenderId: '1030180257416',
    appId: '1:1030180257416:web:e0b1d53062e9dd6311a93d',
    measurementId: 'G-VVYZ4MF83E'
};

export default firebaseConfig;