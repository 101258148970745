import {createAsyncThunk} from '@reduxjs/toolkit';
import {collection, getDocs, query, where} from 'firebase/firestore';
import {fb_firestore} from '../../../../../App';
import {LeagueDataType, LeagueVisibilityOptions,} from '../types';
import {useAppDispatch} from 'app/redux/hooks';
import {resetErrorMessage} from '../leagueSlice';
import {RootState} from '../../../../../app/redux/store';
import {fetchPlayersForLeague} from './helper/fetchPlayersForLeague';

export const getAllLeaguesForTournamentId = createAsyncThunk(
    'leagues/getAllLeaguesForTournament',
    async (tournamentId: string, {rejectWithValue, getState}) => {
        try {
            //query for all leagues for that specific tournament id
            const q = query(collection(fb_firestore, 'leagues'), where('tournament', '==', tournamentId));
            const querySnapshot = await getDocs(q);

            //get current user to check for loading additional player data
            const state = getState() as RootState;
            const currentUser = state.auth.user;

            const leagues: LeagueDataType[] = [];
            await Promise.all(querySnapshot.docs.map(async (leagueDoc) => {
                const league = leagueDoc.data();

                const players = await fetchPlayersForLeague(leagueDoc.id, currentUser?.uid);

                leagues.push({
                    id: leagueDoc.id,
                    name: league.name,
                    tournamentId: league.tournament,
                    visibility: league.visibility as LeagueVisibilityOptions,
                    canJoinLater: league.canPlayerJoinLater,
                    selectedStatistics: league.statistics,
                    players: players,
                });
            }));

            //
            // console.log('LOADED LEAGUES');
            // console.table(leagues);
            return leagues;
        } catch (e) {
            console.log('error in league get');
            console.log(e.message);
            resetErrorAfterDelay().then();
            return rejectWithValue(e.message);
        }
    }
);

const resetErrorAfterDelay = async () => {
    setTimeout(() => {
        const dispatch = useAppDispatch();
        dispatch(resetErrorMessage());
    }, 20000);
};