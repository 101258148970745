import React from 'react';
import {ColorMode, Flex, Link} from 'native-base';
import {AntDesign} from '@expo/vector-icons';

const TournamentNavigatorHeader = ({
                                       navigation,
                                       colorMode,
                                       colors,
                                   }: { navigation: any, colorMode: ColorMode, colors: any, layout?: any }) => {

    const itemColor = colorMode === 'light' ? colors['light']['contrast'] : colors['dark']['contrast'];

    const goBackCallback = () => {
        if (navigation.canGoBack())
            navigation.goBack();
    };

    return (
        <Flex bg={colorMode === 'light' ? 'light.bg' : 'dark.bg'} flexDir='row' justifyContent='flex-start' px='20px'>
            {
                navigation.canGoBack() ?
                    <Link onPress={goBackCallback} mt='10px'>
                        <AntDesign name='arrowleft' size={36} color={itemColor}/>
                    </Link>
                    :
                    <></>
            }
        </Flex>
    );
};

export default TournamentNavigatorHeader;