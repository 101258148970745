import React, {useState} from 'react';
import {
    Box,
    Button,
    Container,
    FormControl,
    Heading,
    HStack,
    Input,
    KeyboardAvoidingView,
    Link, ScrollView,
    Spinner,
    Text,
    useColorMode,
    useTheme, VStack,
} from 'native-base';
import {getAuth, signInWithEmailAndPassword} from 'firebase/auth';
import {InputFieldErrorMessage, validateEmail} from './helper/AuthComponentHelper';
import {
    AuthenticationScreenProps, possibleAuthComponents, possiblePublicScreens
} from '../types';

/**
 * Interface
 */
type LoginComponentProps = {
    switchAuthComponent: (newState: possibleAuthComponents) => void,
    navigation: AuthenticationScreenProps['navigation']
}

interface IUserData {
    email: string,
    password: string
}

interface IValidation {
    email: string,
    password: string,
    server: string
}

enum possibleLoginStates {
    idle = 'idle',
    loading = 'loading',
    failed = 'failed'
}

/**
 * Login Component
 * Displays the UI for login logic
 * handles the
 * @param switchAuthComponent
 * @param navigation
 * @constructor
 */
const LoginComponent = ({switchAuthComponent, navigation}: LoginComponentProps) => {

    //---------------------------------------------------------------------------------------- state
    //state
    const [userData, setUserData] = useState<IUserData>({email: '', password: ''});

    //ui helper state
    const [validation, setValidation] = useState<IValidation>({email: '', password: '', server: ''});

    const [loginStatus, setLoginStatus] = useState<string>(possibleLoginStates.idle);

    //colors to be used when hooks cannot be used
    const {colors} = useTheme();
    const {colorMode} = useColorMode();

    //---------------------------------------------------------------------------------------- callbacks
    //navigation callbacks
    const switchToRegisterCallback = () => {
        switchAuthComponent(possibleAuthComponents.register);
    };
    const passwordForgotCallback = () => {
        navigation.navigate(possiblePublicScreens.PasswordReset);
    };

    //---------------------------------------------------------------------------------------- login logic
    //login button callback
    const loginButtonCallback = () => {

        //-------------------------------------------------------- Checks
        /* check if the database request is already dispatched (either it is loading, then display server message
        - or the status is 'succeeded' then the navigation displays the next screen anyway) */
        if (!(loginStatus === possibleLoginStates.idle || loginStatus === possibleLoginStates.failed)) {
            console.log(`cant login because of auth status ${loginStatus}`);
            setValidation({email: '', password: '', server: 'Server is loading, please wait or reload the page.'});
            return;
        }

        //check email
        let email = '';
        if (userData.email === '')
            email = 'Please provide your email address.';
        else if (!validateEmail(userData.email))
            email = 'Please provide a valid email format, like `name@example.com`';

        //check password
        let password = '';
        if (userData.password === '')
            password = 'Please provide a password.';
        else if (userData.password.length < 6)
            password = 'Password is at least 6 characters.';

        //update form
        setValidation({email: email, password: password, server: ''});

        //block the request here - to check both email and password before blocking
        if (![email, password].every(input => input === '')) {
            console.log('email or password not correct');
            return;
        }

        setLoginStatus(possibleLoginStates.loading);

        //-------------------------------------------------------- Call Login
        loginUser({
            email: userData.email,
            password: userData.password,
        }).catch((loginError) => {
            setLoginStatus(possibleLoginStates.failed);

            const empty = {email: '', password: '', server: ''};

            //check why failed
            //check for email errors
            if (loginError === 'auth/invalid-email')
                setValidation({...empty, email: 'Email not formatted correctly.'});
            else if (loginError === 'auth/user-not-found')
                setValidation({...empty, email: 'A user with this email is not registered yet.'});
            //check for password errors
            else if (loginError === 'auth/invalid-password')
                setValidation({...empty, password: 'Invalid password. Password is at least 6 characters.'});
            else if (loginError === 'auth/wrong-password')
                setValidation({...empty, server: 'Email or password is wrong.'});
            //auth errors
            else if (loginError === 'auth/too-many-requests')
                setValidation({
                    ...empty, server: 'Access to this account has been temporarily disabled due to ' +
                        'many failed login attempts. You can immediately restore it by resetting your password or ' +
                        'you can try again later.'
                });
            //else set server error message
            else
                setValidation({...empty, server: loginError?.replace('auth/', '') ?? ''});
        });
    };

    const loginUser = async ({email, password}: { email: string, password: string }) => {
        //catch double inputs
        if (loginStatus === possibleLoginStates.loading) {
            throw 'Server is loading. Please wait or refresh the page.';
        }

        const auth = getAuth();
        return signInWithEmailAndPassword(auth, email, password)
            .catch((error) => {
                throw error.code;
            });

        //old persistence code - firebase seems to handle this automatically
        // return setPersistence(auth, browserLocalPersistence)
        // return setPersistence(auth, browserLocalPersistence)
        //     .then(() => {
        //         // console.log('persistence set');
        //         return signInWithEmailAndPassword(auth, email, password);
        //     })
        //     .catch((error) => {
        //         throw error.code;
        //     });
    };

    //---------------------------------------------------------------------------------------- render
    return (
        // <KeyboardAvoidingView flexGrow={1} width='full' justifyContent='center'>
        //     <ScrollView
        //         _contentContainerStyle={{
        //             flex: 1,
        //             justifyContent: 'center',
        //             alignItems: 'center',
        //             padding: ['4', '6', '8']
        //         }}>
        <KeyboardAvoidingView>
            <Container maxWidth='512px'>
                <Heading textAlign={'left'} mr={'auto'}>
                    Login
                </Heading>
                <HStack space={2} mb={10} mr={'auto'}>
                    <Text>
                        No account?
                    </Text>
                    <Link _text={{color: 'primary'}} onPress={switchToRegisterCallback}>
                        Register
                    </Link>
                </HStack>
                <VStack space='5' mb='10' ml='2' mr='2'>
                    {/*Email*/}
                    <FormControl isInvalid={validation.email !== ''}>
                        <FormControl.Label>Email</FormControl.Label>
                        <Input placeholder='my-email@mail.com' value={userData.email}
                               onChangeText={value => setUserData({...userData, email: value})}/>
                        {
                            validation.email === '' ? <></> :
                                <InputFieldErrorMessage colors={colors} colorMode={colorMode ?? ''}
                                                        message={validation.email} isSuccess={false}/>
                        }
                    </FormControl>
                    {/*Password*/}
                    <FormControl isInvalid={validation.password !== ''}>
                        <FormControl.Label>Password</FormControl.Label>
                        <Input type='password' value={userData.password}
                               onChangeText={value => setUserData({...userData, password: value})}/>
                        {
                            validation.password === '' ? <></> :
                                <InputFieldErrorMessage colors={colors} colorMode={colorMode ?? ''}
                                                        message={validation.password} isSuccess={false}/>
                        }
                        <Link _text={{
                            fontSize: 'sm',
                            fontWeight: '500',
                            color: 'blueGray.500'
                        }} alignSelf='flex-end' mt='1' onPress={passwordForgotCallback}>
                            Forgot Password?
                        </Link>
                    </FormControl>
                </VStack>
                <Box width='full' display='flex' flexDir='column' alignItems='stretch'>
                    <Button onPress={loginButtonCallback} w='full'>
                        {
                            loginStatus !== possibleLoginStates.loading ? 'Login' :
                                <Spinner size={'lg'}
                                         color={colorMode === 'light' ? 'light.contrastPartner' : 'dark.contrastPartner'}/>
                        }
                    </Button>
                    {
                        validation.server === '' ? <></> :
                            <Text fontSize={['sm', 'md', 'lg']} width={'full'} mt={2} mb={0} textAlign={'center'}
                                  color={colorMode === 'light' ? 'light.error' : 'dark.error'}>
                                {validation.server}
                            </Text>
                    }
                </Box>
                {/*<Box h={'2px'} w={'80%'} bg={useColorModeValue('light.contrastLow', 'dark.contrastLow')} mb={8}/>*/}
                {/*<Box style={{marginBottom: '10px'}}>*/}
                {/*    <FontAwesome.Button style={{width: '100%'}} name="google"*/}
                {/*                        backgroundColor={useColorModeValue(colors['light']['contrastMid'], colors['dark']['contrastMid'])}*/}
                {/*                        onPress={() => console.log('login with facebook')}>*/}
                {/*        Login with Google*/}
                {/*    </FontAwesome.Button>*/}
                {/*</Box>*/}
                {/*<FontAwesome.Button name="facebook"*/}
                {/*                    backgroundColor={useColorModeValue(colors['light']['contrastMid'], colors['dark']['contrastMid'])}*/}
                {/*                    onPress={() => console.log('login with facebook')}>*/}
                {/*    Login with Facebook*/}
                {/*</FontAwesome.Button>*/}
            </Container>
        </KeyboardAvoidingView>
        // </ScrollView>
        // </KeyboardAvoidingView>
    );
};

export default LoginComponent;