import {Box, ColorMode, Heading, HStack, Link, Text, VStack} from 'native-base';
import React from 'react';
import {JoinedPlayerType} from '../../api/types';
import {TournamentInfoDataType} from '../../../allTournaments/api/tournamentInfoSlice';
import {RoundDataType} from '../../../tournament/api/types';
import Badge from '../../../../../components/basic/Badge';
import {convertRoundName} from '../../../../../app/helper/nameConversionHelper';

interface ListInfoHeaderProps {
    playerInfo: JoinedPlayerType | null,
    tournamentInfo: TournamentInfoDataType | undefined,
    selectedRound: RoundDataType,
    colorMode: ColorMode
}

export const ListInfoHeader = ({
                                   playerInfo,
                                   tournamentInfo,
                                   selectedRound,
                                   colorMode
                               }: ListInfoHeaderProps) => {

    const [moreInfoShown, setMoreInfoShown] = React.useState(false);

    return (
        <VStack alignItems='flex-start' mb='10'>
            <Heading lineHeight='1em' mb='-10px' textAlign='left'>
                {playerInfo?.playerName}
            </Heading>
            <Text textAlign='left' mb='2'>
                {tournamentInfo?.name}
            </Text>
            <Badge
                text={convertRoundName(selectedRound.id)}
                variant='default'
                colorMode={colorMode}
            />
            <VStack mt={6} alignItems={'flex-start'}>
                <Text textAlign='left' fontSize={['sm', 'md', 'lg']}
                      color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                    Build your own digital player by connecting each defining statistic with one tennis athlete.
                    {/*Choose the player who, in your estimation, performs the best for the respective statistic.*/}
                </Text>
                {
                    !moreInfoShown ?
                        <Link _text={{
                            textAlign: 'left',
                            fontSize: ['2xs', 'xs', 'sm'],
                            mt: 2,
                            color: (colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid')
                        }} onPress={() => setMoreInfoShown(!moreInfoShown)}>
                            More info ...
                        </Link>
                        : <></>
                }
                {
                    moreInfoShown ?
                        <VStack>
                            <HStack alignItems='stretch' mt={2}>
                                <Box w='2px' mr={2}
                                     bg={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}/>
                                <Text textAlign='left' fontSize={['sm', 'md', 'lg']}
                                      color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                                    The performance of the tennis athlete for the specific statistic you chose, will
                                    determine your digital points.
                                    {'\n'}
                                    To get more information about the statistics and the calculation click the ? icon
                                    and view a detailed explanation.
                                    {'\n'}
                                    Only athletes for this particular round are available for selection. After all
                                    matches for the respective round are played, the performances will be used to
                                    calculate your digital tennis tour points.
                                    {'\n'}
                                </Text>
                            </HStack>
                            <Link _text={{
                                textAlign: 'left',
                                fontSize: ['2xs', 'xs', 'sm'],
                                mt: 2,
                                color: (colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid')
                            }} onPress={() => setMoreInfoShown(false)}>
                                Hide info.
                            </Link>
                        </VStack>
                        : <></>
                }
            </VStack>
        </VStack>
    );
};

