import React from 'react';
import {Box, ColorMode, Text} from 'native-base';


interface BadgeProps {
    text: string;
    colorMode: ColorMode;
    variant?: 'highlight' | 'hovered' | 'error' | 'default' | 'success';
}

const Badge = (props: BadgeProps | any) => {
    const {colorMode, text, variant} = props;

    let bgColor, textColor;

    switch (variant) {
        case 'highlight':
            bgColor = 'primary';
            textColor = 'primaryPartner';
            break;
        case 'hovered':
            bgColor = colorMode === 'light' ? 'light.contrast' : 'dark.contrast';
            textColor = colorMode === 'light' ? 'light.contrastPartner' : 'dark.contrastPartner';
            break;
        case 'error':
            bgColor = colorMode === 'light' ? 'light.error' : 'dark.error';
            textColor = colorMode === 'light' ? 'light.errorPartner' : 'dark.errorPartner';
            break;
        case 'success':
            bgColor = colorMode === 'light' ? 'light.success' : 'dark.success';
            textColor = colorMode === 'light' ? 'light.successPartner' : 'dark.successPartner';
            break;
        default:
            bgColor = colorMode === 'light' ? 'light.layoutShade' : 'dark.layoutShade';
            textColor = colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid';
            break;
    }

    return (
        <Box bg={bgColor}
             px='4' py='1' rounded='full' {...props}>
            <Text fontSize={['2xs', 'xs', 'sm']} color={textColor}>
                {text}
            </Text>
        </Box>
    );
};

export default Badge;