import React from 'react';
import {Box, Heading, useColorMode} from 'native-base';
import AppBackground from 'components/essential/AppBackground';
import {useAppSelector} from '../../../app/redux/hooks';
import {getAllTournaments, possibleTournamentInfoStatus} from './api/tournamentInfoSlice';
import AppLoading from 'expo-app-loading';
import ErrorScreen from '../../main/helper/ErrorScreen';
import {TournamentInfoComponent} from './components/TournamentInfoComponent';
import {FlatList, useWindowDimensions} from 'react-native';
import {AllTournamentsScreenProps} from '../types';
import {store} from 'app/redux/store';

const AllTournamentsScreen = ({navigation}: AllTournamentsScreenProps) => {

    //get status on fetch
    const tournamentInfoError = useAppSelector(state => state.tournaments.error);
    const tournamentInfoStatus = useAppSelector(state => state.tournaments.status);

    //get all tournaments
    const tournamentInfo = getAllTournaments(store.getState());

    //helper
    const dimensions = useWindowDimensions();
    const {colorMode} = useColorMode();

    //render loading
    if (tournamentInfoStatus === possibleTournamentInfoStatus.loading)
        return <AppLoading/>;

    //render failed
    if (tournamentInfoStatus === possibleTournamentInfoStatus.failed)
        return <ErrorScreen errorMessage={tournamentInfoError}/>;

    //helper
    const renderTournamentInfoBox = ({item, index}: any) => (
        <TournamentInfoComponent tournamentInfo={item} index={index} navigation={navigation} dimensions={dimensions}
                                 colorMode={colorMode}/>
    );

    return (
        <AppBackground p={['4', '10', '16']}>
            <Heading fontSize={['6xl', '7xl', '8xl']} alignSelf={['center', 'flex-start']} mb='12' lineHeight='1em'>
                Tournaments
            </Heading>
            <FlatList
                data={tournamentInfo}
                renderItem={({item, index}) => renderTournamentInfoBox({item, index})}
                keyExtractor={item => item.name}
                ItemSeparatorComponent={SeparatorComponent}
                // @ts-ignore
                style={{overflowY: 'hidden'}}
            />
        </AppBackground>
    );
};

const SeparatorComponent = () => {
    return (
        <Box height='8' bg='transparent'/>
    );
};

export default AllTournamentsScreen;