import {Box, Flex, useColorMode, useTheme, Text} from 'native-base';
import React, {useEffect, useRef} from 'react';
import Animated, {
    useAnimatedGestureHandler,
    useAnimatedStyle,
    useSharedValue,
    withSpring
} from 'react-native-reanimated';
import {
    PanGestureHandler, PanGestureHandlerGestureEvent,
} from 'react-native-gesture-handler';
import {View, StyleSheet} from 'react-native';
import {clamp} from '../../../../../app/helper/clamp';
import {AntDesign} from '@expo/vector-icons';

interface UploadConfirmComponentProps {
    confirmButtonCallback: () => void,
}

export const UploadConfirmComponent = ({confirmButtonCallback}: UploadConfirmComponentProps) => {

    const {colorMode} = useColorMode();
    const {colors} = useTheme();

    const x = useSharedValue(0);
    const maxWidth = useSharedValue(0);
    const pressed = useSharedValue(false);

    const [maxWidthSet, setMaxWidthSet] = React.useState(false);

    //setup for max swipe width
    const parentRef = useRef<View>();
    useEffect(() => {
        parentRef.current?.measure((fx, fy, width) => {
            maxWidth.value = width - 80;
            setMaxWidthSet(true);
        });
    }, [parentRef, maxWidth]);

    const confirmUpload = () => {
        confirmButtonCallback();
    };

    const eventHandler = useAnimatedGestureHandler<PanGestureHandlerGestureEvent>({
        onStart: () => {
            pressed.value = true;
        },
        onActive: (event) => {
            x.value = clamp(event.translationX, 0, maxWidth.value);
        },
        onEnd: () => {
            pressed.value = false;
            x.value = withSpring(0, {damping: 50, stiffness: 90});
            if ((maxWidth.value - x.value) < 30)
                confirmUpload();
        }
    });

    const handleStyle = useAnimatedStyle(() => {
        let bg = colorMode === 'light' ? colors['light']['contrast'] : colors['dark']['contrast'];
        if ((maxWidth.value - x.value) < 30 && maxWidthSet) {
            bg = colorMode === 'light' ? colors['light']['success'] : colors['dark']['success'];
        }
        return {
            backgroundColor: bg,
            transform: [{translateX: x.value}],
        };
    });

    const bgStyle = useAnimatedStyle(() => {
        return {
            backgroundColor: colorMode === 'light' ? colors['light']['bg'] : colors['dark']['bg'],
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: (maxWidth.value - x.value) + 50,
            zIndex: -1,
        };
    });

    return (
        <Box mt={10} rounded='full' position='relative'
             bg={colorMode === 'light' ? 'light.layout' : 'dark.layout'}
             ref={parentRef}>
            <PanGestureHandler onGestureEvent={eventHandler}>
                <Animated.View style={[handleStyle, styles.handle]}>
                    <AntDesign name='doubleright' size={30}
                               color={pressed.value ? colors['primaryPartner'] : colors['primary']}/>
                </Animated.View>
            </PanGestureHandler>
            <Flex position='absolute' top='0' left='0' right='0' bottom='0' justifyContent='center' alignItems='center'
                  zIndex='-2' px='110px'>
                <Text fontSize='md' color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                    swipe to upload
                </Text>
            </Flex>
            <Animated.View style={bgStyle}/>
        </Box>
    );
};

const styles = StyleSheet.create({
    handle: {
        width: '80px',
        height: '80px',
        borderRadius: 999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
});