import {ColorMode} from 'native-base';
import {ScaledSize} from 'react-native';
import {DrawerHeader} from './DrawerHeader';
import React from 'react';
import {DrawerHeaderProps} from '@react-navigation/drawer';

export const DrawerStyle = ({
                                colorMode,
                                colors,
                                isScreenBig
                            }: { colorMode: ColorMode, colors: any, isScreenBig: boolean }): {} => {
    return {
        width: 232,
        position: 'fixed',
        left: 0,
        height: '100%',
        backgroundColor: colorMode === 'light' ? colors['light']['contrastLow'] : colors['dark']['contrastLow'],
        //padding
        paddingTop: '50px',
        paddingBottom: '50px',
        paddingLeft: '15px',
        paddingRight: '20px',
        //shadow
        shadowColor: '#000000',
        shadowOpacity: isScreenBig ? 0.15 : 0,
        shadowRadius: 30,
        //border
        borderTopRightRadius: '40px',
        borderBottomRightRadius: '40px',
        borderRightWidth: 0,
        borderRightColor: colorMode === 'light' ? colors['light']['contrast'] : colors['dark']['contrast'],
    };
};

export const DrawerScreenOptions = ({
                                        colorMode,
                                        colors,
                                        dimensions
                                    }: { colorMode: ColorMode, colors: any, dimensions: ScaledSize }): {} => {

    const isScreenBig = dimensions.width >= 1220;

    return {
        drawerType: isScreenBig ? 'permanent' : 'slide',
        drawerStyle: DrawerStyle({colorMode, colors, isScreenBig}),
        headerShown: !isScreenBig,
        // drawerPosition: 'left',
        overlayColor: 'transparent',
        sceneContainerStyle: isScreenBig ? {marginLeft: '232px'} : '',
        header: ({navigation}: DrawerHeaderProps) => {
            return <DrawerHeader navigation={navigation} colors={colors} colorMode={colorMode}
                                 isScreenBig={isScreenBig}/>;
        },
    };
};