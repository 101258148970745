import React from 'react';
import AppBackground from 'components/essential/AppBackground';
import {Flex, Heading, Text, useColorMode, VStack} from 'native-base';
import {StatisticHelpScreenProps} from '../../types';
import {StatisticValueOptions} from '../../statistics/api/types';

const StatisticHelpScreen = ({navigation, route}: StatisticHelpScreenProps) => {

    const {statistic} = route.params;

    const {colorMode} = useColorMode();

    return (
        <AppBackground p={['4', '10', '16']} py={['8', '10', '16']}>
            <VStack mb='12'>
                <Heading fontSize='5xl' textAlign='left'>
                    {statistic.displayName}
                </Heading>
                <Text textAlign='left'>
                    {statistic.infoText}
                </Text>
            </VStack>
            <Flex bg={colorMode === 'light' ? 'light.layout' : 'dark.layout'} rounded='15px' p={['2', '4', '6']}
                  direction='row'
                  justifyContent='space-evenly' alignItems='flex-start'>
                <Flex w='31%'>
                    <Text>
                        {
                            statistic.value === StatisticValueOptions.percentage ? '80%' : '10'
                        }
                    </Text>
                    <Text fontSize={['xs', 'sm', 'md']}
                          color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                        example for athlete performance
                    </Text>
                </Flex>
                <Flex w='31%'>
                    <Text>
                        {'x ' + statistic.performanceMultiplier}
                    </Text>
                    <Text fontSize={['xs', 'sm', 'md']}
                          color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                        performance multiplier
                    </Text>
                </Flex>
                <Flex w='31%'>
                    <Text>
                        {'= ' + statistic.performanceMultiplier * (statistic.value === StatisticValueOptions.percentage ? 80 : 10)}
                    </Text>
                    <Text fontSize={['xs', 'sm', 'md']}
                          color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                        example for points received
                    </Text>
                </Flex>
            </Flex>
        </AppBackground>
    );
};

export default StatisticHelpScreen;