import React from 'react';
import {DrawerContentComponentProps} from '@react-navigation/drawer';
import {
    Box,
    Flex,
    useColorModeValue,
    Switch,
    useColorMode,
    useTheme,
    Button,
    Link,
    VStack,
} from 'native-base';
import {DrawerList} from './components/DrawerList';
import {MaterialCommunityIcons} from '@expo/vector-icons';
import {getAuth, signOut} from 'firebase/auth';
import VersionIndicator from "../helper/VersionIndicator";
import LogoSmall from "../../../components/branding/Logo-Small";

export const DrawerContent = (props: DrawerContentComponentProps) => {

    //color Mode
    const {colorMode, toggleColorMode} = useColorMode();
    const {colors} = useTheme();

    //sign out logic
    const signOutCallback = () => {
        signOutUser().then();
    };

    const signOutUser = async () => {

        const auth = getAuth();

        return signOut(auth)
            .catch((error) => {
                console.log('error during sign out:');
                console.log(error.message);
                return;
            });
    };

    //TODO check current tournament title for length and put (...) if title is longer than for example 20 characters

    return (
        <Box flex={1}>
            {/*<Avatar size='xl' mb='16' ml='1'/>*/}
            <VStack mb={20} alignItems={'flex-start'} mx={4}>
                <LogoSmall textcolor={colorMode === 'light' ? colors['light']['contrast'] : colors['dark']['contrast']}
                           subtextcolor={colorMode === 'light' ? colors['light']['contrastMid'] : colors['dark']['contrastMid']}/>
                <VersionIndicator fontSize={'2xs'}/>
            </VStack>
            <Box mb='16'>
                {DrawerList({...props})}
            </Box>
            <Box bg={useColorModeValue('light.contrastMid', 'dark.contrastMid')}
                 h='2px' mx='4' rounded='full' mb='5'/>
            <Flex direction='row' justifyContent='space-between' alignItems='center' px='4' mb='4'>
                <MaterialCommunityIcons name='theme-light-dark' size={24}
                                        color={colorMode === 'light' ? colors['light']['contrast'] : colors['dark']['contrast']}/>
                <Switch value={colorMode === 'dark'} onValueChange={toggleColorMode} offTrackColor='light.contrastMid'
                        onTrackColor='dark.layout' offThumbColor='light.contrast' onThumbColor='dark.contrast'/>
            </Flex>
            <Box bg={useColorModeValue('light.contrastMid', 'dark.contrastMid')}
                 h='2px' mx='4' rounded='full' mb='16'/>
            <Button
                bg={colorMode === 'light' ? colors['light']['contrastMid'] : colors['dark']['bg']} rounded='xl'
                _hover={{background: 'primary', _text: {color: 'primaryPartner'}}} _pressed={{background: 'primary'}}
                onPress={signOutCallback} px='4' py='2'
                _text={{
                    fontSize: 'lg',
                    color: (colorMode === 'light' ? colors['light']['contrast'] : colors['dark']['contrast'])
                }}
                alignSelf='center' mb='6'
            >
                Sign out
            </Button>
            <Link
                _text={{
                    fontSize: 'xs',
                    textDecorationLine: 'none',
                    color: colorMode === 'light' ? colors['light']['contrastMid'] : colors['dark']['contrastMid']
                }}
                _hover={{_text: {textDecorationLine: 'underline'}}} alignSelf='center' mb='1'>
                Data Protection
            </Link>
            <Link
                _text={{
                    fontSize: 'xs',
                    textDecorationLine: 'none',
                    color: colorMode === 'light' ? colors['light']['contrastMid'] : colors['dark']['contrastMid']
                }}
                _hover={{_text: {textDecorationLine: 'underline'}}} alignSelf='center'>
                Legal Notice
            </Link>
        </Box>
    );
};