import React, {useEffect} from 'react';
import {createDrawerNavigator, DrawerContentComponentProps,} from '@react-navigation/drawer';
import {DrawerContent} from './drawer/DrawerContent';
import AppBackground from 'components/essential/AppBackground';
import AccountScreen from '../account/AccountScreen';
import {DrawerScreenOptions} from './drawer/DrawerConfig';
import {DrawerIcon} from './drawer/components/DrawerItem';
import HelpScreen from '../help/HelpScreen';
import {useAppDispatch, useAppSelector} from 'app/redux/hooks';
import {getTournamentInfo, possibleTournamentInfoStatus} from '../tournaments/allTournaments/api/tournamentInfoSlice';
import AppLoading from 'expo-app-loading';
import ErrorScreen from './helper/ErrorScreen';
import Dashboard from '../dashboard/Dashboard';
import {MainNavigatorParamList, possibleMainScreens} from './types';
import TournamentNavigator from '../tournaments/TournamentNavigator';
import {useColorMode, useTheme} from 'native-base';
import {useWindowDimensions} from 'react-native';
import {possibleAthleteInfoStatus} from '../tournaments/athletes/api/types';
import {getStatisticInfo} from '../tournaments/statistics/api/statisticsSlice';
import {getAthleteInfo} from '../tournaments/athletes/api/functions/getAthleteInfo';
import {getFocusedRouteNameFromRoute} from '@react-navigation/native';
import {possibleTournamentScreens} from '../tournaments/types';

const Drawer = createDrawerNavigator<MainNavigatorParamList>();

const MainNavigator = () => {

    //check to get tournament data - if no data is received - show disconnected screen
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getTournamentInfo());
        dispatch(getAthleteInfo());
        dispatch(getStatisticInfo());
    }, [dispatch]);

    //helpers
    const {colorMode} = useColorMode();
    const {colors} = useTheme();
    const dimensions = useWindowDimensions();

    //get fetch data
    const tournamentsDataStatus = useAppSelector(state => state.tournaments.status);
    const athleteDataStatus = useAppSelector(state => state.athletes.fetchStatus);
    const tournamentsError = useAppSelector(state => state.tournaments.error);
    const athleteDataError = useAppSelector(state => state.athletes.fetchError);

    //show loading
    if (tournamentsDataStatus === possibleTournamentInfoStatus.loading || athleteDataStatus === possibleAthleteInfoStatus.loading)
        return <AppLoading/>;

    //show failed
    if (tournamentsDataStatus === possibleTournamentInfoStatus.failed || athleteDataStatus === possibleAthleteInfoStatus.failed)
        return <ErrorScreen errorMessage={tournamentsError + '\n' + athleteDataError}/>;

    return (
        <AppBackground safeArea>
            <Drawer.Navigator
                screenOptions={DrawerScreenOptions({colorMode, colors, dimensions})}
                drawerContent={(props: DrawerContentComponentProps) => {
                    return <DrawerContent {...props}/>;
                }}
            >
                <Drawer.Screen name={possibleMainScreens.Dashboard} component={Dashboard} options={{
                    drawerIcon: (props) => <DrawerIcon {...props} icon={possibleMainScreens.Dashboard}/>
                }}/>
                <Drawer.Screen
                    name={possibleMainScreens.Tournaments}
                    component={TournamentNavigator}
                    options={({route}) => ({
                        drawerIcon: (props) => <DrawerIcon {...props}
                                                           icon={possibleMainScreens.Tournaments}/>,
                        headerShown: getHeaderShownForTournament(route)
                    })}
                    listeners={({navigation}) => ({
                        drawerItemPress: (event) => {
                            event.preventDefault();
                            navigation.navigate(possibleMainScreens.Tournaments, {
                                screen: possibleTournamentScreens.AllTournaments
                            });
                        }
                    })}
                />
                <Drawer.Screen name={possibleMainScreens.Account} component={AccountScreen} options={{
                    drawerIcon: (props) => <DrawerIcon {...props} icon={possibleMainScreens.Account}/>
                }}/>
                <Drawer.Screen name={possibleMainScreens.Help} component={HelpScreen} options={{
                    drawerIcon: (props) => <DrawerIcon {...props} icon={possibleMainScreens.Help}/>
                }}/>
            </Drawer.Navigator>
        </AppBackground>
    );
};

const getHeaderShownForTournament = (route: any) => {
    const routeName = getFocusedRouteNameFromRoute(route) ?? possibleTournamentScreens.AllTournaments;

    return !(routeName === possibleTournamentScreens.AthleteSelection || routeName === possibleTournamentScreens.StatisticHelp);
};

export default MainNavigator;