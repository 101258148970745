import {Box, ColorMode, Flex, Text} from 'native-base';
import React from 'react';
import {convertMonth, getEnding} from '../helper/dateHelper';

export const DateBoxSingle = ({
                                  order,
                                  show,
                                  colorMode,
                                  isHoveredOrPressed,
                                  date
                              }: { order: number, show: boolean, colorMode: ColorMode, isHoveredOrPressed: boolean, date: Date }) => {

    if (!show)
        return <></>;

    const color = isHoveredOrPressed ? 'primaryPartner' : colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid';

    return (
        <Flex bg={isHoveredOrPressed ? 'primary' : colorMode === 'light' ? 'light.layoutShade' : 'dark.layoutShade'}
              w='15%' p='4' maxW='100px'
              justifyContent='center'
              style={{
                  shadowColor: '#000',
                  shadowOpacity: 0.05,
                  shadowRadius: 10,
                  shadowOffset: order === 0 ? {width: 6, height: 0} : {width: -6, height: 0},
                  zIndex: 3,
              }}
        >
            <Text color={color} fontSize={'16px'}>
                {convertMonth(date.getMonth())}
            </Text>
            <Flex direction='row' justifyContent='center'>
                <Text color={color} fontSize='32px' lineHeight='1em'>
                    {date.getDate()}
                </Text>
                <Text color={color} fontSize='12px'>
                    {getEnding(date.getDate())}
                </Text>
            </Flex>
            <Text color={color} fontSize='18px'>
                {date.getUTCFullYear()}
            </Text>
        </Flex>
    );
};

export const DateBoxCombined = ({
                                    show,
                                    colorMode,
                                    isHoveredOrPressed,
                                    startDate,
                                    endDate
                                }: { show: boolean, colorMode: ColorMode, isHoveredOrPressed: boolean, startDate: Date, endDate: Date }) => {

    if (!show)
        return <></>;

    const color = isHoveredOrPressed ? 'primaryPartner' : colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid';

    return (
        <Box bg={isHoveredOrPressed ? 'primary' : colorMode === 'light' ? 'light.layoutShade' : 'dark.layoutShade'}
             px='5' pb='3' pt='2'>
            <Flex direction='row' justifyContent='space-between'>
                <Text fontSize='md' color={color}>
                    {convertMonth(startDate.getMonth()) + ' ' + startDate.getDate() + getEnding(startDate.getDate()) + ' ' + startDate.getUTCFullYear()}
                </Text>
                <Text fontSize='md' color={color}>
                    -
                </Text>
                <Text fontSize='md' color={color}>
                    {convertMonth(endDate.getMonth()) + ' ' + endDate.getDate() + getEnding(endDate.getDate()) + ' ' + endDate.getUTCFullYear()}
                </Text>
            </Flex>
        </Box>
    );
};