import {Box, ColorMode, Flex, Pressable, Text} from 'native-base';
import {AntDesign} from '@expo/vector-icons';
import React from 'react';
import {AthleteDataType} from '../../../athletes/api/types';
import {StatisticSelectionMatchType} from '../types';
import {Feather} from '@expo/vector-icons';

interface AthleteSelectionMatchComponentProps {
    item: StatisticSelectionMatchType,
    playerSelectedCallback: (player: AthleteDataType) => void,
    selectedAthleteId: string | undefined,
    colorMode: ColorMode,
    colors: any,
    isEntry: boolean,
}

export const AthleteSelectionMatchComponent = ({
                                                   item,
                                                   playerSelectedCallback,
                                                   selectedAthleteId,
                                                   colorMode,
                                                   colors,
                                                   isEntry,
                                               }: AthleteSelectionMatchComponentProps) => {

    return (
        // <Flex maxW='512px' width='100%' mx='auto' px='2' direction='row'>
        <Flex direction='row'>
            <Flex flexGrow='1'>
                <AthleteSelectionPlayerComponent athlete={item.athleteOne}
                                                 athleteSelectedCallback={playerSelectedCallback}
                                                 selectedAthleteId={selectedAthleteId} colorMode={colorMode}
                                                 colors={colors}/>
                <Box h='2'/>
                <AthleteSelectionPlayerComponent athlete={item.athleteTwo}
                                                 athleteSelectedCallback={playerSelectedCallback}
                                                 selectedAthleteId={selectedAthleteId} colorMode={colorMode}
                                                 colors={colors}/>
            </Flex>
            {
                !isEntry ?
                    <Flex direction='row' alignItems='stretch'>
                        <Box borderColor={colorMode === 'light' ? 'light.contrastLow' : 'dark.contrastLow'} h='60%'
                             borderWidth='10px' w='20px' my='auto' borderLeftWidth='0'/>
                        <Box bg={{
                            linearGradient: {
                                colors: [colorMode === 'light' ? colors['light']['contrastLow'] : colors['dark']['contrastLow'], colors['light']['layoutShade']],
                                start: [0, 0],
                                end: [1, 0]
                            },
                        }} h='10px' w='40px' my='auto'/>
                    </Flex>
                    : <></>
            }
        </Flex>
    );
};

interface AthleteSelectionPlayerComponentProps {
    athlete: AthleteDataType | null,
    athleteSelectedCallback: (athlete: AthleteDataType) => void,
    selectedAthleteId: string | undefined,
    colorMode: ColorMode,
    colors: any,
}

const AthleteSelectionPlayerComponent = ({
                                             athlete,
                                             athleteSelectedCallback,
                                             selectedAthleteId,
                                             colorMode,
                                             colors
                                         }: AthleteSelectionPlayerComponentProps) => {

    if (athlete === null) {
        return <></>;
    }

    const isAthleteSelected = selectedAthleteId !== undefined && selectedAthleteId === athlete.id;

    return (
        <Pressable onPress={() => athleteSelectedCallback(athlete)} display='flex' flexDir='row'
                   bg={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'} p='4' rounded='15px'
                   _pressed={{bg: 'primary'}} _hover={{bg: 'primary'}}
        >
            {
                ({isPressed, isHovered}) => (
                    <>
                        <Flex h='full' style={{aspectRatio: 1}} rounded='5px' mr='4' justifyContent='center'
                              alignItems='center'
                              bg={colorMode === 'light' ? (isAthleteSelected ? 'light.success' : 'light.bg') : (isAthleteSelected ? 'dark.success' : 'dark.bg')}>
                            {
                                isAthleteSelected ?
                                    <Feather name='check-circle' size={24}
                                             color={colors['primaryPartner']}/>
                                    :
                                    <></>
                            }
                        </Flex>
                        <Text
                            color={isPressed || isHovered ? 'primaryPartner' : colorMode === 'light' ? 'light.contrast' : 'dark.contrast'}>
                            {athlete.name}
                        </Text>
                    </>
                )
            }

        </Pressable>
    );
};