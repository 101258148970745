import React, {useEffect, useRef} from 'react';
import {Box, ColorMode, Flex, Heading, HStack, Pressable, Text, useBreakpointValue} from 'native-base';
import {JoinedPlayerType} from '../../api/types';
import {possibleRoundStates, RoundDataType} from '../../../tournament/api/types';
import {AntDesign, Ionicons} from '@expo/vector-icons';
import {convertRoundName} from '../../../../../app/helper/nameConversionHelper';
import {View} from 'react-native';

interface PlayerListItemProps {
    playerInfo: JoinedPlayerType;
    currentUserId: string;
    index: number;
    colorMode: ColorMode;
    colors: any,
    selectedRound: RoundDataType | null;
    onPressSelection: (playerInfo: JoinedPlayerType) => void;
    onPressPerformance: (playerInfo: JoinedPlayerType) => void;
    hasPlayerMadeSelection?: boolean;
    setPlayerItemPosY: (posY: number) => void;
}

const PlayerListItem = ({
                            playerInfo,
                            currentUserId,
                            index,
                            colorMode,
                            colors,
                            selectedRound,
                            onPressSelection,
                            onPressPerformance,
                            hasPlayerMadeSelection,
                            setPlayerItemPosY
                        }: PlayerListItemProps) => {

    //helper
    const isPlayerUser = playerInfo.userUid === currentUserId;
    const isRoundOpen = selectedRound?.status === possibleRoundStates.open ?? false;
    const isRoundComplete = selectedRound?.status === possibleRoundStates.completed ?? false;

    //color setup
    const bg = isPlayerUser ? (colorMode === 'light' ? 'light.contrast' : 'dark.contrast') : 'transparent';
    const contentColor = isPlayerUser ? (colorMode === 'light' ? 'light.contrastPartner' : 'dark.contrastPartner') : colorMode === 'light' ? ('light.contrast') : ('dark.contrast');
    const midContrastColor = colorMode === 'light' ? ('light.contrastMid') : ('dark.contrastMid');
    const lowContrastContent = colorMode === 'light' ? (isPlayerUser ? 'light.contrastLow' : 'light.bg') : (isPlayerUser ? 'dark.contrastLow' : 'dark.contrastLow');

    const isScreenBig = useBreakpointValue({base: false, md: true});

    //reference setup - used for the scroll to player feature
    const elementRef = useRef<View>();
    useEffect(() => {
        elementRef.current?.measure((fx, fy, width, height, px, py) => {
            //if the current rendered item is the player item - set the playerItemPosY in leagueScreen to scrollTo
            if (isPlayerUser)
                setPlayerItemPosY(py);
        });
    }, [elementRef]);

    const renderItem = (isHoveredOrPressed: boolean) => (
        <Flex
            direction={isScreenBig ? 'row' : 'column'}
            alignItems='stretch' justifyContent='space-between'
            rounded='15px' flexGrow='1' ref={elementRef}
            bg={isHoveredOrPressed ? 'primary' : bg}
            p={['5', '6', '7']} my='-2px'>
            <HStack alignItems='stretch' mb={isScreenBig ? '0' : '3'} flexShrink='1'>
                <HStack alignItems='center' space='3'>
                    <Heading fontSize={['22px', '26px', '32px']} minW='6' lineHeight='1em'
                             mb={isScreenBig ? '-5px' : '-3px'}
                             color={isHoveredOrPressed ? 'primaryPartner' : lowContrastContent}>
                        {index + 1}
                    </Heading>
                    {
                        isScreenBig ?
                            <Box h='80%' w='2px' rounded='full'
                                 bg={isHoveredOrPressed ? 'primaryPartner' : lowContrastContent}/>
                            : <></>
                    }
                </HStack>
                <Flex direction={'row'} px='4' flexShrink='1'>
                    <Text color={isHoveredOrPressed ? 'primaryPartner' : contentColor} textAlign='left'
                          isTruncated={true} mr={'2'}>
                        {playerInfo.playerName}
                    </Text>
                    {
                        isRoundOpen && isPlayerUser ?
                            (
                                hasPlayerMadeSelection ?
                                    <Flex direction='row' alignItems='center'>
                                        <AntDesign name='checkcircle' size={20}
                                                   color={colorMode === 'light' ? colors['light']['success'] : colors['dark']['success']}/>
                                        <Text
                                            color={isHoveredOrPressed ? 'primaryPartner' : colorMode === 'light' ? 'light.success' : 'dark.success'}
                                            textAlign='left' ml='2' fontSize='md'>
                                            ready
                                        </Text>
                                    </Flex>
                                    :
                                    <Flex direction='row' alignItems='center'>
                                        <Ionicons name='information-circle' size={26}
                                                  color={colorMode === 'light' ? colors['light']['warning'] : colors['dark']['warning']}/>
                                        <Text
                                            color={isHoveredOrPressed ? 'primaryPartner' : colorMode === 'light' ? 'light.warning' : 'dark.warning'}
                                            textAlign='left' ml='2' fontSize='md'>
                                            action needed
                                        </Text>
                                    </Flex>
                            )
                            : <></>
                    }
                </Flex>
            </HStack>
            <HStack alignItems='stretch' space='3'
                    justifyContent={isScreenBig ? 'flex-start' : (selectedRound === null || selectedRound.status === possibleRoundStates.upcoming ? 'flex-end' : 'space-between')}>
                {
                    selectedRound !== null && selectedRound.status !== possibleRoundStates.upcoming ?
                        <HStack alignItems='center' space='3' w='112px'>
                            <Box h='80%' w='2px' rounded='full'
                                 bg={isHoveredOrPressed ? 'primaryPartner' : lowContrastContent}/>
                            <Flex direction={isScreenBig ? 'row' : 'column'} alignItems='flex-start'>
                                {
                                    isScreenBig ? <></> :
                                        <Text fontSize='xs' textAlign='left' mb={2}
                                              color={isHoveredOrPressed ? 'primaryPartner' : colorMode === 'light' ? 'light.contrastLow' : 'dark.contrastLow'}>
                                            Points for {convertRoundName(selectedRound?.id ?? '')}
                                        </Text>
                                }
                                <Text lineHeight='1em' mb={isScreenBig ? '-5px' : '0'}
                                      color={isHoveredOrPressed ? 'primaryPartner' : midContrastColor}>
                                    {
                                        isRoundComplete ?
                                            playerInfo.performance.find(r => r.roundId === selectedRound?.id)?.SCORE ?? '-' : '-'
                                    }
                                </Text>
                            </Flex>
                        </HStack>
                        : <></>
                }
                <HStack alignItems='center' space='3' w='112px'>
                    <Box h='80%' w='2px' rounded='full'
                         bg={isHoveredOrPressed ? 'primaryPartner' : lowContrastContent}/>
                    <Flex direction={isScreenBig ? 'row' : 'column'} alignItems='flex-start'>
                        {
                            isScreenBig ? <></> :
                                <Text fontSize='xs' textAlign='left' mb={2}
                                      color={isHoveredOrPressed ? 'primaryPartner' : colorMode === 'light' ? 'light.contrastLow' : 'dark.contrastLow'}>
                                    Total Points
                                </Text>
                        }
                        <Text lineHeight='1em' mb={isScreenBig ? '-5px' : '0'} isTruncated={true}
                              color={isHoveredOrPressed ? 'primaryPartner' : contentColor}>
                            {playerInfo.currentScore}
                        </Text>
                    </Flex>
                </HStack>
            </HStack>
        </Flex>
    );

    return (
        isRoundComplete ?
            <Pressable onPress={() => onPressPerformance(playerInfo)}>
                {
                    ({isPressed, isHovered}) => {
                        return renderItem(isPressed || isHovered);
                    }
                }
            </Pressable> :
            isPlayerUser && isRoundOpen ?
                <Pressable onPress={() => onPressSelection(playerInfo)}>
                    {
                        ({isPressed, isHovered}) => {
                            return renderItem(isPressed || isHovered);
                        }
                    }
                </Pressable>
                : renderItem(false)
    );
};

export default PlayerListItem;