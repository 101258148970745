import {createAsyncThunk, createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import {collection, getDocs, query, orderBy} from 'firebase/firestore';
import {fb_firestore} from '../../../../App';
import {RootState} from 'app/redux/store';
import {possibleRoundIds} from '../../tournament/api/types';

export enum possibleTournamentInfoStatus {
    idle = 'idle',
    loading = 'loading',
    succeeded = 'succeeded',
    failed = 'failed',
}

export type TournamentInfoDataType = {
    id: string,
    name: string,
    currentRound: possibleRoundIds
    location: string,
    atpPoints: string,
    startDate: string,
    endDate: string,
}

const tournamentInfoAdapter = createEntityAdapter<TournamentInfoDataType>({
    selectId: (tournamentInfo) => tournamentInfo.id,
});

const initialState = tournamentInfoAdapter.getInitialState({
    status: possibleTournamentInfoStatus.idle,
    error: ''
});

//tournamentId Thunk
export const getTournamentInfo = createAsyncThunk(
    'tournaments/getInfo',
    async (empty, {rejectWithValue}) => {
        try {
            //query for all tournaments that are active or upcoming
            const q = query(collection(fb_firestore, 'tournaments'), orderBy('startDate'));
            const querySnapshot = await getDocs(q);

            //pass data into array format and convert the dates to strings
            const tournaments: TournamentInfoDataType[] = [];
            querySnapshot.forEach((doc) => {
                const tournament = doc.data();
                tournaments.push({
                    id: doc.id,
                    name: tournament.name,
                    currentRound: tournament.currentRound,
                    location: tournament.location,
                    atpPoints: tournament.atpPoints,
                    startDate: tournament.startDate.toDate().toJSON(),
                    endDate: tournament.endDate.toDate().toJSON(),
                });
            });

            //
            // console.log('LOADED EVENTS:');
            // console.table(tournaments);
            return tournaments;
        } catch (e) {
            console.log('error in tournament Id get');
            console.log(e);
            return rejectWithValue(e);
        }
    }
);

const tournamentInfoSlice = createSlice({
    name: 'tournaments',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            //pending
            .addCase(getTournamentInfo.pending, (state) => {
                state.status = possibleTournamentInfoStatus.loading;
            })
            //succeeded
            .addCase(getTournamentInfo.fulfilled, (state, action) => {
                state.status = possibleTournamentInfoStatus.succeeded;
                tournamentInfoAdapter.upsertMany(state, action.payload);
            })
            //failed
            .addCase(getTournamentInfo.rejected, (state, action) => {
                state.status = possibleTournamentInfoStatus.failed;
                state.error = String(action.payload);
            });
    }
});

export const {
    selectAll: getAllTournaments,
    selectById: getTournamentById,
    selectIds: selectTournamentIds,
} = tournamentInfoAdapter.getSelectors<RootState>(state => state.tournaments);

export default tournamentInfoSlice.reducer;