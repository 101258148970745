import React from 'react';
import {TournamentInfoDataType} from '../../tournaments/allTournaments/api/tournamentInfoSlice';
import {AspectRatio, Box, Button, ColorMode, Flex, Heading, Pressable, Text, VStack} from 'native-base';
import {Image} from 'react-native';

interface FeaturedTournamentCardProps {
    tournamentInfo: TournamentInfoDataType | undefined,
    isScreenBig: boolean,
    colorMode: ColorMode,
    startDate: Date | undefined,
    endDate: Date | undefined,
    viewTournamentCallback: () => void,
    setTrophyModalOpen: (value: boolean) => void
}

const FeaturedTournamentCard = ({
                                    tournamentInfo,
                                    isScreenBig,
                                    colorMode,
                                    startDate,
                                    endDate,
                                    viewTournamentCallback,
                                    setTrophyModalOpen
                                }: FeaturedTournamentCardProps) => {

    if (tournamentInfo === undefined)
        return <></>;

    return (
        <Flex direction={isScreenBig ? 'row' : 'column'} flexShrink={1} p={[6, 8, 10]}
              rounded={['15px', '25px', '35px']} justifyContent={'space-between'}
              bg={colorMode === 'light' ? 'light.layout' : 'dark.layout'}>
            <VStack flexShrink={1} alignItems={'flex-start'} mr={isScreenBig ? 6 : 0}
                    mb={isScreenBig ? 0 : 6}>
                <Heading textAlign={'left'} fontSize={['4xl', '5xl', '6xl']} lineHeight={'1em'}
                         color={colorMode === 'light' ? 'light.contrast' : 'dark.contrast'}>
                    {tournamentInfo.name}
                </Heading>
                <Flex direction={isScreenBig ? 'row' : 'column'} flexShrink={1} mb={2}>
                    <Text fontSize={['sm', 'md', 'lg']} textAlign={'left'}
                          color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                        {tournamentInfo.location}
                    </Text>
                    {
                        isScreenBig ?
                            <Box h={'full'} w={'2px'} rounded={'full'} mx={3}
                                 bg={colorMode === 'light' ? 'light.contrastLow' : 'dark.contrastLow'}/>
                            : <></>
                    }
                    <Text fontSize={['sm', 'md', 'lg']} textAlign={'left'}
                          color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                        {startDate?.toLocaleDateString()} - {endDate?.toLocaleDateString()}
                    </Text>
                </Flex>
                <Button _text={{fontSize: ['sm', 'md', 'lg']}} py={1} px={10} minW={0} mt={'auto'}
                        onPress={viewTournamentCallback}>
                    View
                </Button>
            </VStack>
            <AspectRatio flex={'1 1 256px'} maxW={'256px'} alignSelf={'center'} ratio={1}>
                <Pressable w={'100%'} h={'100%'} onPress={() => setTrophyModalOpen(true)}>
                    {
                        ({isPressed, isHovered}) => (
                            <Image source={require('assets/trophy/geneva-digital-trophy_cut.png')}
                                   style={{
                                       height: '100%',
                                       width: '100%',
                                       transform: [{scale: isPressed || isHovered ? 1.05 : 1}],
                                       resizeMode: 'contain'
                                   }}/>
                        )
                    }
                </Pressable>
            </AspectRatio>
        </Flex>
    );
};

export default FeaturedTournamentCard;