import React from 'react';
import {Box, Text, Pressable, useColorMode, Flex, HStack, useTheme, ScrollView} from "native-base";
import {AntDesign} from "@expo/vector-icons";

interface MultiplierHelpModalProps {
    closeModal: () => void
}

const MultiplierHelpModal = ({closeModal}: MultiplierHelpModalProps) => {

    const {colorMode} = useColorMode();
    const {colors} = useTheme();

    return (
        <Flex position={'absolute'} top={0} bottom={0} left={0} right={0} zIndex={10}
              justifyContent={'center'} alignItems={'center'} p={10}>
            <Pressable bg={colorMode === 'light' ? 'light.contrast' : 'dark.contrast'} position={'absolute'} top={0}
                       bottom={0} left={0} right={0} opacity={0.7} onPress={closeModal}/>
            <Box bg={colorMode === 'light' ? 'light.layout' : 'dark.layout'} rounded={'2xl'} flexShrink={1}>
                <ScrollView contentContainerStyle={{padding: '24px'}}>
                    <HStack justifyContent={'space-between'}>
                        <Text mr={4} fontSize={['2xl', '3xl', '4xl']} textAlign={'left'} mb={2}>
                            Performance Multiplier
                        </Text>
                        <Pressable onPress={closeModal}>
                            {
                                ({isPressed, isHovered}) => (
                                    <AntDesign name="closecircle" size={28}
                                               color={isPressed || isHovered ? (colorMode === 'light' ? colors['light']['error'] : colors['dark']['error']) : colors['primary']}/>
                                )
                            }
                        </Pressable>
                    </HStack>
                    <Text textAlign={'left'} fontSize={['lg', 'xl', '2xl']}
                          color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                        The performance multiplier is used to weight the individual statistics in the calculation.
                        {'\n'}
                        A more impactful statistic will have a higher multiplier. The multiplier is bound to one
                        statistic and is shared across all players and leagues.
                    </Text>
                </ScrollView>
            </Box>
        </Flex>
    );
};

export default MultiplierHelpModal;