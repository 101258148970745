import React from 'react';
import AppBackground from "../../components/essential/AppBackground";
import {Heading, Link, Text, VStack} from "native-base";
import {InformationScreenProps, possiblePublicScreens} from "../authentication/types";
import {Linking} from "react-native";

const InformationScreen = ({navigation}: InformationScreenProps) => {

    const handleBackCallback = () => {
        navigation.navigate(possiblePublicScreens.Welcome);
    }

    const handleWebsiteLink = () => {
        Linking.openURL('https://gonetgenevaopen.com/').then();
    }

    return (
        <AppBackground p={['4', '6', '8']}>
            <VStack alignItems={'center'} py={20}>
                <VStack alignItems={'flex-start'} maxW={'512px'}>
                    <Link onPress={handleBackCallback} _text={{fontSize: 'xs'}} mb={6}>
                        go back
                    </Link>
                    <Heading textAlign={'left'}>
                        Information
                    </Heading>
                    <Text mb={4} textAlign={'left'}>
                        Digital tennis tour is a project for the Gonet Geneva Open 2022 tennis tournament. {'\n'}
                        More information about the tournament can be found on the official website. {'\n'}
                    </Text>
                    <Link onPress={handleWebsiteLink} mb={4}>
                        Gonet Geneva Open 2022
                    </Link>
                    <Text textAlign={'left'}>
                        More information about the digital tennis tour will be added soon.
                    </Text>
                </VStack>
            </VStack>
        </AppBackground>
    );
};

export default InformationScreen;