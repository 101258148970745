import React from 'react';
import AppBackground from "../../components/essential/AppBackground";
import {Box, Button, Flex, Heading, HStack, Link, Text, useColorMode, useTheme, VStack} from "native-base";
import {useWindowDimensions} from "react-native";
import ColorModeSwitchButton from "../theme/components/ColorModeSwitchButton";
import VersionIndicator from "../main/helper/VersionIndicator";
import {possibleAuthComponents, possiblePublicScreens, WelcomeScreenProps} from "../authentication/types";
import WelcomeLogo from "./Components/WelcomeLogo";

const WelcomeScreen = ({navigation}: WelcomeScreenProps) => {

    const dimensions = useWindowDimensions();
    const isScreenBig = dimensions.width >= 1024;

    const {colorMode} = useColorMode();
    const {colors} = useTheme();

    const handleAuthCallback = (state: possibleAuthComponents) => {
        navigation.navigate(possiblePublicScreens.Authentication, {initialAuthState: state});
    }

    const handleMoreInformationCallback = () => {
        navigation.navigate(possiblePublicScreens.Information);
    }

    return (
        <AppBackground p={['4', '14', '24']}>
            <Flex direction={isScreenBig ? 'row' : 'column'}
                  justifyContent={isScreenBig ? 'center' : 'flex-start'} alignItems={'center'}
                  flexGrow={1}>
                <WelcomeLogo
                    maxW={'512px'}
                    mt={['32', '16', '0']}
                    textcolor={colorMode === 'light' ? colors['light']['title'] : colors['dark']['title']}
                    subtextcolor={colorMode === 'light' ? colors['light']['contrast'] : colors['dark']['contrast']}
                />
                <Box w={'5%'} h={'15%'}/>
                <HStack alignItems={'stretch'}>
                    <Box w={'4px'} rounded={'full'} mr={[4, 6, 10]}
                         bg={colorMode === 'light' ? 'light.contrastLow' : 'dark.contrastLow'}/>
                    <VStack flexShrink={1}>
                        <Heading textAlign={'left'} lineHeight={'1em'}>
                            Welcome
                        </Heading>
                        <Text textAlign={'left'} maxW={'512px'} mb={1}>
                            Play alongside the Gonet Geneva Open 2022 and create your own dream tennis star.
                        </Text>
                        <Link _text={{
                            color: colorMode === 'light' ? 'light.contrast' : 'dark.contrast',
                            fontSize: 'xs'
                        }} mb={8} onPress={handleMoreInformationCallback} opacity={'50%'} alignSelf={'flex-start'}>
                            More information
                        </Link>
                        <HStack space={4}>
                            <Button variant='attention'
                                    onPress={() => handleAuthCallback(possibleAuthComponents.register)}>
                                Register
                            </Button>
                            <Button onPress={() => handleAuthCallback(possibleAuthComponents.login)}>
                                Login
                            </Button>
                        </HStack>
                    </VStack>
                </HStack>
            </Flex>
            <ColorModeSwitchButton position={'absolute'} right={5} top={5} _ios={{top: 16}}/>
            <VersionIndicator position={'absolute'} right={5} bottom={5}/>
        </AppBackground>
    );
};

export default WelcomeScreen;