import {HStack, Text} from 'native-base';
import {MaterialCommunityIcons} from '@expo/vector-icons';
import React from 'react';

export const InputFieldErrorMessage = ({
                                           colorMode,
                                           colors,
                                           message,
                                           isSuccess
                                       }: { colorMode: string, colors: any, message: string, isSuccess: boolean }) => {

    const getColor = (): string => {
        if (isSuccess)
            return colorMode === 'light' ? colors['light']['success'] : colors['dark']['success'];

        return colorMode === 'light' ? colors['light']['error'] : colors['dark']['error'];
    };

    return (
        <HStack mt={2} space={2}>
            <MaterialCommunityIcons name='information-outline' size={16} color={getColor()}/>
            <Text fontSize={['sm', 'md', 'lg']} textAlign={'left'}
                  color={getColor()}>
                {message}
            </Text>
        </HStack>
    );
};

export const validateEmail = (email: string) => {
    return email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};