import React from 'react';
import {HStack, Pressable, Text, useColorMode, useTheme} from 'native-base';
import {Entypo, MaterialCommunityIcons, MaterialIcons, FontAwesome5} from '@expo/vector-icons';
import {possibleMainScreens} from '../../types';

type DrawerItemProps = {
    isFocused: boolean,
    onPress: any,
    label: string,
    icon: any,
}

export const DrawerItem = (props: DrawerItemProps): React.ReactElement => {

    //props
    const {isFocused, onPress, label, icon} = props;

    //colors
    const {colorMode} = useColorMode();
    const {colors} = useTheme();
    let contrastColor = colorMode === 'light' ? colors['light']['contrast'] : colors['dark']['contrast'];

    //icon comes via the props from the screen options in the navigator
    //icon is a function returning the drawer icon component
    const iconNode = (iconColor: string) => {
        return (
            icon ?
                icon({focused: isFocused, color: iconColor})
                :
                <DrawerIcon color={iconColor} focused={isFocused}/>
        );
    };

    return (
        <Pressable
            bg={isFocused ? (colorMode === 'light' ? colors['light']['contrastMid'] : colors['dark']['bg']) : ''}
            _hover={{background: 'primary'}} _pressed={{background: 'primary'}} onPress={onPress} px='4'
            py='2' rounded='xl' my='2px'>
            {
                ({isHovered, isPressed}) => (
                    <HStack space={'4'} alignItems='center'>
                        {iconNode(isHovered || isPressed ? colors['primaryPartner'] : contrastColor)}
                        <Text fontSize='lg' color={isHovered || isPressed ? colors['primaryPartner'] : contrastColor}>
                            {label}
                        </Text>
                    </HStack>
                )
            }

        </Pressable>
    );
};

type DrawerIconType = {
    focused: boolean,
    color: string,
    icon?: possibleMainScreens,
}

export const DrawerIcon = (props: DrawerIconType) => {

    const {color, icon} = props;

    const size = 20;

    switch (icon) {
        case possibleMainScreens.Dashboard:
            return <MaterialCommunityIcons name='tennis-ball' size={size} color={color}/>;
        case possibleMainScreens.Tournaments:
            return <FontAwesome5 name='medal' size={size} color={color}/>;
        case possibleMainScreens.Account:
            return <MaterialIcons name='account-circle' size={size * 1.1} color={color}/>;
        case possibleMainScreens.Help:
            return <Entypo name='help-with-circle' size={size} color={color}/>;
        default:
            return <MaterialIcons name='web-asset' size={size} color={color}/>;
    }
};