import {Box, ColorMode, Flex, Pressable, Text, VStack} from 'native-base';
import React from 'react';
import {Image, ImageURISource, ScaledSize} from 'react-native';
import {DateBoxCombined, DateBoxSingle} from './DateComponents';
import Badge from 'components/basic/Badge';
import {TournamentInfoDataType} from '../api/tournamentInfoSlice';
import {AllTournamentsNavigationProp, possibleTournamentScreens} from '../../types';
import {convertRoundName} from '../../../../app/helper/nameConversionHelper';
import {possibleRoundIds} from "../../tournament/api/types";

type TournamentInfoComponentProps = {
    tournamentInfo: TournamentInfoDataType,
    index: number,
    navigation: AllTournamentsNavigationProp,
    dimensions: ScaledSize,
    colorMode: ColorMode
}

export const TournamentInfoComponent = ({
                                            tournamentInfo,
                                            navigation,
                                            dimensions,
                                            colorMode
                                        }: TournamentInfoComponentProps) => {

    //helper
    const isScreenBig = dimensions.width >= 768;

    //dates
    const startDate = new Date(tournamentInfo.startDate);
    const endDate = new Date(tournamentInfo.endDate);

    const tournamentButtonPressed = () => {
        // console.log(`open tournament: ${tournamentInfo.name}`);
        navigation.push(possibleTournamentScreens.Tournament, {tournamentId: tournamentInfo.id});
    };

    const getImage = (atpPoints: string): ImageURISource => {
        switch (atpPoints) {
            case '250':
                return require('assets/atp-points/atp-250.png');
            case '500':
                return require('assets/atp-points/atp-500.png');
            case '1000':
                return require('assets/atp-points/atp-1000.png');
            default:
                return require('assets/atp-points/atp-backup.png');
        }
    };

    //TODO check length of texts (tournament title, location, etc.) and restrict to e.g. 20 chars

    return (
        <Pressable display='flex' flexDir={isScreenBig ? 'row' : 'column'} rounded='30px' overflow='hidden'
                   onPress={tournamentButtonPressed} maxW={'1280px'}>
            {
                ({isHovered, isPressed}) => (
                    <>
                        <DateBoxSingle order={0} show={isScreenBig} colorMode={colorMode} date={startDate}
                                       isHoveredOrPressed={isHovered || isPressed}/>
                        {/*<Flex w={isScreenBig ? '70%' : 'full'}>*/}
                        <Flex flexGrow={1} w={isScreenBig ? '70%' : 'full'}>
                            <Flex
                                bg={isHovered || isPressed ? 'primary' : colorMode === 'light' ? 'light.layout' : 'dark.layout'}
                                h='full' p='5' direction='row' justifyContent='space-between'>
                                <VStack flexShrink='1' justifyContent='space-between' pr='4' alignItems='flex-start'>
                                    <Text textAlign='left' fontSize={['2xl', '3xl', '4xl']} lineHeight={'1em'} mb='2'
                                          color={
                                              isHovered || isPressed ? 'primaryPartner' :
                                                  colorMode === 'light' ? 'light.contrast' : 'dark.contrast'
                                          }
                                    >
                                        {tournamentInfo.name}
                                    </Text>
                                    <Flex direction={isScreenBig ? 'row' : 'column-reverse'}
                                          justifyContent='space-between'
                                          alignItems={isScreenBig ? 'center' : 'flex-start'}>
                                        <Badge colorMode={colorMode}
                                               text={convertRoundName(tournamentInfo.currentRound)}
                                               mr={isScreenBig ? '2' : 0} mt={isScreenBig ? '0' : '2'}
                                               variant={isHovered || isPressed ? 'hovered' :
                                                   (tournamentInfo.currentRound === possibleRoundIds.upcoming ? 'default' :
                                                       (tournamentInfo.currentRound === possibleRoundIds.completed ? 'success' : 'highlight'))
                                               }
                                        />
                                        <Text fontSize={['xs', 'sm', 'md']} textAlign='left'
                                              color={
                                                  isHovered || isPressed ? 'primaryPartner' :
                                                      colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'
                                              }
                                        >
                                            {tournamentInfo.location}
                                        </Text>
                                    </Flex>
                                </VStack>
                                <Box flexGrow='1' minW='126px' maxH='75px' alignItems='flex-end'>
                                    <Image source={getImage(tournamentInfo.atpPoints)}
                                           style={{
                                               resizeMode: 'cover',
                                               height: '100%',
                                               maxWidth: '100%',
                                               aspectRatio: 1.68
                                           }}/>
                                </Box>
                            </Flex>
                        </Flex>
                        <DateBoxSingle order={1} show={isScreenBig} colorMode={colorMode} date={endDate}
                                       isHoveredOrPressed={isHovered || isPressed}/>
                        <DateBoxCombined show={!isScreenBig} colorMode={colorMode} startDate={startDate}
                                         endDate={endDate} isHoveredOrPressed={isHovered || isPressed}/>
                    </>
                )
            }
        </Pressable>
    );
};