import {DrawerContentComponentProps} from '@react-navigation/drawer';
import {CommonActions, DrawerActions} from '@react-navigation/native';
import React from 'react';
import {DrawerItem} from './DrawerItem';

export const DrawerList = ({state, navigation, descriptors}: DrawerContentComponentProps) => {

    return state.routes.map((route, i) => {
        const focused = i === state.index;

        const {drawerIcon} = descriptors[route.key].options;

        const onPress = () => {
            const event = navigation.emit({
                type: 'drawerItemPress',
                target: route.key,
                canPreventDefault: true,
            });

            if (!event.defaultPrevented) {
                navigation.dispatch({
                    ...(focused ? DrawerActions.closeDrawer() : CommonActions.navigate({
                        name: route.name,
                        merge: true
                    })),
                    target: state.key,
                });
            }
        };

        return (
            <DrawerItem
                key={route.key}
                isFocused={focused}
                onPress={onPress}
                label={route.name}
                icon={drawerIcon}
            />
        );
    });
};
