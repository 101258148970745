import React, {useEffect, useRef, useState} from 'react';
import AppBackground from '../../../../components/essential/AppBackground';
import {possibleTournamentScreens, VPlayerPerformanceScreenProps} from '../../types';
import {FlatList, useWindowDimensions} from 'react-native';
import {Center, Spinner, Text, useBreakpointValue, useColorMode, useTheme} from 'native-base';
import {useAppDispatch, useAppSelector} from '../../../../app/redux/hooks';
import {getLeagueById} from '../api/leagueSlice';
import {getTournamentById} from '../../allTournaments/api/tournamentInfoSlice';
import {store} from '../../../../app/redux/store';
import {getTournamentDataById} from '../../tournament/api/tournamentDataSlice';
import ListInfoHeader from './components/ListInfoHeader';
import {JoinedPlayerType, PlayerSelectionItemType, possibleLeagueApiStatus} from '../api/types';
import StatisticPerformanceComponent from './components/StatisticPerformanceComponent';
import {PerformanceInfoType} from './types';
import {getAllStatistics} from '../../statistics/api/statisticsSlice';
import {getAllAthletes} from '../../athletes/api/athleteSlice';
import {StatisticType} from '../../statistics/api/types';
import {MatchAthleteDataType} from '../../tournament/api/types';
import ListInfoFooter from './components/ListInfoFooter';
import MultiplierHelpModal from './components/MultiplierHelpModal';
import {getPlayerSelectionForRound} from '../api/functions/getPlayerSelectionForRound';
import AutoSelectHelpModal from './components/AutoSelectHelpModal';
import ReplacementHelpModal from './components/ReplacementHelpModal';
import SubtractHelpModal from './components/SubtractHelpModal';

type PerformanceSelectionItemType = {
    selection: PlayerSelectionItemType;
    autoSelected: boolean;
    replaced: boolean;
    originalSelectionAthleteId?: string;
}

const VPlayerPerformanceScreen = ({route, navigation}: VPlayerPerformanceScreenProps) => {

    const {playerUid, leagueId, tournamentId, selectedRound} = route.params;

    //helper
    const {colorMode} = useColorMode();
    const {colors} = useTheme();
    const dispatch = useAppDispatch();
    const isScreenBig = useWindowDimensions().width > 768;

    //data
    const [performanceDisplayList, setPerformanceDisplayList] = useState<PerformanceInfoType[] | null>(null);
    const [performanceLoading, setPerformanceLoading] = useState(true);

    //get league info
    const leagueInfo = useAppSelector(state => getLeagueById(state, leagueId));
    const leagueApiStatus = useAppSelector(state => state.leagues.getSelectionStatus);
    const leagueApiError = useAppSelector(state => state.leagues.currentError);

    //get tournament info for displaying available rounds and players
    const tournamentInfo = getTournamentById(store.getState(), tournamentId);
    const tournamentData = useAppSelector(state => getTournamentDataById(state, tournamentId));

    //
    const allStatistics = useAppSelector(state => getAllStatistics(state));
    const allAthletes = useAppSelector(state => getAllAthletes(state));

    //user
    const user = useAppSelector(state => state.auth.user);

    //player
    const [playerInfo, setPlayerInfo] = useState<JoinedPlayerType | undefined>(undefined);
    const [playerSelection, setPlayerSelection] = useState<PerformanceSelectionItemType[]>([]);

    //ui
    const listPaddingHorizontal = useBreakpointValue(['16px', '40px', '64px']);
    const listPaddingVertical = useBreakpointValue(['32px', '40px', '64px']);
    const [multiplierHelpOpen, setMultiplierHelpOpen] = useState(false);
    const [subtractHelpOpen, setSubtractHelpOpen] = useState(false);
    const [autoSelectHelpOpen, setAutoSelectHelpOpen] = useState(false);
    const [replacementHelpOpen, setReplacementHelpOpen] = useState(false);
    //ref
    const listRef = useRef<FlatList>(null);

    //--------------------------------------------------------------------- Startup
    //get the current player info object whenever the league info changes (i.e. when the player selection is fetched)
    useEffect(() => {
        if (leagueInfo !== undefined && leagueInfo.players !== undefined) {
            setPlayerInfo(leagueInfo.players.find(player => player.userUid === playerUid));
        }
    }, [leagueInfo]);

    //get the player selection whenever the player info changes
    const fetchTried = useRef<boolean>(false);
    useEffect(() => {
        if (performanceDisplayList === null && allStatistics.length > 0 && tournamentData !== undefined && leagueInfo !== undefined && playerInfo !== undefined) {
            //get selection
            let playerSelection = playerInfo.selection.find(s => s.roundId === selectedRound.id)?.selection;
            let autoSelection = playerInfo.autoSelection.find(s => s.roundId === selectedRound.id)?.selection;
            let cancelledSelection = playerInfo.cancelledSelection.find(s => s.roundId === selectedRound.id)?.selection;

            //check the selection and sort into new array containing selection and autoSelection identifier
            if (playerSelection !== undefined || autoSelection !== undefined || cancelledSelection !== undefined) {
                let playerSelectionList: PerformanceSelectionItemType[] = [];
                leagueInfo.selectedStatistics.forEach(statistic => {
                    let pSelection = playerSelection?.find(s => s.statisticId === statistic);
                    let aSelection = autoSelection?.find(s => s.statisticId === statistic);
                    let cSelection = cancelledSelection?.find(s => s.statisticId === statistic);

                    if (cSelection !== undefined) {
                        playerSelectionList.push({
                            selection: cSelection,
                            autoSelected: false,
                            replaced: true,
                            originalSelectionAthleteId: pSelection?.athleteId
                        });
                    } else if (pSelection !== undefined) {
                        playerSelectionList.push({selection: pSelection, autoSelected: false, replaced: false});
                    } else if (aSelection !== undefined) {
                        playerSelectionList.push({selection: aSelection, autoSelected: true, replaced: false});
                    }
                });
                setPlayerSelection(playerSelectionList);
            }
            //if no selection, fetch the player selection
            else if (!fetchTried.current) {
                fetchTried.current = true;
                //fetch selection
                dispatch(getPlayerSelectionForRound({
                    leagueInfo,
                    roundId: selectedRound.id,
                    userUid: playerInfo.userUid
                }));
            }
        }
    }, [playerInfo]);


    useEffect(() => {
        if (playerSelection.length > 0 && tournamentData !== undefined && allAthletes.length > 0) {
            //get athlete performances
            const roundForPerformance = tournamentData.rounds.find(r => r.id === selectedRound.id);
            if (roundForPerformance !== undefined) {
                const athletes: MatchAthleteDataType[] = [];
                roundForPerformance.matches.forEach(m => {
                    if (m.athleteTwo.id !== '') {
                        athletes.push(m.athleteOne);
                        athletes.push(m.athleteTwo);
                    }
                });

                const performanceSelectionList: PerformanceInfoType[] = [];
                playerSelection.forEach(s => {
                    const statistic = allStatistics.find(st => st.id === s.selection.statisticId);
                    const athlete = athletes.find(ath => ath.id === s.selection.athleteId);
                    const originalPlayer = athletes.find(a => a.id === s.originalSelectionAthleteId);
                    if (statistic && athlete) {
                        performanceSelectionList.push({
                            statistic: statistic,
                            selectedAthlete: {
                                id: athlete.id,
                                displayName: allAthletes.find(a => a.id === athlete.id)?.name ?? '',
                                performanceValue: athlete.performance.find(p => p.id === statistic.id)?.value ?? 0,
                            },
                            autoSelected: s.autoSelected,
                            replaced: s.replaced,
                            originalPlayer: originalPlayer ? {
                                id: originalPlayer.id,
                                displayName: allAthletes.find(a => a.id === originalPlayer.id)?.name ?? '',
                                performanceValue: 0,
                            } : undefined
                        });
                    }
                });

                setPerformanceDisplayList(performanceSelectionList);
                setPerformanceLoading(false);
            }
        }
    }, [playerSelection, tournamentData, allAthletes]);


    //--------------------------------------------------------------------- Callbacks
    //upon pressing the help button for any statistic
    const statHelpCallback = (statistic: StatisticType) => {
        navigation.push(possibleTournamentScreens.StatisticHelp, {statistic});
    };

    const multiplierHelpCallback = () => {
        setMultiplierHelpOpen(true);
    };

    const autoSelectHelpCallback = () => {
        setAutoSelectHelpOpen(true);
    };

    const replacementHelpCallback = () => {
        setReplacementHelpOpen(true);
    };

    const subtractHelpCallback = () => {
        setSubtractHelpOpen(true);
    };

    const scrollToTotalPoints = () => {
        if (listRef.current !== null) {
            listRef.current.scrollToEnd({animated: true});
        }
    };

    //--------------------------------------------------------------------- Render
    if ((performanceLoading || leagueApiStatus === possibleLeagueApiStatus.loading) && leagueApiError === '') {
        return (
            <AppBackground>
                <Center>
                    <Spinner size='lg' color={colorMode === 'light' ? 'light.warning' : 'dark.warning'}/>
                    <Text color={colorMode === 'light' ? 'light.contrast' : 'dark.contrast'}>
                        Performance Loading ...
                    </Text>
                </Center>
            </AppBackground>
        );
    }

    if (!leagueInfo || !tournamentInfo || !tournamentData || performanceDisplayList === null || playerInfo === undefined) {
        return (
            <AppBackground>
                <Center>
                    <Text color={colorMode === 'light' ? 'light.error' : 'dark.error'}>
                        This performance cannot be shown.
                    </Text>
                </Center>
            </AppBackground>
        );
    }

    return (
        <AppBackground flexShrink='1'>
            {
                multiplierHelpOpen ?
                    <MultiplierHelpModal closeModal={() => setMultiplierHelpOpen(false)}/>
                    : <></>
            }
            {
                autoSelectHelpOpen ?
                    <AutoSelectHelpModal closeModal={() => setAutoSelectHelpOpen(false)}/>
                    : <></>
            }
            {
                replacementHelpOpen ?
                    <ReplacementHelpModal closeModal={() => setReplacementHelpOpen(false)}/>
                    : <></>
            }
            {
                subtractHelpOpen ?
                    <SubtractHelpModal closeModal={() => setSubtractHelpOpen(false)}/>
                    : <></>
            }
            <FlatList
                keyExtractor={item => item.statistic.id}
                data={performanceDisplayList}
                ref={listRef}
                renderItem={({item, index}) =>
                    <StatisticPerformanceComponent
                        item={item} index={index} statHelpCallback={statHelpCallback} isScreenBig={isScreenBig}
                        replacementHelpCallback={replacementHelpCallback} subtractHelpCallback={subtractHelpCallback}
                        multiplierHelpCallback={multiplierHelpCallback} autoSelectHelpCallback={autoSelectHelpCallback}
                        colorMode={colorMode} colors={colors} statAmount={performanceDisplayList.length}
                        isShownPlayerUser={user !== null && user.uid === playerUid}
                    />
                }
                style={{}}
                contentContainerStyle={{
                    maxWidth: '1024px',
                    paddingHorizontal: listPaddingHorizontal,
                    paddingVertical: listPaddingVertical
                }}
                ListHeaderComponent={() => <ListInfoHeader playerInfo={playerInfo} tournamentInfo={tournamentInfo}
                                                           selectedRound={selectedRound} colorMode={colorMode}
                                                           scrollToTotalPoints={scrollToTotalPoints}/>}
                ListFooterComponent={() => <ListInfoFooter playerInfo={playerInfo} selectedRound={selectedRound}
                                                           colorMode={colorMode}/>}
            />
        </AppBackground>
    );
};

export default VPlayerPerformanceScreen;