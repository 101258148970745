export const convertMonth = (month: number): string => {
    switch (month) {
        case 0:
            return 'Jan';
        case 1:
            return 'Feb';
        case 2:
            return 'March';
        case 3:
            return 'April';
        case 4:
            return 'May';
        case 5:
            return 'June';
        case 6:
            return 'July';
        case 7:
            return 'Aug';
        case 8:
            return 'Sept';
        case 9:
            return 'Oct';
        case 10:
            return 'Nov';
        case 11:
            return 'Dec';
    }

    return '';
};

export const getEnding = (day: number): string => {
    if (day === 1)
        return 'st';
    if (day === 2)
        return 'nd';
    if (day === 3)
        return 'rd';

    return 'th';
};