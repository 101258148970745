import React from 'react';
import {createNativeStackNavigator, NativeStackHeaderProps} from '@react-navigation/native-stack';
import TournamentScreen from './tournament/TournamentScreen';
import {possibleTournamentScreens, TournamentNavigatorParamList} from './types';
import AllTournamentsScreen from './allTournaments/AllTournamentsScreen';
import LeagueScreen from './leagues/LeagueScreen';
import {useColorMode, useTheme} from 'native-base';
import TournamentNavigatorHeader from './helper/TournamentNavigatorHeader';
import VPlayerBuildingScreen from './leagues/vPlayerBuilding/VPlayerBuildingScreen';
import AthleteSelectionScreen from './leagues/vPlayerBuilding/AthleteSelectionScreen';
import StatisticHelpScreen from './leagues/vPlayerBuilding/StatisticHelpScreen';
import VPlayerPerformanceScreen from './leagues/vPlayerPerformance/VPlayerPerformanceScreen';

const TournamentStack = createNativeStackNavigator<TournamentNavigatorParamList>();

const TournamentNavigator = () => {

    const {colorMode} = useColorMode();
    const {colors} = useTheme();

    return (
        <TournamentStack.Navigator screenOptions={{
            header: ({navigation}: NativeStackHeaderProps) => {
                return <TournamentNavigatorHeader navigation={navigation} colors={colors} colorMode={colorMode}/>;
            },
        }}>
            <TournamentStack.Screen name={possibleTournamentScreens.AllTournaments} component={AllTournamentsScreen}
                                    options={{
                                        headerShown: false,
                                    }}/>
            <TournamentStack.Screen name={possibleTournamentScreens.Tournament} component={TournamentScreen}/>
            <TournamentStack.Screen name={possibleTournamentScreens.League} component={LeagueScreen}/>
            <TournamentStack.Screen name={possibleTournamentScreens.VPlayerBuilding} component={VPlayerBuildingScreen}/>
            <TournamentStack.Group screenOptions={{presentation: 'modal'}}>
                <TournamentStack.Screen name={possibleTournamentScreens.AthleteSelection}
                                        component={AthleteSelectionScreen} options={{headerShown: false}}/>
                <TournamentStack.Screen name={possibleTournamentScreens.StatisticHelp} component={StatisticHelpScreen}/>
            </TournamentStack.Group>
            <TournamentStack.Screen name={possibleTournamentScreens.VPlayerPerformance}
                                    component={VPlayerPerformanceScreen}/>
        </TournamentStack.Navigator>
    );
};

export default TournamentNavigator;