import React from 'react';
import {AspectRatio, Box, ColorMode, Flex, Pressable, useBreakpointValue} from 'native-base';
import {Image} from 'react-native';
import {AntDesign} from '@expo/vector-icons';

interface TrophyModalProps {
    colorMode: ColorMode,
    colors: any,
    setTrophyModalOpen: (open: boolean) => void,
}

const TrophyModal = ({colorMode, colors, setTrophyModalOpen}: TrophyModalProps) => {
    return (
        <Pressable position={'absolute'} top={0} bottom={0} left={0} right={0}
                   bg={colorMode === 'light' ? 'light.bg' : 'dark.bg'} onPress={() => setTrophyModalOpen(false)}>
            <Flex p={5} rounded={'2xl'} overflow={'hidden'} h={'95vh'} w={'95vh'} maxW={'95vw'} maxH={'95vw'}
                  mx={'auto'} my={'auto'} position={'relative'}>
                <Image source={require('assets/trophy/geneva-digital-trophy_mid.jpg')}
                       style={{height: '100%', width: '100%', resizeMode: 'contain', borderRadius: 20}}/>
                <Pressable position={'absolute'} top={7} right={7} onPress={() => setTrophyModalOpen(false)}>
                    {
                        ({isPressed, isHovered}) => (
                            <AntDesign name='closecircle' size={useBreakpointValue([24, 28, 32])}
                                       color={isPressed || isHovered ? colors['primary'] : colors['dark']['error']}/>
                        )
                    }
                </Pressable>
            </Flex>
        </Pressable>
    );
};

export default TrophyModal;