// noinspection ExceptionCaughtLocallyJS

import {createAsyncThunk} from '@reduxjs/toolkit';
import {fb_firestore} from '../../../../../App';
import {doc, getDoc} from 'firebase/firestore';
import {LeagueDataType, LeagueVisibilityOptions} from '../types';
import {fetchPlayersForLeague} from './helper/fetchPlayersForLeague';

interface FetchLeagueByIdProps {
    leagueIds: string[];
    userUid: string;
}

export const fetchLeaguesByIds = createAsyncThunk(
    'leagues/fetchLeagueById',
    async ({leagueIds, userUid}: FetchLeagueByIdProps, {rejectWithValue}) => {
        try {

            const leagues: LeagueDataType[] = [];

            await Promise.all(leagueIds.map(async (leagueId) => {
                const q = doc(fb_firestore, `leagues/${leagueId}`);
                const qSnapshot = await getDoc(q);

                if (qSnapshot.exists()) {

                    const league = qSnapshot.data();

                    const players = await fetchPlayersForLeague(qSnapshot.id, userUid);

                    const newLeagueObj: LeagueDataType = {
                        id: qSnapshot.id,
                        name: league.name,
                        tournamentId: league.tournament,
                        visibility: league.visibility as LeagueVisibilityOptions,
                        canJoinLater: league.canPlayerJoinLater,
                        selectedStatistics: league.statistics,
                        players: players,
                    };

                    // console.log('new league obj', newLeagueObj.name)
                    // console.log(newLeagueObj)

                    leagues.push(newLeagueObj);
                }
            }));

            return leagues;

        } catch (e) {
            console.log('error in fetch league by id');
            console.log(e.message);
            return rejectWithValue(e.message);
        }
    }
);