export enum possibleStatisticsInfoStatus {
    idle = 'idle',
    loading = 'loading',
    succeeded = 'succeeded',
    failed = 'failed',
}

export enum StatisticValueOptions {
    percentage = 'percentage',
    absolute = 'absolute',
    subtractAbsolute = 'subtractAbsolute',
    subtractPercentage = 'subtractPercentage',
}

export type StatisticType = {
    id: string,
    displayName: string,
    performanceMultiplier: number,
    subtractValue: number,
    subtractMultiplier: number
    infoText: string,
    value: StatisticValueOptions,
    order: number
}