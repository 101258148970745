import React, {useEffect, useState} from 'react';
import {Box, ColorMode, Flex, Heading, HStack, Pressable, Text, VStack} from 'native-base';
import {possibleTournamentScreens, TournamentNavigationProp} from '../../../types';
import {Fontisto} from '@expo/vector-icons';
import {LeagueDataType} from '../../../leagues/api/types';
import {useAppSelector} from '../../../../../app/redux/hooks';

export type LeagueInfoComponentType = {
    leagueInfo: LeagueDataType,
    navigation: TournamentNavigationProp,
    colorMode: ColorMode,
    userToLeagueStatus: possibleUserToLeagueStatus,
    colors: any,
    tournamentId: string,
}

export enum possibleUserToLeagueStatus {
    leagueAlreadyJoined = 'leagueAlreadyJoined',
    leagueAvailable = 'leagueAvailable',
    leagueLocked = 'leagueLocked'
}

const LeagueInfoComponent = ({
                                 leagueInfo,
                                 navigation,
                                 colorMode,
                                 userToLeagueStatus,
                                 colors,
                                 tournamentId
                             }: LeagueInfoComponentType) => {

    //---------------------------------------------------------------- get rank if possible
    const user = useAppSelector(state => state.auth.user);
    const [rank, setRank] = useState('');
    useEffect(() => {
        if (userToLeagueStatus === possibleUserToLeagueStatus.leagueAlreadyJoined) {
            const userIndex = leagueInfo.players?.findIndex(player => player.userUid === user.uid);
            if (userIndex != -1) {
                setRank(((userIndex ?? 0) + 1).toString());
            }
        }
    }, [userToLeagueStatus, leagueInfo]);

    //---------------------------------------------------------------- callbacks
    const LeagueInfoPressCallback = () => {
        navigation.push(possibleTournamentScreens.League, {leagueId: leagueInfo.id, tournamentId});
    };

    //---------------------------------------------------------------- render
    const renderAvailable = (isPressed: boolean, isHovered: boolean) => (
        <Box bg={isPressed || isHovered ? 'primary' : colorMode === 'light' ? 'light.contrast' : 'dark.contrast'}
             rounded={'full'} px={16} py={3} w='full'>
            <Text fontSize={['12px', '16px', '20px']}
                  color={isPressed || isHovered ? 'primaryPartner' : colorMode === 'light' ? 'light.contrastPartner' : 'dark.contrastPartner'}>
                View
            </Text>
        </Box>
    );

    const renderLocked = () => (
        <VStack>
            <HStack alignItems='center' mb={2}>
                <Fontisto name='locked' size={24}
                          color={colorMode === 'light' ? colors['light']['contrastMid'] : colors['dark']['contrastMid']}/>
                <Heading textAlign='right' fontSize='32px' mb='-12px' ml='3'
                         color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                    locked
                </Heading>
            </HStack>
            <Text textAlign='center' color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}
                  fontSize='xs'>
                click to view
            </Text>
        </VStack>
    );

    const renderJoined = () => {
        if (rank === '')
            return <></>;
        return (
            <>
                <Text fontSize={['12px', '16px', '20px']} textAlign='left' mr='2' mb='-8px' opacity={0.5}
                      color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                    Position
                </Text>
                <Heading fontSize='132px' color={'primary'} lineHeight='1em' mb='-35px'>
                    {rank}
                    {/*26*/}
                </Heading>
            </>
        );
    };

    const renderBottomInfo = (isPressed: boolean, isHovered: boolean) => {

        switch (userToLeagueStatus) {
            case possibleUserToLeagueStatus.leagueAlreadyJoined:
                return renderJoined();
            case possibleUserToLeagueStatus.leagueAvailable:
                return renderAvailable(isPressed, isHovered);
            case possibleUserToLeagueStatus.leagueLocked:
                return renderLocked();
            default:
                return <></>;
        }
    };

    return (
        <Pressable onPress={LeagueInfoPressCallback} h={['350px', '387px', '425px']} w={['250px', '287px', '325px']}
                   rounded='50px' p={['8', '9', '10']}
                   bg={colorMode === 'light' ? 'light.layout' : 'dark.layout'}
        >
            {({isPressed, isHovered}) => (
                <>
                    <Text fontSize={['6px', '10px', '14px']} textAlign='left' opacity={0.5} mb={2}
                          color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                        League
                    </Text>
                    <Heading fontSize={['32px', '42px', '52px']} lineHeight='1em' textAlign='left' mb='2'
                             color={colorMode === 'light' ? 'light.contrast' : 'dark.contrast'}>
                        {leagueInfo.name}
                    </Heading>
                    <Text fontSize={['12px', '16px', '20px']} textAlign='left' mb='5' opacity={0.5}
                          color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                        {getPlayerDisplay(leagueInfo.players?.length ?? 0)}
                    </Text>
                    <Flex width='full' direction='row' alignItems='flex-end' justifyContent='flex-end' mt='auto'>
                        {renderBottomInfo(isPressed, isHovered)}
                    </Flex>
                </>
            )}
        </Pressable>
    );
};

const getPlayerDisplay = (playerNumber: number) => {
    return (playerNumber === 0 ? 'No' : playerNumber) + ' Player' + (playerNumber !== 1 ? 's' : '');
};

export default LeagueInfoComponent;