import {LeagueDataType} from '../../api/types';
import React from 'react';
import {getStatisticById} from '../../../statistics/api/statisticsSlice';
import {store} from '../../../../../app/redux/store';
import {StatisticSelectionMatchType, StatisticSelectionType} from '../types';
import {AthleteDataType} from '../../../athletes/api/types';
import {setPlayerSelection} from '../redux/playerBuildingSlice';

export interface GetStatisticsForThisRoundProps {
    leagueInfo: LeagueDataType;
    userUid: string;
    roundId: string;
    matches: StatisticSelectionMatchType[];
    dispatch: React.Dispatch<any>;
    setStatisticsSelectionListError: React.Dispatch<React.SetStateAction<string>>;
}

export const GetPlayerAndStatistics = ({
                                           leagueInfo,
                                           userUid,
                                           roundId,
                                           matches,
                                           dispatch,
                                           setStatisticsSelectionListError
                                       }: GetStatisticsForThisRoundProps) => {
    try {

        //create temp list
        const statList: StatisticSelectionType[] = [];
        //safety
        if (leagueInfo === undefined || leagueInfo.selectedStatistics.length <= 0 || leagueInfo.players === undefined) {
            // noinspection ExceptionCaughtLocallyJS
            throw new Error('Statistics cannot be loaded');
        }

        //get the athlete array
        const athletes: AthleteDataType[] = [];
        matches.forEach((statSelectionMatch) => {
            athletes.push(statSelectionMatch.athleteOne);
            if (statSelectionMatch.athleteTwo !== null)
                athletes.push(statSelectionMatch.athleteTwo);
        });

        //iterate statistics
        leagueInfo.selectedStatistics.forEach(statistic => {
            //get the statistic data from the database storage
            let statInfo = getStatisticById(store.getState(), statistic);
            if (statInfo !== undefined) {
                //check if the player has already made a selection
                let possibleAthlete = null;
                //get player selection
                let selectionForPlayer = leagueInfo.players?.find(player => player.userUid === userUid)?.selection.find(selection => selection.roundId === roundId)?.selection;
                //if the player has made a selection
                if (selectionForPlayer) {
                    let playerSelection = selectionForPlayer.find(selection => selection.statisticId === statInfo?.id);
                    if (playerSelection !== undefined) {
                        //check if the respective athlete from the database is in the list for this round
                        let athlete = athletes.find(athlete => athlete.id === playerSelection?.athleteId);
                        if (athlete) {
                            //if there is an athlete - store it
                            possibleAthlete = athlete;
                        }
                    }
                }
                //add statistic to list
                statList.push({
                    statistic: statInfo,
                    selectedAthlete: possibleAthlete,
                });
            }
        });

        // console.table(statList);

        //update stats list
        dispatch(setPlayerSelection(statList));

        setStatisticsSelectionListError('');
    } catch (e) {
        setStatisticsSelectionListError(e.message);
    }
};
