import React from 'react';
import {JoinedPlayerType} from "../../api/types";
import {RoundDataType} from "../../../tournament/api/types";
import {Box, ColorMode, Text, HStack} from "native-base";

interface ListInfoFooterProps {
    playerInfo: JoinedPlayerType | null,
    selectedRound: RoundDataType,
    colorMode: ColorMode
}

const ListInfoFooter = ({playerInfo, selectedRound, colorMode}: ListInfoFooterProps) => {

    return (
        <HStack justifyContent={'flex-end'} px={[6, 8, 10]}>
            <Box w={['80px', '140px', '200px']} roundedBottom={'25px'} pb={5}
                 bg={colorMode === 'light' ? 'light.contrast' : 'dark.contrast'} zIndex={'-1'}>
                <Text fontSize={['2xl', '3xl', '4xl']} color={'primary'}>
                    {playerInfo?.performance.find(p => p.roundId === selectedRound.id)?.SCORE}
                </Text>
                <Text fontSize={['xs', 'sm', 'md']}
                      color={'dark.contrastMid'}>
                    Total Points
                </Text>
            </Box>
        </HStack>
    );
};

export default ListInfoFooter;