import {ColorMode, Flex, HStack, Link} from 'native-base';
import {Feather} from '@expo/vector-icons';
import React from 'react';
import {AntDesign} from '@expo/vector-icons';
import {Ionicons} from '@expo/vector-icons';
import {possibleMainScreens} from '../types';

export const DrawerHeader = ({
                                 navigation,
                                 colorMode,
                                 colors,
                                 isScreenBig
                             }: { navigation: any, colorMode: ColorMode, colors: any, layout?: any, isScreenBig: boolean }) => {

    const itemColor = colorMode === 'light' ? colors['light']['contrast'] : colors['dark']['contrast'];

    const openDrawerCallback = () => {
        navigation.openDrawer();
    };

    return (
        <Flex bg={colorMode === 'light' ? colors['light']['bg'] : colors['dark']['bg']} px='20px' pt='20px'>
            <Flex direction={'row'} alignItems='center' justifyContent={isScreenBig ? 'flex-end' : 'space-between'}
                  maxW={'1680px'}>
                {
                    !isScreenBig ?
                        <Link onPress={openDrawerCallback}>
                            <Feather name='menu' size={36} color={itemColor}/>
                        </Link>
                        : <></>
                }
                <HStack space={5} display='flex' flexDir='row' alignItems='center'>
                    <Link onPress={() => navigation.navigate(possibleMainScreens.Dashboard)}>
                        <Ionicons name='tennisball-outline' size={28} color={itemColor}/>
                    </Link>
                    <Link onPress={() => navigation.navigate(possibleMainScreens.Account)}>
                        <AntDesign name='user' size={28} color={itemColor}/>
                    </Link>
                    <Link onPress={() => navigation.navigate(possibleMainScreens.Help)}>
                        <Ionicons name='md-help-circle-outline' size={34} color={itemColor}/>
                    </Link>
                </HStack>
            </Flex>
        </Flex>
    );
};