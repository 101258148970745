import {extendTheme} from 'native-base';
import {appComponents} from './appComponents';

/** ------------------------------------------------------------------
 * Colors Object
 */
const appColorTheme = {
    primary: '#ECF012',
    primaryPartner: '#161616',
    light: {
        bg: '#F4F4F4',
        title: '#161616',
        body: '#333333',
        layout: '#FFFFFF',
        layoutShade: '#F9F9F9',
        contrast: '#333333',
        contrastPartner: '#FFFFFF',
        contrastMid: '#C7C7C7',
        contrastLow: '#EDEDED',
        error: '#dc2626',
        errorPartner: '#FFFFFF',
        warning: '#f9c016',
        success: '#39D15A',
        successPartner: '#FFFFFF',
    },
    dark: {
        bg: '#161616',
        title: '#FFFFFF',
        body: '#E8E9F0',
        layout: '#070707',
        layoutShade: '#0E0E0F',
        contrast: '#E8E9F0',
        contrastPartner: '#161616',
        contrastMid: '#626262',
        contrastLow: '#323233',
        error: '#c43838',
        errorPartner: '#FFFFFF',
        warning: '#d29e23',
        success: '#39D15A',
        successPartner: '#FFFFFF',
    },
};

/** ------------------------------------------------------------------
 * Theme Config
 */
const appThemeConfig = {
    initialColorMode: 'light',
    useSystemColorMode: true,
};

/** ------------------------------------------------------------------
 * Font Sizes
 */
// const appFontSizes = {}

/** ------------------------------------------------------------------
 * Fonts
 */
const appFonts = {
    heading: 'Teko_700Bold',
    body: 'Inter_600SemiBold',
    mono: 'Inter_600SemiBold'
};

/** ------------------------------------------------------------------
 * App Theme to be exported and used in Native Base Provider
 */
export const appTheme = extendTheme({
    colors: appColorTheme,
    config: appThemeConfig,
    fonts: appFonts,
    components: appComponents
});

/** ------------------------------------------------------------------
 * Typescript for App Theme
 */
type CustomThemeType = typeof appTheme;

declare module 'native-base' {
    interface ICustomTheme extends CustomThemeType {
    }
}