import React from 'react';
import {Container, Flex, Heading, Text, Link, VStack, Box, useColorMode} from 'native-base';
import AppBackground from 'components/essential/AppBackground';
import {Linking, useWindowDimensions} from "react-native";

const HelpScreen = () => {

    const {colorMode} = useColorMode()

    const isScreenBig = useWindowDimensions().width > 768;

    const handleOpenHowTo = () => {
        Linking.openURL('https://firebasestorage.googleapis.com/v0/b/digital-tennis-tour.appspot.com/o/How-to-play-Digital-Tennis-Tour.pdf?alt=media&token=df27f41e-77b4-4861-8c91-198324053afe').then();
    }

    return (
        <AppBackground p={['4', '10', '16']} py={['8', '10', '16']}>
            <VStack maxWidth={1024} space={10}>
                <Container centerContent={true}>
                    <Heading textAlign={'center'}>
                        Support
                    </Heading>
                </Container>
                <Container>
                    <Flex direction={isScreenBig ? 'row' : 'column'} alignItems={'center'} w={'full'} mb={5}>
                        <Text textAlign={isScreenBig ? 'left' : 'center'} fontSize={['xl', '2xl', '3xl']}
                              mr={isScreenBig ? 2 : 0}>
                            View the how-to
                        </Text>
                        <Link onPress={handleOpenHowTo}>
                            <Text textAlign={isScreenBig ? 'left' : 'center'} fontSize={['xl', '2xl', '3xl']}
                                  underline={true} color={'blue.500'}>
                                here
                            </Text>
                        </Link>
                    </Flex>
                    <Box w={'full'} h={'2px'} bg={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}
                         mb={5}/>
                    <VStack alignItems={isScreenBig ? 'left' : 'center'} space={2}>
                        <Text textAlign={isScreenBig ? 'left' : 'center'} fontSize={['xl', '2xl', '3xl']}>
                            or contact us anytime via email:
                        </Text>
                        <Link onPress={() => Linking.openURL('mailto:support@digital-tennis-tour.com')}>
                            <Text textAlign={isScreenBig ? 'left' : 'center'} fontSize={['xl', '2xl', '3xl']}
                                  underline={true} color={'blue.500'}>
                                support@digital-tennis-tour.com
                            </Text>
                        </Link>
                    </VStack>
                </Container>
            </VStack>
        </AppBackground>
    );
};

export default HelpScreen;