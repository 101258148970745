// noinspection ExceptionCaughtLocallyJS

import {createAsyncThunk} from '@reduxjs/toolkit';
import {UserDataType} from '../../account/userData/types';
import {doc, getDoc} from 'firebase/firestore';
import {fb_firestore} from '../../../App';

interface GetUserDataProps {
    userUid: string,
}

export const getUserData = createAsyncThunk(
    'userData/getUserData',
    async ({userUid}: GetUserDataProps, {rejectWithValue}) => {
        try {
            //get joined leagues
            const q = doc(fb_firestore, `users`, userUid);
            const userDoc = await getDoc(q);

            let newUserData: UserDataType = {
                joinedLeagues: []
            };

            if (userDoc.exists()) {

                const dbData = userDoc.data();

                if (dbData) {
                    newUserData.joinedLeagues = dbData.joinedLeagues ?? [];
                }
            }

            return newUserData;
        } catch (error) {
            console.log('error in userData fetch');
            console.log(error);
            return rejectWithValue(error.message);
        }
    }
);