export enum possibleTournamentDataState {
    idle = 'idle',
    loading = 'loading',
    succeeded = 'succeeded',
    failed = 'failed',
}

export type TournamentDataType = {
    id: string;
    rounds: RoundDataType[];
    finalRoundInfos: FinalWinnerData;
};

export enum possibleTournamentStatus {
    completed = 'completed',
    active = 'active',
    upcoming = 'upcoming',
}

export enum possibleRoundStates {
    upcoming = 'upcoming',
    open = 'open',
    playingActive = 'playingActive',
    completed = 'completed',
}

export enum possibleRoundIds {
    upcoming = 'upcoming',
    r128 = 'r128',
    r64 = 'r64',
    r32 = 'r32',
    r16 = 'r16',
    r8 = 'r8',
    r4 = 'r4',
    r2 = 'r2',
    completed = 'completed',
}

export type RoundDataType = {
    id: string;
    startDate: string;
    endDate: string;
    status: possibleRoundStates;
    matches: MatchDataType[];
    isEntry: false;
};
// export type MatchDataType = {
//     id: string;
//     athletes: MatchAthleteDataType[];
// }
export type MatchDataType = {
    id: string;
    athleteOne: MatchAthleteDataType;
    athleteTwo: MatchAthleteDataType;
}
export type MatchAthleteDataType = {
    id: string;
    performance: AthletePerformanceDataType[];
}
export type AthletePerformanceDataType = {
    id: string;
    value: number;
}
export type FinalWinnerData = {
    athlete: string;
}