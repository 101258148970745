import {LeagueDataType, LeagueVisibilityOptions, possibleLeagueApiStatus} from '../../leagues/api/types';
import {Flex, Heading, Spinner, Text, useColorMode, useTheme, VStack} from 'native-base';
import React, {useEffect, useState} from 'react';
import {selectLeaguesByTournament} from '../../leagues/api/leagueSlice';
import {store} from '../../../../app/redux/store';
import {useAppDispatch, useAppSelector} from '../../../../app/redux/hooks';
import {getAllLeaguesForTournamentId} from '../../leagues/api/functions/getAllLeaguesForTournamentId';
import {TournamentNavigationProp} from '../../types';
import {SelectionHeader} from './LeagueComponents/LeaguesSelectionHeader';
import {possibleLeaguesListDisplayStates} from './types';
import LeaguesList from './LeagueComponents/LeaguesList';
import {possibleRoundIds} from '../api/types';

interface AllLeaguesComponentProps {
    tournamentId: string;
    currentTournamentRound: possibleRoundIds;
    navigation: TournamentNavigationProp;
}

export const TournamentLeaguesComponent = ({
                                               tournamentId,
                                               currentTournamentRound,
                                               navigation
                                           }: AllLeaguesComponentProps) => {

    //helper
    const {colorMode} = useColorMode();
    const {colors} = useTheme();
    const dispatch = useAppDispatch();

    //------------------------------------------------------------------------------ get leagues for tournamentId
    // @ts-ignore
    const leagues = selectLeaguesByTournament(store.getState(), tournamentId);
    const leagueApiStatus = useAppSelector(state => state.leagues.status);
    const leagueApiError = useAppSelector(state => state.leagues.currentError);
    useEffect(() => {
        if (leagueApiStatus !== possibleLeagueApiStatus.loading)
            dispatch(getAllLeaguesForTournamentId(tournamentId));
    }, [tournamentId]);

    //------------------------------------------------------------------------------ sort joined and available leagues
    //user state
    const user = useAppSelector(state => state.auth.user);

    //local storage for sorted leagues
    const [joinedLeagues, setJoinedLeagues] = useState<LeagueDataType[]>([]);
    const [availableLeagues, setAvailableLeagues] = useState<LeagueDataType[]>([]);

    useEffect(() => {
        if (leagues.length > 0 && leagueApiStatus === possibleLeagueApiStatus.idle) {
            let joLe: LeagueDataType[] = [];
            let avLe: LeagueDataType[] = [];
            leagues.forEach(league => {
                if (league.players?.find(player => player.userUid === user.uid))
                    joLe.push(league);
                else if (league.visibility === LeagueVisibilityOptions.public)
                    avLe.push(league);
            });
            //
            setJoinedLeagues(joLe);
            setAvailableLeagues(avLe);

            if (joLe.length > 0)
                setCurrentSelection(possibleLeaguesListDisplayStates.joined);
        }
    }, [leagues]);

    //ui states
    const [currentSelection, setCurrentSelection] = useState<possibleLeaguesListDisplayStates>(possibleLeaguesListDisplayStates.available);

    //------------------------------------------------------------------------------ render helper
    const renderLeaguesOrInfo = () => {
        //during load show spinner
        if (leagueApiStatus === possibleLeagueApiStatus.loading) {
            return (
                <Flex py='20'>
                    <Spinner size='lg' color={colors.primary}/>
                </Flex>
            );
        }
        //if error show message
        else if (leagueApiError !== '') {
            return (
                <Flex py='20'>
                    <Text color={colorMode === 'light' ? 'light.error' : 'dark.error'}>
                        {leagueApiError}
                    </Text>
                </Flex>
            );
        }
        //if selection is joined - show leaguesList with joined leagues
        else if (currentSelection === possibleLeaguesListDisplayStates.joined)
            return <LeaguesList leagues={joinedLeagues} tournamentId={tournamentId} emptyMessage='No leagues joined'
                                leagueDisplayState={currentSelection} currentTournamentRound={currentTournamentRound}
                                colorMode={colorMode} colors={colors} navigation={navigation}/>;
        //if selection is available - show leaguesList with available leagues
        else
            return <LeaguesList leagues={availableLeagues} tournamentId={tournamentId}
                                emptyMessage='No leagues available'
                                leagueDisplayState={currentSelection} currentTournamentRound={currentTournamentRound}
                                colorMode={colorMode} colors={colors} navigation={navigation}/>;
    };

    //------------------------------------------------------------------------------ render
    return (
        <Flex maxW='1280px'>
            <VStack mb={6}>
                <Heading textAlign='left' fontSize={['3xl', '3xl', '4xl']}
                         color={colorMode === 'light' ? 'light.contrast' : 'dark.contrast'}>
                    Leagues
                </Heading>
                <Text textAlign='left' fontSize={['sm', 'md', 'lg']}
                      color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                    Leagues are ranked groups for one tournament. Join an available league to participate in the action
                    or view a joined league to see the current standings and build your player.
                </Text>
            </VStack>
            <SelectionHeader currentSelection={currentSelection} setCurrentSelection={setCurrentSelection}
                             colorMode={colorMode}/>
            {renderLeaguesOrInfo()}
        </Flex>
    );
};
