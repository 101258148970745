import React from 'react';
import {ColorMode, Flex, Link, Pressable, Text} from 'native-base';
import {Ionicons} from '@expo/vector-icons';
import {StatisticType} from '../../../statistics/api/types';
import {StatisticSelectionType} from '../types';
import {AntDesign} from '@expo/vector-icons';
import Badge from 'components/basic/Badge';

interface StatisticBoxProps {
    item: StatisticSelectionType;
    index: number;
    selectionButtonCallback: (item: StatisticSelectionType) => void;
    helpButtonCallback: (statistic: StatisticType) => void;
    colorMode: ColorMode;
    colors: any;
    wasConfirmTried: boolean;
}

const StatisticSelectionComponent = ({
                                         item,
                                         selectionButtonCallback,
                                         helpButtonCallback,
                                         colorMode,
                                         colors,
                                         wasConfirmTried,
                                     }: StatisticBoxProps) => {

    return (
        <Flex bg={colorMode === 'light' ? 'light.layout' : 'dark.layout'} p='4' rounded='20px'
              style={{
                  shadowColor: colorMode === 'light' ? '#8f8f8f' : '#1f1f1f',
                  shadowOffset: {
                      width: 2,
                      height: 2,
                  },
                  shadowOpacity: 0.1,
                  shadowRadius: 10,
              }}
              borderWidth='2'
              borderColor={wasConfirmTried ? (item.selectedAthlete ? 'transparent' : (colorMode === 'light' ? 'light.error' : 'dark.error')) : 'transparent'}
        >
            {
                wasConfirmTried && !item.selectedAthlete ?
                    <Badge text='please select athlete' variant='error' colorMode={colorMode} position='absolute'
                           top='-15px' right='10px'/>
                    : <></>
            }
            <Flex direction='row' justifyContent={['space-between', 'flex-start', 'flex-start']} alignItems='center'
                  ml='1' mb='3'>
                <Text fontSize='xl' color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}
                      mr='2' textAlign='left' lineHeight='1em'>
                    {item.statistic.displayName}
                </Text>
                <Link onPress={() => helpButtonCallback(item.statistic)}>
                    <Ionicons name='md-help-circle-outline' size={24}
                              color={colorMode === 'light' ? colors['light']['contrastMid'] : colors['dark']['contrastMid']}/>
                </Link>
            </Flex>
            <Flex direction='row' alignSelf='stretch'>
                <Pressable bg={colorMode === 'light' ? 'light.layoutShade' : 'dark.layoutShade'} p='2'
                           rounded='15px' _pressed={{bg: 'primary'}} _hover={{bg: 'primary'}}
                           onPress={() => selectionButtonCallback(item)}
                           flexGrow='1'>
                    {
                        ({isPressed, isHovered}) => {
                            if (item.selectedAthlete) {
                                return (
                                    <Flex direction='row' justifyContent='flex-start' alignItems='center'>
                                        <AntDesign name='checkcircle' size={20}
                                                   style={{marginLeft: '3px', marginRight: '3px'}}
                                                   color={colorMode === 'light' ? colors['light']['success'] : colors['dark']['success']}/>
                                        <Text
                                            alignSelf='flex-start' fontSize='xl' ml='2'
                                            color={isPressed || isHovered ?
                                                'primaryPartner'
                                                : colorMode === 'light' ? 'light.contrast' : 'dark.contrast'}
                                        >
                                            {item.selectedAthlete.name}
                                        </Text>
                                    </Flex>
                                );
                            } else {
                                return (
                                    <Flex direction='row' justifyContent='flex-start' alignItems='center'>
                                        <Ionicons
                                            name='information-circle' size={26}
                                            color={isPressed || isHovered ?
                                                colors['primaryPartner']
                                                : wasConfirmTried ?
                                                    (colorMode === 'light' ? colors['light']['error'] : colors['dark']['error'])
                                                    : (colorMode === 'light' ? colors['light']['warning'] : colors['dark']['warning'])}
                                        />
                                        <Text
                                            fontSize='md' alignSelf='center' ml='2'
                                            color={isPressed || isHovered ?
                                                'primaryPartner'
                                                : colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}
                                        >
                                            select athlete
                                        </Text>
                                    </Flex>
                                );
                            }
                        }
                    }
                </Pressable>
            </Flex>
        </Flex>
    );
};

export default StatisticSelectionComponent;