import {ColorMode, HStack, Pressable, Text} from 'native-base';
import React from 'react';
import {possibleLeaguesListDisplayStates} from '../types';

interface SelectionHeaderProps {
    currentSelection: possibleLeaguesListDisplayStates,
    setCurrentSelection: (selection: possibleLeaguesListDisplayStates) => void,
    colorMode: ColorMode,
}

export const SelectionHeader = ({currentSelection, setCurrentSelection, colorMode}: SelectionHeaderProps) => {
    return (
        <HStack bg={colorMode === 'light' ? 'light.contrast' : 'dark.contrast'} rounded='full' p='6px' space='4'>
            <Pressable flexGrow={1} px='4' rounded='full' p='2'
                       onPress={() => setCurrentSelection(possibleLeaguesListDisplayStates.joined)}
                       bg={currentSelection === possibleLeaguesListDisplayStates.joined ?
                           colorMode === 'light' ? 'light.layoutShade' : 'dark.layoutShade'
                           : 'transparent'}>
                <Text color={currentSelection === possibleLeaguesListDisplayStates.joined ?
                    colorMode === 'light' ? 'light.contrast' : 'dark.contrast'
                    : colorMode === 'light' ? 'light.contrastPartner' : 'dark.contrastPartner'}>
                    Joined
                </Text>
            </Pressable>
            <Pressable flexGrow={1} px='4' rounded='full' p='2'
                       onPress={() => setCurrentSelection(possibleLeaguesListDisplayStates.available)}
                       bg={currentSelection === possibleLeaguesListDisplayStates.available ?
                           colorMode === 'light' ? 'light.layoutShade' : 'dark.layoutShade'
                           : 'transparent'}>
                <Text color={currentSelection === possibleLeaguesListDisplayStates.available ?
                    colorMode === 'light' ? 'light.contrast' : 'dark.contrast'
                    : colorMode === 'light' ? 'light.contrastPartner' : 'dark.contrastPartner'}>
                    Available
                </Text>
            </Pressable>
            {/*<Flex px='6' py='1' rounded='full' bg='primary' justifyContent='center'>*/}
            {/*    <Text>*/}
            {/*        +*/}
            {/*    </Text>*/}
            {/*</Flex>*/}
        </HStack>
    );
};