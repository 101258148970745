import {DrawerNavigationProp, DrawerScreenProps} from '@react-navigation/drawer';
import {NavigatorScreenParams} from "@react-navigation/native";
import {TournamentNavigatorParamList} from "../tournaments/types";

export enum possibleMainScreens {
    Dashboard = 'Dashboard',
    Tournaments = 'Tournaments',
    Account = 'Account',
    Help = 'Help',
}

export type MainNavigatorParamList = {
    Dashboard: undefined,
    Tournaments: NavigatorScreenParams<TournamentNavigatorParamList>,
    TournamentNavigator: { tournamentId: string },
    Account: undefined,
    Help: undefined,
}

export type DashboardProps = DrawerScreenProps<MainNavigatorParamList, possibleMainScreens.Dashboard>

export type TournamentsProps = DrawerScreenProps<MainNavigatorParamList, possibleMainScreens.Tournaments>

export type AccountProps = DrawerScreenProps<MainNavigatorParamList, possibleMainScreens.Account>

export type HelpProps = DrawerScreenProps<MainNavigatorParamList, possibleMainScreens.Help>