import React from 'react';
import {Box, ColorMode, Heading, Text} from 'native-base';
import {LeagueDataType} from '../api/types';
import {TournamentInfoDataType} from '../../allTournaments/api/tournamentInfoSlice';
import {TournamentCompleteInfo, TournamentUpcomingInfo} from './LeagueScreen/TournamentStatusComponents';
import LeagueDescriptionInfo from './LeagueScreen/LeagueDescriptionInfo';
import CasualLeagueInfoAlert from './LeagueScreen/CasualLeagueInfoAlert';

interface LeagueInfoHeaderProps {
    leagueInfo: LeagueDataType,
    tournamentInfo: TournamentInfoDataType,
    colorMode: ColorMode,
    colors: any,
    showFinalRanking: boolean,
    hasTournamentStarted: boolean,
}

const LeagueInfoHeader = ({
                              leagueInfo,
                              tournamentInfo,
                              colorMode,
                              showFinalRanking,
                              hasTournamentStarted,
                              colors
                          }: LeagueInfoHeaderProps) => {

    const startDate = new Date(tournamentInfo?.startDate ?? '');

    //check which current information about the league should be shown
    const renderLeagueInfo = () => {
        if (showFinalRanking) {
            return (
                <TournamentCompleteInfo colorMode={colorMode} colors={colors}/>
            );
        } else if (!hasTournamentStarted) {
            return (
                <TournamentUpcomingInfo colorMode={colorMode} startDate={startDate}/>
            );
        } else {
            return (
                <LeagueDescriptionInfo colorMode={colorMode}/>
            );
        }
    };

    return (
        <>
            <Heading fontSize={['6xl', '7xl', '8xl']} alignSelf={'flex-start'} lineHeight='1em'
                     textAlign='left'>
                {leagueInfo.name}
            </Heading>
            <Text fontSize={['xl', '2xl', '3xl']} alignSelf={'flex-start'}
                  color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                {tournamentInfo.name}
            </Text>
            {renderLeagueInfo()}
            {
                leagueInfo.canJoinLater ?
                    <CasualLeagueInfoAlert colorMode={colorMode}/>
                    : <></>
            }
        </>
    );
};

export default LeagueInfoHeader;