import React from 'react';
import {Box, ColorMode, Flex, Text, useBreakpointValue} from 'native-base';
import {possibleRoundIds, RoundDataType} from '../api/types';
import {convertRoundName} from '../../../../app/helper/nameConversionHelper';
import {AntDesign, Feather, FontAwesome, FontAwesome5, MaterialIcons} from '@expo/vector-icons';

interface CurrentRoundIndicatorProps {
    rounds: RoundDataType[];
    currentRound: possibleRoundIds;
    colorMode: ColorMode;
    colors: any;
}

const CurrentRoundIndicator = ({rounds, currentRound, colorMode, colors}: CurrentRoundIndicatorProps) => {

    const normalHeight = ['4px', '5px', '6px'];
    const tournamentUpcoming = currentRound === possibleRoundIds.upcoming;

    const roundIndicators = rounds.map((round) => {
        const isActive = round.id === currentRound;
        return (
            <Box mr={['6px', '12px', '18px']} h={isActive ? 'full' : normalHeight} rounded='full' key={round.id}
                 flexGrow={isActive ? 0 : 1}
                 bg={isActive ? 'primary' : colorMode === 'light' ? 'light.contrastLow' : 'dark.contrastLow'}>
                {
                    isActive ? (
                        <Text fontSize={['xs', 'sm', 'md']} my='1' mx='6' color='primaryPartner'>
                            {convertRoundName(round.id)}
                        </Text>
                    ) : <></>
                }
            </Box>
        );
    });

    return (
        <Flex width='100%' maxWidth={['312px', '425px', '648px']} direction='row' alignItems='center'>
            <Flex rounded='full' alignSelf='stretch' justifyContent='center' py='1' px='3' mr={['6px', '12px', '18px']}
                  bg={tournamentUpcoming ? 'primary' : colorMode === 'light' ? 'light.contrastLow' : 'dark.contrastLow'}>
                <FontAwesome name="arrow-circle-right" size={useBreakpointValue([11, 13, 15])}
                             color={tournamentUpcoming ? 'primaryPartner' : colorMode === 'light' ? colors['light']['contrastMid'] : colors['dark']['contrastMid']}/>
            </Flex>
            {roundIndicators}
            {/* the winner indicator is fine like this as it will never be active aka roundIndicator will not be displayed if tournament is complete */}
            <Flex rounded='full' alignSelf='stretch' justifyContent='center' py='1' px='3'
                  bg={colorMode === 'light' ? 'light.contrastLow' : 'dark.contrastLow'}>
                <FontAwesome5 name='medal' size={useBreakpointValue([11, 13, 15])}
                              color={colorMode === 'light' ? colors['light']['contrastMid'] : colors['dark']['contrastMid']}/>
            </Flex>
        </Flex>
    );
};

export default CurrentRoundIndicator;