import React, {useEffect, useRef} from 'react';
import {JoinedPlayerType} from '../../api/types';
import {Box, ColorMode, Flex, Heading, HStack, Text, useBreakpointValue} from 'native-base';
import {View} from 'react-native';

interface FinalPlayerListItemProps {
    playerInfo: JoinedPlayerType,
    currentUserId: string,
    index: number,
    colorMode: ColorMode,
    colors: any,
    setPlayerItemPosY: (posY: number) => void;
}

const FinalPlayerListItem = ({
                                 playerInfo,
                                 currentUserId,
                                 index,
                                 colorMode,
                                 colors,
                                 setPlayerItemPosY
                             }: FinalPlayerListItemProps) => {

    //helper
    const isPlayerUser = playerInfo.userUid === currentUserId;
    const bg = isPlayerUser ? (colorMode === 'light' ? 'light.layoutShade' : 'dark.layoutShade') : 'transparent';
    const lowContrastContent = colorMode === 'light' ? (isPlayerUser ? 'light.contrastLow' : 'light.bg') : (isPlayerUser ? 'dark.contrastLow' : 'dark.bg');

    const isScreenBig = useBreakpointValue({base: false, md: true});

    //reference setup - used for the scroll to player feature
    const elementRef = useRef<View>();
    useEffect(() => {
        elementRef.current?.measure((fx, fy, width, height, px, py) => {
            //if the current rendered item is the player item - set the playerItemPosY in leagueScreen to scrollTo
            if (isPlayerUser)
                setPlayerItemPosY(py);
        });
    }, [elementRef]);

    return (
        <Flex direction='row' alignItems='stretch' justifyContent='space-between' rounded='15px' flexGrow='1'
              bg={bg} ref={elementRef}
              p={['4', '5', '6']} my='-2px'>
            <HStack alignItems='center' space='3'>
                <Heading fontSize={['22px', '26px', '32px']} minW='6' lineHeight='1em' mb='-5px'
                         color={lowContrastContent}>
                    {index + 1}
                </Heading>
                <Box h='80%' w='2px' rounded='full'
                     bg={lowContrastContent}/>
            </HStack>
            <HStack px='4' flexShrink='1' mr='auto'>
                <Text color={colorMode === 'light' ? 'light.contrast' : 'dark.contrast'} textAlign='left'
                      isTruncated={true} fontSize='xl'>
                    {playerInfo.playerName}
                    {/*A very very very vlong luasdflksjdlfkjsdlfjsdlkfjlk*/}
                </Text>
            </HStack>
            <HStack alignItems='stretch' space='3'>
                <HStack alignItems='center' space='3' w={isScreenBig ? '112px' : '85px'}>
                    <Box h='80%' w='2px' rounded='full'
                         bg={lowContrastContent}/>
                    <Text lineHeight='1em' isTruncated={true} fontSize='xl'
                          color={colorMode === 'light' ? 'light.contrast' : 'dark.contrast'}>
                        {playerInfo.currentScore}
                    </Text>
                </HStack>
            </HStack>
        </Flex>
    );
};

export default FinalPlayerListItem;