import {JoinedPlayerType, PlayerPerformanceType, PlayerSelectionItemType, PlayerSelectionType} from '../../types';
import {collection, getDocs, query} from 'firebase/firestore';
import {fb_firestore} from '../../../../../../App';

export const fetchPlayersForLeague = async (leagueId: string, userUid: string | null): Promise<JoinedPlayerType[]> => {
    const players: JoinedPlayerType[] = [];
    const playersQ = query(collection(fb_firestore, '/leagues/' + leagueId + '/players'));
    const playersSnapshot = await getDocs(playersQ);

    if (playersSnapshot.docs.length > 0) {
        await Promise.all(playersSnapshot.docs.map(async (player) => {

            //check performance data
            const playerPerformance: PlayerPerformanceType[] = [];
            //get player performance data
            const playersPerformanceQuery = query(collection(fb_firestore, `/leagues/${leagueId}/players/${player.id}/performance`));
            const playersPerformanceSnapshot = await getDocs(playersPerformanceQuery);

            playersPerformanceSnapshot.docs.forEach(performanceDoc => {
                playerPerformance.push({
                    roundId: performanceDoc.id,
                    SCORE: performanceDoc.data().SCORE
                });
            });

            //check selection data only for current player (all other players selections is only loaded upon request to reduce database calls)
            let playerSelection: PlayerSelectionType[] = [];
            if (userUid !== null && userUid === player.id) {
                //get the player selection
                const q = query(collection(fb_firestore, `leagues/${leagueId}/players/${player.id}/selection`));
                const snapshot = await getDocs(q);

                snapshot.forEach((doc) => {
                    // console.log(doc.id);
                    // console.log(doc.data());
                    const selection: PlayerSelectionItemType[] = [];
                    Object.entries(doc.data()).forEach(([key, value]: [string, string]) => {
                        selection.push({
                            statisticId: key,
                            athleteId: value
                        });
                    });
                    playerSelection.push({
                        roundId: doc.id,
                        selection: selection
                    });
                });
            }

            //check auto selection
            let autoSelection: PlayerSelectionType[] = [];
            if (userUid !== null && userUid === player.id) {
                //get the player auto selection
                const q = query(collection(fb_firestore, `leagues/${leagueId}/players/${player.id}/autoSelection`));
                const snapshot = await getDocs(q);

                snapshot.forEach((doc) => {
                    // console.log(doc.id);
                    // console.log(doc.data());
                    const selection: PlayerSelectionItemType[] = [];
                    Object.entries(doc.data()).forEach(([key, value]: [string, string]) => {
                        selection.push({
                            statisticId: key,
                            athleteId: value
                        });
                    });
                    autoSelection.push({
                        roundId: doc.id,
                        selection: selection
                    });
                });
            }

            //check cancel selection
            let cancelledSelection: PlayerSelectionType[] = [];
            if (userUid !== null && userUid === player.id) {
                //get the player cancel selection
                const q = query(collection(fb_firestore, `leagues/${leagueId}/players/${player.id}/cancelledSelection`));
                const snapshot = await getDocs(q);

                snapshot.forEach((doc) => {
                    // console.log(doc.id);
                    // console.log(doc.data());
                    const selection: PlayerSelectionItemType[] = [];
                    Object.entries(doc.data()).forEach(([key, value]: [string, string]) => {
                        selection.push({
                            statisticId: key,
                            athleteId: value
                        });
                    });
                    cancelledSelection.push({
                        roundId: doc.id,
                        selection: selection
                    });
                });
            }

            const playerData = player.data();
            players.push({
                userUid: player.id,
                playerName: playerData.playerName,
                currentScore: playerData.currentScore ?? 0,
                performance: playerPerformance,
                selection: playerSelection,
                autoSelection: autoSelection,
                cancelledSelection: cancelledSelection,
            });
        }));
    }

    //sort players
    players.sort((a, b) => {
        if (a.currentScore > b.currentScore) {
            return -1;
        }
        if (a.currentScore < b.currentScore) {
            return 1;
        }
        return 0;
    });

    return players;
};