import React, {MutableRefObject, PropsWithChildren, useEffect, useRef, useState} from 'react';
import {Box, ColorMode, Factory, Flex, Heading, HStack, Link, Text} from 'native-base';
import {useAppSelector} from '../../../../../app/redux/hooks';
import {getLeagueById} from '../../api/leagueSlice';
import {JoinedPlayerType} from '../../api/types';
import FinalPlayerListItem from './FinalPlayerListItem';
import {FlatList, ScrollView, useWindowDimensions} from 'react-native';
import Polygon from './helper/FinalRankingPolygon';
import LogoSmall from '../../../../../components/branding/Logo-Small';

interface FinalRankingListProps {
    leagueId: string,
    colorMode: ColorMode,
    colors: any,
    scrollViewRef: MutableRefObject<ScrollView | undefined>,
}

const FinalRankingList = ({leagueId, colorMode, colors, scrollViewRef}: FinalRankingListProps) => {

    //setup list
    const leagueInfo = useAppSelector(state => getLeagueById(state, leagueId));
    const currentUser = useAppSelector(state => state.auth.user);
    const [topPlayers, setTopPlayers] = useState<JoinedPlayerType[]>([]);
    const [players, setPlayers] = useState<JoinedPlayerType[]>([]);

    const dimensions = useWindowDimensions();
    const isScreenBig = dimensions.width >= 768;

    const currentPlayerScrollPos = useRef(400);

    //--------------------------------------------------------------- startup
    useEffect(() => {
        if (leagueInfo && currentUser && leagueInfo.players !== undefined) {

            //safety sort
            const allPlayers = [...leagueInfo.players];
            allPlayers.sort((a, b) => {
                if (a.currentScore > b.currentScore) {
                    return -1;
                }
                if (a.currentScore < b.currentScore) {
                    return 1;
                }
                return 0;
            });

            const topPlayers: JoinedPlayerType[] = [];
            for (let i = 0; i < 3; i++) {
                if (allPlayers.length > 0)
                    topPlayers.push(allPlayers.shift() ?? null as unknown as JoinedPlayerType);
            }

            setTopPlayers(topPlayers);
            setPlayers(allPlayers);
        }
    }, [leagueInfo, currentUser]);

    //-------------------------------------------------------------------- callbacks
    //callback from player list player item - used for scrollTo feature
    const setCurrentPlayerItemPosY = (posY: number) => {
        currentPlayerScrollPos.current = posY;
    };

    const scrollToPlayer = () => {
        // console.log(`item pos 0 ${currentPlayerScrollPos.current}`);
        scrollViewRef.current?.scrollTo({x: 0, y: currentPlayerScrollPos.current - 200, animated: true});
    };

    //-------------------------------------------------------------------- render
    if (leagueInfo === undefined || players === undefined || (players.length === 0 && topPlayers.length === 0)) {
        return (
            <Text color={colorMode === 'light' ? 'light.contrastLow' : 'dark.contrastLow'} mx={'auto'}>
                No players available
            </Text>
        );
    }

    const renderPlayerItem = ({playerInfo, index}: { playerInfo: JoinedPlayerType, index: number }) => {
        return (
            <FinalPlayerListItem
                playerInfo={playerInfo}
                index={index}
                currentUserId={currentUser.uid}
                colorMode={colorMode}
                colors={colors}
                setPlayerItemPosY={setCurrentPlayerItemPosY}
            />
        );
    };

    const TopHeader = Factory(TopHeaderItem);

    return (
        <>
            {
                isScreenBig ?
                    <Flex direction={'row'} w='full' px={['4', '5', '6']} alignItems='flex-start'
                          mb='10' mt='6'>
                        <TopHeader playerName={topPlayers[1]?.playerName} totalPoints={topPlayers[1]?.currentScore}
                                   colorMode={colorMode} colors={colors} ranking={2} isScreenBig={isScreenBig}/>
                        <TopHeader playerName={topPlayers[0]?.playerName} totalPoints={topPlayers[0]?.currentScore}
                                   colorMode={colorMode} colors={colors} ranking={1} isScreenBig={isScreenBig}/>
                        <TopHeader playerName={topPlayers[2]?.playerName} totalPoints={topPlayers[2]?.currentScore}
                                   colorMode={colorMode} colors={colors} ranking={3} isScreenBig={isScreenBig}/>
                    </Flex>
                    :
                    <Flex direction={'column'} w='full' px={['4', '5', '6']} alignItems='center'
                          mb='16' mt='10'>
                        <TopHeader playerName={topPlayers[0]?.playerName} totalPoints={topPlayers[0]?.currentScore}
                                   colorMode={colorMode} colors={colors} ranking={1} isScreenBig={isScreenBig}/>
                        <HStack justifyContent='space-between' w={'full'}>
                            <TopHeader playerName={topPlayers[1]?.playerName} totalPoints={topPlayers[1]?.currentScore}
                                       colorMode={colorMode} colors={colors} ranking={2} isScreenBig={isScreenBig}/>

                            <TopHeader playerName={topPlayers[2]?.playerName} totalPoints={topPlayers[2]?.currentScore}
                                       colorMode={colorMode} colors={colors} ranking={3} isScreenBig={isScreenBig}/>
                        </HStack>
                    </Flex>
            }
            <FlatList
                data={players}
                renderItem={({item, index}) => renderPlayerItem({playerInfo: item, index: index + topPlayers.length})}
                keyExtractor={item => item.userUid}
                ItemSeparatorComponent={() => <Box h='2px' rounded='full' mx='4' zIndex='-1'
                                                   bg={colorMode === 'light' ? 'light.layoutShade' : 'dark.layoutShade'}/>}
                style={{width: '100%', overflow: 'visible'}}
                ListHeaderComponent={() =>
                    <PointInfoHeader colorMode={colorMode} isScreenBig={isScreenBig}
                                     scrollToPlayerItemCallback={scrollToPlayer}/>
                }
            />
        </>
    );
};

const TopHeaderItem = (props:
                           PropsWithChildren<{
                               playerName: string, totalPoints: number, ranking: number, colorMode: ColorMode, colors: any, isScreenBig: boolean
                           }>) => {

    const {
        playerName,
        totalPoints,
        colorMode,
        colors,
        ranking,
        isScreenBig,
    } = props;

    const topOne = ranking === 1;

    const name = playerName === undefined ? '-' : playerName;
    const points = totalPoints === undefined ? '-' : totalPoints;

    return (
        <Flex flexDirection='column' w={isScreenBig ? (topOne ? '40%' : '30%') : (topOne ? '80%' : '50%')}
              mt={topOne ? '0' : '11%'} px='4' alignItems='center'>
            <Box position='relative' w={'100%'}>
                <Polygon
                    iconcolor={colorMode === 'light' ? colors['light']['layoutShade'] : colors['dark']['layoutShade']}/>
                <Flex position='absolute' top='0' bottom='0' left='0' right='0' justifyContent='center'>
                    <Heading lineHeight='1em' mb='-5'
                             fontSize={topOne ? ['8xl', '9xl', '162px'] : ['6xl', '7xl', '100px']}
                             color={colorMode === 'light' ? (topOne ? 'light.contrast' : 'light.contrastMid') : (topOne ? 'dark.contrast' : 'dark.contrastMid')}>
                        {ranking}
                    </Heading>
                </Flex>
            </Box>
            <Text color={colorMode === 'light' ? 'light.contrast' : 'dark.contrast'} mt='4' mb='2'
                  fontSize={topOne ? ['2xl', '3xl', '4xl'] : ['md', 'lg', 'xl']} isTruncated={true}>
                {name}
            </Text>
            <Box w='30%' h='2px' bg='primary' mb='2'/>
            <Text isTruncated={true} color={colorMode === 'light' ? 'light.contrast' : 'dark.contrast'}
                  fontSize={topOne ? ['xl', '2xl', '3xl'] : ['sm', 'md', 'lg']}>
                {points}
            </Text>
        </Flex>
    );
};

const PointInfoHeader = ({
                             colorMode,
                             isScreenBig,
                             scrollToPlayerItemCallback
                         }: { colorMode: ColorMode, isScreenBig: boolean, scrollToPlayerItemCallback: () => void }) => {

    return (
        <HStack justifyContent='space-between' px={['4', '5', '6']} space='3' alignItems='end'>
            <Link onPress={scrollToPlayerItemCallback} alignSelf={'center'} px={5} py={1} rounded={'full'}
                  bg={colorMode === 'light' ? 'light.layoutShade' : 'dark.layoutShade'}>
                <Text fontSize='sm' textAlign='left'
                      color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                    Find me
                </Text>
            </Link>
            <HStack alignItems='baseline' w={isScreenBig ? '112px' : '85px'}>
                <Text fontSize='sm' textAlign='right'
                      color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                    Total Points
                </Text>
            </HStack>
        </HStack>
    );
};

export default FinalRankingList;