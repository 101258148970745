import {createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import {RootState} from 'app/redux/store';
import {AthleteDataType, possibleAthleteInfoStatus} from './types';
import {getAthleteInfo} from './functions/getAthleteInfo';
import {uploadAthleteSelection} from './functions/uploadAthleteSelection';

const athleteAdapter = createEntityAdapter<AthleteDataType>({
    selectId: (athlete) => athlete.id,
});

const initialState = athleteAdapter.getInitialState({
    fetchStatus: possibleAthleteInfoStatus.idle,
    fetchError: '',
    uploadStatus: possibleAthleteInfoStatus.idle,
    uploadError: '',
});

const athleteInfoSlice = createSlice({
    name: 'athletes',
    initialState,
    reducers: {
        resetUploadStatus: (state) => {
            state.uploadStatus = possibleAthleteInfoStatus.idle;
            state.uploadError = '';
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAthleteInfo.pending, (state) => {
                state.fetchStatus = possibleAthleteInfoStatus.loading;
            })
            .addCase(getAthleteInfo.fulfilled, (state, action) => {
                state.fetchStatus = possibleAthleteInfoStatus.succeeded;
                athleteAdapter.upsertMany(state, action.payload);
            })
            .addCase(getAthleteInfo.rejected, (state, action) => {
                state.fetchStatus = possibleAthleteInfoStatus.failed;
                state.fetchError = String(action.payload);
            });
        builder
            .addCase(uploadAthleteSelection.pending, (state) => {
                state.uploadStatus = possibleAthleteInfoStatus.loading;
            })
            .addCase(uploadAthleteSelection.fulfilled, (state) => {
                state.uploadStatus = possibleAthleteInfoStatus.succeeded;
            })
            .addCase(uploadAthleteSelection.rejected, (state, action) => {
                state.uploadStatus = possibleAthleteInfoStatus.failed;
                state.uploadError = String(action.payload);
            });
    }
});

export const {
    selectAll: getAllAthletes,
    selectById: getAthleteById,
    selectIds: selectAthleteIds,
} = athleteAdapter.getSelectors<RootState>(state => state.athletes);

export const {
    resetUploadStatus,
} = athleteInfoSlice.actions;

export default athleteInfoSlice.reducer;