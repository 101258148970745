import React, {PropsWithChildren} from 'react';
import {Text, useColorMode} from 'native-base';

const VersionIndicator = (props: PropsWithChildren<any>) => {

    const {colorMode} = useColorMode();

    return (
        <Text fontSize='xs' fontFamily='body'
              color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrast'} {...props}>
            v2.3.1
        </Text>
    );
};

export default VersionIndicator;