import React, {useEffect, useRef} from 'react';
import {Box, ColorMode, Flex, HStack, Pressable, Text, useColorMode, useTheme} from 'native-base';
import {possibleRoundStates, RoundDataType} from '../../tournament/api/types';
import {FlatList} from 'react-native';
import {convertRoundName} from 'app/helper/nameConversionHelper';
import {AntDesign, FontAwesome5} from '@expo/vector-icons';
import {MaterialIcons} from '@expo/vector-icons';

interface LeagueRoundPickerProps {
    rounds: RoundDataType[];
    onPress: (round: RoundDataType) => void;
    onWinnerPress: () => void;
    selectedRound: RoundDataType | null;
    isScreenBig: boolean;
    isTournamentComplete: boolean;
}

const LeagueRoundPicker = ({
                               rounds,
                               onPress,
                               onWinnerPress,
                               selectedRound,
                               isScreenBig,
                               isTournamentComplete,
                           }: LeagueRoundPickerProps) => {

    //state
    const {colorMode} = useColorMode();
    const {colors} = useTheme();

    const scrollRef = useRef<FlatList<RoundDataType>>();

    //initial scroll
    // useEffect(() => {
    //     if (selectedRound && scrollRef.current) {
    //         let index = rounds.indexOf(selectedRound);
    //         if (index !== -1) {
    //             scrollRef.current.scrollToIndex({index: index, viewOffset: 120});
    //             console.log('scroll to: ' + index);
    //         }
    //     }
    // }, [selectedRound]);

    useEffect(
        () => {
            let timer: NodeJS.Timeout;
            if (selectedRound && scrollRef.current) {
                let index = rounds.indexOf(selectedRound);
                timer = setTimeout(() => {
                    scrollRef.current?.scrollToIndex({index: index, viewOffset: 120});
                }, 100);
            }
            // this will clear Timeout
            // when component unmount like in willComponentUnmount
            // and show will not change to true
            return () => {
                if (timer !== null)
                    clearTimeout(timer);
            };
        },
        [selectedRound]);

    //------------------------------------------------------ callbacks
    const roundSelected = (round: RoundDataType) => {
        onPress(round);
    };

    const selectPreviousRound = () => {
        if (selectedRound) {
            const index = rounds.findIndex(round => round.id === selectedRound.id);
            if (index > 0) {
                roundSelected(rounds[index - 1]);
            }
        }
    };

    const selectNextRound = () => {
        if (selectedRound) {
            const index = rounds.findIndex(r => r.id === selectedRound.id);
            const filRounds = rounds.filter(r => r.status !== possibleRoundStates.upcoming);
            if (index < filRounds.length - 1) {
                roundSelected(filRounds[index + 1]);
            }
        }
    };

    //------------------------------------------------------ render
    if (selectedRound === null || rounds.length === 0) {
        return <></>;
    }

    return (
        <Flex direction={isScreenBig ? 'row' : 'column-reverse'} mb='5' position='sticky'>
            <FlatList
                data={rounds}
                ref={(ref) => {
                    scrollRef.current = ref ?? undefined;
                }}
                renderItem={({item}) => RoundSelectionButton({
                    item,
                    colorMode,
                    colors,
                    onPress: roundSelected,
                    selectedRound
                })}
                keyExtractor={(item) => item.id}
                horizontal={true}
                extraData={selectedRound}
                //@ts-ignore
                style={{borderRadius: '15px', overflow: 'auto', flexShrink: 0, flexGrow: 0}}
                ItemSeparatorComponent={() => <Box width={2}/>}
                showsHorizontalScrollIndicator={false}
                initialScrollIndex={0}
                onScrollToIndexFailed={info => {
                    const wait = new Promise(resolve => setTimeout(resolve, 500));
                    wait.then(() => {
                        scrollRef.current?.scrollToIndex({index: info.index, animated: true});
                    });
                }}
            />
            <HStack justifyContent='space-between' flexGrow='1' alignItems='center' ml={isScreenBig ? '5' : '0'}>
                <HStack alignItems='center'>
                    {
                        isTournamentComplete ?
                            <>
                                <>
                                    {
                                        isScreenBig ?
                                            <Text mr='5'
                                                  color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                                                -
                                            </Text>
                                            : <></>
                                    }
                                </>
                                <Pressable onPress={onWinnerPress} px='6' py='3' rounded='full' _hover={{bg: 'primary'}}
                                           _pressed={{bg: 'primary'}}
                                           bg={colorMode === 'light' ? 'light.contrast' : 'dark.layout'}>
                                    {
                                        ({isHovered, isPressed}) => (
                                            <FontAwesome5 name='medal' size={20}
                                                          color={(isHovered || isPressed) ? (colorMode === 'light' ? colors['light']['contrast'] : colors['dark']['contrast']) : colors['primary']}/>
                                        )
                                    }
                                </Pressable>
                            </>
                            : <></>
                    }
                </HStack>
                <Flex direction='row' pl='5' pt='2' pb={isScreenBig ? '5' : '2'} alignSelf='flex-end'>
                    <Pressable onPress={selectPreviousRound} mr='2' rounded='lg' display='flex' justifyContent='center'
                               bg={colorMode === 'light' ? 'light.contrastLow' : 'dark.contrastLow'} px='2' py='2'>
                        <AntDesign name='caretleft' size={16}
                                   color={colorMode === 'light' ? colors['light']['contrastPartner'] : colors['dark']['contrastPartner']}/>
                    </Pressable>
                    <Pressable onPress={selectNextRound} rounded='lg' display='flex' justifyContent='center'
                               bg={colorMode === 'light' ? 'light.contrastLow' : 'dark.contrastLow'} px='2' py='2'>
                        <AntDesign name='caretright' size={16}
                                   color={colorMode === 'light' ? colors['light']['contrastPartner'] : colors['dark']['contrastPartner']}/>
                    </Pressable>
                </Flex>
            </HStack>
        </Flex>
    );
};

interface RoundSelectionButtonProps {
    item: RoundDataType;
    colorMode: ColorMode;
    colors: any,
    onPress: (round: RoundDataType) => void;
    selectedRound: RoundDataType;
}

const RoundSelectionButton = ({item, colorMode, colors, onPress, selectedRound}: RoundSelectionButtonProps) => {

    const isSelected = selectedRound && selectedRound.id === item.id;

    let bgColor: string = isSelected ?
        colorMode === 'light' ? 'light.layout' : 'dark.layout'
        : colorMode === 'light' ? 'light.layoutShade' : 'dark.layoutShade';
    let textColor: string = isSelected ?
        colorMode === 'light' ? 'light.contrast' : 'dark.contrast'
        : colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid';
    let borderWidth: string = '2';
    let borderColor: string = 'transparent';

    if (item.status === possibleRoundStates.upcoming)
        bgColor = 'transparent';

    return (
        <Flex>
            <Pressable onPress={() => onPress(item)} disabled={item.status === possibleRoundStates.upcoming}>
                {
                    ({isHovered, isPressed}) => (
                        <Box bg={(isHovered || isPressed) && !isSelected ? 'primary' : bgColor}
                             px='6' py='2' mt='3' rounded='full' borderWidth={borderWidth} borderColor={borderColor}>
                            <Text fontSize={['md', 'lg', 'xl']} my='auto'
                                  color={(isHovered || isPressed) && !isSelected ? 'primaryPartner' : textColor}>
                                {convertRoundName(item.id)}
                            </Text>
                            <RoundSelectionBadge status={item.status} colorMode={colorMode} colors={colors}
                                                 isSelected={isSelected}/>
                        </Box>
                    )
                }
            </Pressable>
            {
                isSelected ? (
                    <Box h='2px' w='70%' mx='auto' bg={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}
                         mt='2' rounded='full'/>
                ) : null
            }
        </Flex>
    );
};

interface RoundSelectionBadgeProps {
    status: possibleRoundStates;
    colorMode: ColorMode;
    colors: any;
    isSelected: boolean,
}

const RoundSelectionBadge = ({status, colorMode, colors, isSelected}: RoundSelectionBadgeProps) => {

    let bgColor = colorMode === 'light' ? (isSelected ? 'light.contrast' : 'light.layout') : (isSelected ? 'dark.contrast' : 'dark.layout');
    let itemColor = colorMode === 'light' ? (isSelected ? colors['light']['contrastPartner'] : colors['light']['contrastMid']) : (isSelected ? colors['dark']['contrastPartner'] : colors['dark']['contrastMid']);
    let statusElement = undefined;

    //set text
    switch (status) {
        case possibleRoundStates.open:
            statusElement = (
                <Flex py='2px' px='3px'>
                    <MaterialIcons name='edit' size={12} color={itemColor}/>
                </Flex>
            );
            break;
        case possibleRoundStates.playingActive:
            itemColor = colorMode === 'light' ? (isSelected ? 'dark.error' : 'light.error') : (isSelected ? 'light.error' : 'dark.error');
            statusElement = (
                <Flex direction='row' alignItems='center' py='0.5' px='2'>
                    <Box rounded='full' w='5px' h='5px' mr='1' bg={itemColor}/>
                    <Text fontSize='12px' color={itemColor}>
                        Live
                    </Text>
                </Flex>
            );
            break;
        case possibleRoundStates.completed:
            statusElement = (
                <Flex py='2px' px='3px'>
                    <AntDesign name='check' size={12} color={itemColor}/>
                </Flex>
            );
            break;
    }

    if (statusElement === undefined)
        return <></>;

    return (
        <Flex position='absolute' bg={bgColor} top='-10px' right='-2px' rounded='full'>
            {statusElement}
        </Flex>
    );
};

export default LeagueRoundPicker;