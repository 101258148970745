import React from 'react';
import LeagueInfoComponent, {possibleUserToLeagueStatus} from './LeagueInfoComponent';
import {FlatList} from 'react-native';
import {Box, ColorMode, Flex, Text} from 'native-base';
import {LeagueDataType} from '../../../leagues/api/types';
import {possibleLeaguesListDisplayStates} from '../types';
import {TournamentNavigationProp} from '../../../types';
import {possibleRoundIds} from '../../api/types';

interface LeaguesListProps {
    leagues: LeagueDataType[];
    leagueDisplayState: possibleLeaguesListDisplayStates;
    tournamentId: string;
    currentTournamentRound: possibleRoundIds,
    navigation: TournamentNavigationProp;
    colorMode: ColorMode;
    colors: any;
    emptyMessage: string;
}

const LeaguesList = ({
                         leagues,
                         leagueDisplayState,
                         tournamentId,
                         currentTournamentRound,
                         navigation,
                         colorMode,
                         colors,
                         emptyMessage
                     }: LeaguesListProps) => {

    //render individual league box
    const renderLeagueBox = ({item}: { item: LeagueDataType }) => {

        //check round state
        let currentLeagueStatus: possibleUserToLeagueStatus;

        if (leagueDisplayState === possibleLeaguesListDisplayStates.joined)
            currentLeagueStatus = possibleUserToLeagueStatus.leagueAlreadyJoined;
        else if (currentTournamentRound === possibleRoundIds.upcoming || item.canJoinLater)
            currentLeagueStatus = possibleUserToLeagueStatus.leagueAvailable;
        else
            currentLeagueStatus = possibleUserToLeagueStatus.leagueLocked;

        return (
            <LeagueInfoComponent leagueInfo={item} navigation={navigation} colorMode={colorMode}
                                 userToLeagueStatus={currentLeagueStatus} colors={colors} tournamentId={tournamentId}/>
        );
    };

    return (
        <FlatList
            data={leagues}
            renderItem={({item}) => renderLeagueBox({item})}
            keyExtractor={item => item.id}
            ItemSeparatorComponent={SeparatorComponent}
            //@ts-ignore
            style={{flexGrow: 0, overflowX: 'auto', width: '100%'}}
            contentContainerStyle={{width: '100%', paddingVertical: '24px'}}
            horizontal={true}
            ListEmptyComponent={EmptyLeagueListComponent({colorMode, emptyMessage})}
        />
    );
};

const SeparatorComponent = () => {
    return (
        <Box width='8' bg='transparent'/>
    );
};

const EmptyLeagueListComponent = ({colorMode, emptyMessage}: { colorMode: ColorMode, emptyMessage: string }) => {
    return (
        <Flex py='20' flexGrow='1' justifyContent='center'>
            <Text mx='auto' fontSize={['sm', 'md', 'lg']}
                  color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                {emptyMessage}
            </Text>
        </Flex>
    );
};

export default LeaguesList;