//react
import * as React from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
//style
import AppBackground from 'components/essential/AppBackground';
import {StatusBarStyled} from 'components/essential/StatusBarStyled';
//fonts
import {useFonts} from 'expo-font';
import {Teko_700Bold} from '@expo-google-fonts/teko';
import {Inter_600SemiBold} from '@expo-google-fonts/inter';
//navigation
import {NavigationContainer} from '@react-navigation/native';
//redux
import {Provider} from 'react-redux';
import {store} from 'app/redux/store';
import {useAppDispatch, useAppSelector} from 'app/redux/hooks';
//firebase
import {initializeApp} from 'firebase/app';

// import {onAuthStateChanged, initializeAuth, connectAuthEmulator} from 'firebase/auth';
import {onAuthStateChanged, initializeAuth} from 'firebase/auth';
// import {getFirestore, connectFirestoreEmulator} from 'firebase/firestore';
import {getFirestore} from 'firebase/firestore';

import {getReactNativePersistence} from 'firebase/auth/react-native';
import firebaseConfig from 'app/firebase/config';
//custom logic
import {userLoggedIn, userLoggedOut} from './features/authentication/authSlice';
import {AppThemeProvider, PublicNavigator, MainNavigator} from './features';
import LoadingScreen from './components/branding/LoadingScreen';

//------------------------------------------------------------------------- Firebase
// Initialize Firebase
export const fb_app = initializeApp(firebaseConfig);
export const fb_auth = initializeAuth(fb_app, {persistence: getReactNativePersistence(AsyncStorage)});
export const fb_firestore = getFirestore();
// export const fb_functions = getFunctions(fb_app);

//local emulators
// connectAuthEmulator(fb_auth, 'http://localhost:9099');
// connectFirestoreEmulator(fb_firestore, 'localhost', 8030);
// connectFunctionsEmulator(fb_functions, 'localhost', 5001);

/*
start
firebase emulators:start --import=./emulatorFirestoreData --export-on-exit

export
firebase emulators:export ./emulatorFirestoreData
 */

//------------------------------------------------------------------------- Main App
const AppMain = () => {

    //state
    const isUserLoggedIn = useAppSelector(state => state.auth.userLoggedIn);
    const user = useAppSelector(state => state.auth.user);

    //redux
    const dispatch = useAppDispatch();

    //firebase auth event
    onAuthStateChanged(fb_auth, (user) => {
        if (user) {
            fb_auth.currentUser?.getIdToken(true);
            dispatch(userLoggedIn({uid: user.uid, emailVerified: user.emailVerified, userName: user.displayName}));
        } else {
            dispatch(userLoggedOut());
        }
    });//'<3'

    //if user not logged in show public stack
    if (!isUserLoggedIn || !user.emailVerified) {
        return <PublicNavigator/>;
    }

    //if user logged in but player not created
    //show player building stack

    //if user logged in and player is created
    //show dashboard
    return <MainNavigator/>;
};

//------------------------------------------------------------------------- App Wrapper for Expo
export default function App() {

    //fonts
    let [fontsLoaded] = useFonts({
        Teko_700Bold, Inter_600SemiBold
    });

    //--------------------------------------------- Loading
    if (!fontsLoaded) {
        return (
            <AppThemeProvider>
                <LoadingScreen/>
            </AppThemeProvider>
        );
    }

    //--------------------------------------------- App
    return (
        <AppThemeProvider>
            <NavigationContainer documentTitle={{
                formatter: (options, route) =>
                    `DTT - ${options?.title ?? route?.name}`
            }}>
                <Provider store={store}>
                    <AppBackground>
                        <AppMain/>
                        <StatusBarStyled/>
                    </AppBackground>
                </Provider>
            </NavigationContainer>
        </AppThemeProvider>
    );
}