import React from 'react';
import {ColorMode, Pressable, Text} from 'native-base';

interface JoinLeagueComponentProps {
    joinLeagueCallback: () => void;
    colorMode: ColorMode,
    colors: any,
}

const JoinLeagueComponent = ({joinLeagueCallback, colorMode, colors}: JoinLeagueComponentProps) => {

    return (
        <Pressable flexGrow={1} bg={colorMode === 'light' ? 'light.contrast' : 'dark.contrast'} zIndex={-1}
                   mb={['-30px', '-40px', '-50px']} pb={['40px', '55px', '70px']} pt={['10px', '18px', '25px']}
                   roundedTop={['30px', '40px', '50px']} _pressed={{bg: 'primary'}} _hover={{bg: 'primary'}}
                   onPress={joinLeagueCallback}>
            {
                ({isPressed, isHovered}) => (
                    <Text
                        color={isPressed || isHovered ? 'primaryPartner' : colorMode === 'light' ? 'light.contrastPartner' : 'dark.contrastPartner'}>
                        Click here to join
                    </Text>
                )
            }
        </Pressable>
    );

    // return (
    //     <Flex direction={'column'} mb={10} alignItems='flex-start'>
    //         <HStack alignItems='center' space={3} mb={3}>
    //             <FontAwesome name='exclamation-circle' size={24}
    //                          color={colorMode === 'light' ? colors['light']['warning'] : colors['dark']['warning']}/>
    //             <Text>
    //                 You are not part of this league.
    //             </Text>
    //         </HStack>
    //         <Button onPress={joinLeagueCallback}>
    //             Join league
    //         </Button>
    //     </Flex>
    // );
};

export default JoinLeagueComponent;