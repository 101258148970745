import React, {useEffect, useRef, useState} from 'react';
import AppBackground from 'components/essential/AppBackground';
import {Box, Heading, Spinner, useBreakpointValue, useColorMode, useTheme} from 'native-base';
import {possibleTournamentScreens, VPlayerBuildingNavigationProp, VPlayerBuildingScreenProps} from '../../types';
import {getLeagueById} from '../api/leagueSlice';
import {useAppDispatch, useAppSelector} from 'app/redux/hooks';
import {getTournamentById} from '../../allTournaments/api/tournamentInfoSlice';
import {store} from 'app/redux/store';
import {getTournamentDataById} from '../../tournament/api/tournamentDataSlice';
import StatisticSelectionComponent from './components/StatisticSelectionComponent';
import {StatisticType} from '../../statistics/api/types';
import {GetPlayerAndStatistics} from './helper/GetPlayerAndStatistics';
import {GetAthleteMatchesForRound} from './helper/GetAthleteMatchesForRound';
import {StatisticSelectionMatchType, StatisticSelectionType} from './types';
import {possibleAthleteInfoStatus} from '../../athletes/api/types';
import {uploadAthleteSelection} from '../../athletes/api/functions/uploadAthleteSelection';
import {Alert, FlatList, Platform} from 'react-native';
import ErrorScreen from '../../../main/helper/ErrorScreen';
import {ListInfoHeader} from './components/ListInfoHeader';
import hybridAlert from '../../../../app/helper/hybridAlert';
import {resetUploadStatus} from '../../athletes/api/athleteSlice';
import {getPlayerSelection, setUnsavedChanges} from './redux/playerBuildingSlice';
import {UploadConfirmComponent} from './components/UploadConfirmComponent';

const VPlayerBuildingScreen = ({navigation, route}: VPlayerBuildingScreenProps) => {

    const {playerInfo, leagueId, tournamentId, selectedRound} = route.params;

    //temp
    // const leagueId = 'zJcMEq6tUOnLxdizHPGG';
    // const tournamentId = 'monte-carlo-2022';
    // const selectedRound: RoundDataType = {
    //     id: 'r4',
    //     startDate: '2020-06-01T00:00:00.000Z',
    //     endDate: '2020-06-01T00:00:00.000Z',
    //     status: possibleRoundStates.open,
    //     matches: []
    // };
    // // @ts-ignore
    // const leagues = useAppSelector(state => selectLeaguesByTournament(state, tournamentId));
    // const leagueApiStatus = useAppSelector(state => state.leagues.status);
    // useEffect(() => {
    //     if (leagues.length <= 0 && leagueApiStatus !== possibleLeagueApiStatus.loading)
    //         dispatch(getAllLeaguesForTournamentId(tournamentId));
    //     if (!tournamentData)
    //         dispatch(fetchTournamentData(tournamentId));
    // }, []);
    // let playerInfo: JoinedPlayerType = {
    //     userUid: 'Anj09WtSTYBsocqixTDgUmRyKfXZ',
    //     playerName: 'MisterX',
    //     selection: [],
    //     performance: [],
    //     currentScore: 0
    // };
    //end temp

    //helper
    const {colorMode} = useColorMode();
    const {colors} = useTheme();
    const dispatch = useAppDispatch();
    //ui
    const listPaddingHorizontal = useBreakpointValue(['16px', '40px', '64px']);
    const listPaddingVertical = useBreakpointValue(['32px', '40px', '64px']);

    //get league info
    //useAppSelector to re-render the league screen when the league info changes (i.e. when a player joins)
    const leagueInfo = useAppSelector(state => getLeagueById(state, leagueId));

    //get tournament info for displaying available rounds and players
    const tournamentInfo = getTournamentById(store.getState(), tournamentId);
    const tournamentData = useAppSelector(state => getTournamentDataById(state, tournamentId));

    //statistics
    const statisticsSelectionList = useAppSelector(state => getPlayerSelection(state));
    const [statisticsSelectionListError, setStatisticsSelectionListError] = useState<string>('');
    const [statisticsSelectionListLoading, setStatisticsSelectionListLoading] = useState<boolean>(true);
    const unsavedChanges = useAppSelector(state => state.playerBuilding.unsavedChanges);

    //matches / athletes
    const [matches, setMatches] = React.useState<StatisticSelectionMatchType[]>([]);
    const [matchesError, setMatchesError] = React.useState<string>('');
    const [matchesLoading, setMatchesLoading] = React.useState<boolean>(true);
    useEffect(() => {
        if (tournamentData) {
            //get matches for this round
            GetAthleteMatchesForRound({
                tournamentData,
                setMatchesError,
                setMatches,
                selectedRoundId: selectedRound.id
            });
            //set loading false
            setMatchesLoading(false);
        }
    }, [tournamentData]);

    //player selection
    useEffect(() => {
        //for checking when the first leagueInfo arrives in the Ui
        if (leagueInfo !== undefined && matches.length > 0 && statisticsSelectionListLoading) {
            //get statistics for this league
            GetPlayerAndStatistics({
                leagueInfo,
                userUid: playerInfo.userUid,
                roundId: selectedRound.id,
                matches,
                dispatch,
                setStatisticsSelectionListError
            });
            //set loading false
            setStatisticsSelectionListLoading(false);
            //reset unsavedChanges
            dispatch(setUnsavedChanges(false));
        }
    }, [leagueInfo, matches]);

    //back prevent setup
    useEffect(() => {
        navigation.addListener('beforeRemove', (e) => {

            //get directly from store - sometimes the app selector is not updated yet coming from goBack()
            const currentlyUnsaved = store.getState().playerBuilding.unsavedChanges;

            if (!currentlyUnsaved)
                return;

            e.preventDefault();

            if (UnsavedChangesAlert())
                navigation.dispatch(e.data.action);
        });
    }, []);

    //confirm state
    const listRef = useRef<FlatList>(null);
    const [wasConfirmTried, setWasConfirmTried] = useState<boolean>(false);
    const uploadStatus = useAppSelector(state => state.athletes.uploadStatus);
    const uploadError = useAppSelector(state => state.athletes.uploadError);
    useEffect(() => {
        if (uploadStatus === possibleAthleteInfoStatus.succeeded) {
            UploadResponseAlert({
                navigation,
                title: 'Upload successful',
                description: 'Your selection has been uploaded successfully!'
            });
            dispatch(resetUploadStatus());
        }
        if (uploadStatus === possibleAthleteInfoStatus.failed) {
            UploadResponseAlert({
                navigation,
                title: 'Upload failed!',
                description: uploadError
            });
            dispatch(resetUploadStatus());
        }
    }, [uploadStatus]);

    //----------------------------------------------------------------------------------------------------- callbacks
    //upon pressing the help button for any statistic
    const helpButtonCallback = (statistic: StatisticType) => {
        navigation.push(possibleTournamentScreens.StatisticHelp, {statistic});
    };

    //upon pressing the selection for any statistic
    const selectionButtonCallback = (statisticSelection: StatisticSelectionType) => {
        navigation.push(possibleTournamentScreens.AthleteSelection, {
            matches,
            statisticSelection,
            isEntry: selectedRound.isEntry,
        });
    };

    //upon pressing the confirm button for all statistics
    const confirmButtonCallback = () => {
        setWasConfirmTried(true);
        if (statisticsSelectionList.every(statistic => statistic.selectedAthlete === null)) {
            NoAthleteSelectionAlert();
        } else if (statisticsSelectionList.every(selection => selection.selectedAthlete !== null)) {
            uploadSelection();
        } else {
            IncompleteUploadConfirmAlert();
            //scroll to first no set
            const firstNotSet = statisticsSelectionList.findIndex(selection => selection.selectedAthlete === null);
            if (firstNotSet !== -1)
                listRef.current?.scrollToIndex({index: firstNotSet, viewPosition: 0.2});
        }
    };

    const uploadSelection = () => {

        if (!unsavedChanges) {
            SelectionNotChangedAlert();
        }

        if (leagueInfo !== undefined && selectedRound !== undefined && statisticsSelectionList.length > 0 && playerInfo !== undefined && unsavedChanges) {
            dispatch(setUnsavedChanges(false));
            dispatch(uploadAthleteSelection({
                userUid: playerInfo.userUid,
                leagueInfo: leagueInfo,
                tournamentId: tournamentId,
                selectedRoundId: selectedRound.id,
                selectionList: statisticsSelectionList,
            }));
        }
    };

    //----------------------------------------------------------------------------------------------------- render

    //error
    if (matchesError !== '' || statisticsSelectionListError !== '') {
        console.log('error');
        console.log(matchesError);
        console.log(statisticsSelectionListError);
        return (
            <ErrorScreen errorMessage={matchesError + '\n' + statisticsSelectionListError}/>
        );
    }

    //loading
    if (matchesLoading || statisticsSelectionListLoading) {
        return (
            <AppBackground p={['4', '10', '16']} py={['8', '10', '16']}>
                <Spinner size='lg' color='primary'/>
            </AppBackground>
        );
    }

    //upload states
    if (uploadStatus === possibleAthleteInfoStatus.loading) {
        return (
            <AppBackground p={['4', '10', '16']} py={['8', '10', '16']}>
                <Heading fontSize='4xl' mb='8' color={colorMode === 'light' ? 'light.contrast' : 'dark.contrast'}>
                    Uploading your selection
                </Heading>
                <Spinner size='lg' color={colorMode === 'light' ? 'light.success' : 'dark.success'}/>
            </AppBackground>
        );
    }

    //state loaded
    return (
        <AppBackground flexShrink='1'>
            <FlatList
                keyExtractor={(item) => item.statistic.id}
                data={statisticsSelectionList}
                ref={listRef}
                renderItem={({item, index}) =>
                    <StatisticSelectionComponent
                        item={item} index={index}
                        helpButtonCallback={helpButtonCallback}
                        selectionButtonCallback={selectionButtonCallback}
                        colorMode={colorMode} colors={colors}
                        wasConfirmTried={wasConfirmTried}
                    />
                }
                style={{}}
                contentContainerStyle={{
                    maxWidth: '768px',
                    paddingHorizontal: listPaddingHorizontal,
                    paddingVertical: listPaddingVertical
                }}
                ItemSeparatorComponent={() => (
                    <Box h='10' w='full'/>
                )}
                ListHeaderComponent={() => <ListInfoHeader playerInfo={playerInfo} tournamentInfo={tournamentInfo}
                                                           selectedRound={selectedRound} colorMode={colorMode}/>}
                ListFooterComponent={() => <UploadConfirmComponent confirmButtonCallback={confirmButtonCallback}/>}
            />
        </AppBackground>
    );
};

const UploadResponseAlert = ({
                                 navigation,
                                 title,
                                 description
                             }: { navigation: VPlayerBuildingNavigationProp, title: string, description: string }) => {
    hybridAlert(
        title,
        description,
        [
            {
                text: 'Ok',
                onPress: () => {
                    if (navigation.canGoBack()) {
                        navigation.goBack();
                    }
                },
            },
        ],
        {cancelable: false},
    );
};

const NoAthleteSelectionAlert = () => {
    hybridAlert(
        'No selection',
        'Please select athletes for each statistic before you upload',
        [
            {
                text: 'Ok',
            },
        ],
        {cancelable: false},
    );
};

const IncompleteUploadConfirmAlert = () => {
    hybridAlert(
        'Incomplete selection',
        'Please select athletes for all statistics before you upload',
        [
            {
                text: 'Ok',
            },
        ],
        {cancelable: false},
    );
};

const SelectionNotChangedAlert = () => {
    hybridAlert(
        'Selection unchanged',
        'Your selection is already uploaded to the server.',
        [
            {
                text: 'Ok',
            },
        ],
        {cancelable: false},
    );
};

const UnsavedChangesAlert = () => {
    if (Platform.OS === 'web') {
        if (confirm('You have unsaved changes. Are you sure you want to leave?')) {
            return true;
        }
    } else {
        return Alert.alert(
            'You have unsaved changes',
            'Are you sure you want to leave?',
            [
                {
                    text: 'Yes',
                    onPress: () => true,
                },
                {
                    text: 'No',
                    onPress: () => false,
                },
            ],
            {cancelable: false},
        );
    }
};

export default VPlayerBuildingScreen;