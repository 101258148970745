import React from 'react';
import {JoinedPlayerType} from '../../api/types';
import {TournamentInfoDataType} from '../../../allTournaments/api/tournamentInfoSlice';
import {RoundDataType} from '../../../tournament/api/types';
import {Box, ColorMode, Heading, HStack, Link, Pressable, Text, VStack} from 'native-base';
import Badge from '../../../../../components/basic/Badge';
import {convertRoundName} from '../../../../../app/helper/nameConversionHelper';

interface ListInfoHeaderProps {
    playerInfo: JoinedPlayerType | null,
    tournamentInfo: TournamentInfoDataType | undefined,
    selectedRound: RoundDataType,
    colorMode: ColorMode,
    scrollToTotalPoints: () => void,
}

const ListInfoHeader = ({
                            playerInfo,
                            tournamentInfo,
                            selectedRound,
                            colorMode,
                            scrollToTotalPoints
                        }: ListInfoHeaderProps) => {

    const [moreInfoShown, setMoreInfoShown] = React.useState(false);

    return (
        <VStack>
            <VStack alignItems='flex-start' mb='10'>
                <Text textAlign='left' underline={true} mb={10}
                      color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                    Performance
                </Text>
                <Heading lineHeight='1em' mb='-10px' textAlign='left'>
                    {playerInfo?.playerName}
                </Heading>
                <Text textAlign='left' mb='2'>
                    {tournamentInfo?.name}
                </Text>
                <Badge
                    text={convertRoundName(selectedRound.id)}
                    variant='default'
                    colorMode={colorMode}
                />
                <VStack mt={6} alignItems={'flex-start'}>
                    <Text textAlign='left' fontSize={['sm', 'md', 'lg']}
                          color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                        This detailed overview displays the performance of your digital player for the selected round.
                    </Text>
                    {
                        !moreInfoShown ?
                            <Link _text={{
                                textAlign: 'left',
                                fontSize: ['2xs', 'xs', 'sm'],
                                mt: 2,
                                color: (colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid')
                            }} onPress={() => setMoreInfoShown(!moreInfoShown)}>
                                More info ...
                            </Link>
                            : <></>
                    }
                    {
                        moreInfoShown ?
                            <VStack>
                                <HStack alignItems='stretch' mt={2}>
                                    <Box w='2px' mr={2}
                                         bg={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}/>
                                    <Text textAlign='left' fontSize={['sm', 'md', 'lg']}
                                          color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                                        For all the statistics, you can view the performance of the tennis athlete
                                        you chose. The performance is combined with the multiplier to determine
                                        the digital points.
                                        {'\n'}
                                        {'\n'}
                                        At the bottom of the list you can see the total points you have earned for this
                                        round.
                                    </Text>
                                </HStack>
                                <Link _text={{
                                    textAlign: 'left',
                                    fontSize: ['2xs', 'xs', 'sm'],
                                    mt: 2,
                                    color: (colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid')
                                }} onPress={() => setMoreInfoShown(false)}>
                                    Hide info.
                                </Link>
                            </VStack>
                            : <></>
                    }
                </VStack>
            </VStack>
            <HStack justifyContent={'flex-end'} px={[6, 8, 10]}>
                <Box w={['80px', '140px', '200px']} roundedTop={'25px'} overflow={'hidden'}
                     bg={colorMode === 'light' ? 'light.contrast' : 'dark.contrast'} zIndex={'-1'}>
                    <Pressable bg={'transparent'} _pressed={{bg: 'primary'}} _hover={{bg: 'primary'}} p={[3, 4, 5]}
                               onPress={scrollToTotalPoints}>
                        {
                            ({isPressed, isHovered}) => (
                                <Text fontSize={['sm', 'md', 'lg']}
                                      color={isPressed || isHovered ? 'primaryPartner' : colorMode === 'light' ? 'light.contrastPartner' : 'dark.contrastPartner'}>
                                    show total points
                                </Text>
                            )
                        }
                    </Pressable>
                </Box>
            </HStack>
        </VStack>
    );
};

export default ListInfoHeader;