import React from 'react';
import {Box, ColorMode, HStack, Pressable, Text, VStack} from "native-base";
import {LeagueDataType} from "../../tournaments/leagues/api/types";
import {FlatList} from "react-native";

interface LeagueListProps {
    colorMode: ColorMode,
    leaguesList: LeagueDataType[],
    userUid: string,
    viewLeagueCallback: (leagueId: string, tournamentId: string) => void,
}

const LeagueList = ({colorMode, leaguesList, userUid, viewLeagueCallback}: LeagueListProps) => {
    return (
        <VStack flexGrow={1}>
            <Text fontSize={['md', 'lg', 'xl']} textAlign={'left'} mx={'10px'}
                  color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                Your Leagues
            </Text>
            <Box bg={colorMode === 'light' ? 'light.contrastLow' : 'dark.contrastLow'} h={'2px'} mx={'10px'}
                 mb={2}/>
            <FlatList
                data={leaguesList}
                keyExtractor={item => item.id}
                renderItem={({item, index}) => <LeagueInfoItem item={item} index={index} userUid={userUid}
                                                               colorMode={colorMode}
                                                               viewLeagueCallback={viewLeagueCallback}/>}
                ItemSeparatorComponent={() => <Box h={2}/>}
                ListEmptyComponent={() =>
                    <Text mt={4} fontSize={['sm', 'md', 'lg']} textAlign={'left'} mx={'10px'} opacity={0.3}
                          color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                        No leagues joined yet
                    </Text>
                }
                style={{
                    maxHeight: 256
                }}
            />
        </VStack>
    );
};

interface LeagueInfoItemProps {
    item: LeagueDataType,
    index: number,
    userUid: string,
    colorMode: ColorMode,
    viewLeagueCallback: (leagueId: string, tournamentId: string) => void,
}

const LeagueInfoItem = ({item, userUid, colorMode, viewLeagueCallback}: LeagueInfoItemProps) => {

    const playersLength = item.players?.length ?? 0;
    const playerIndex = item.players?.findIndex(player => player.userUid === userUid) ?? -1;

    const handleLeagueView = () => {
        viewLeagueCallback(item.id, item.tournamentId);
    }

    return (
        <Pressable bg={colorMode === 'light' ? 'light.layout' : 'dark.layout'} p={4} pl={['26px', '30px', '34px']}
                   rounded={['10px', '14px', '18px']} _hover={{bg: 'primary'}} _pressed={{bg: 'primary'}}
                   onPress={handleLeagueView} overflow={'hidden'}>
            {
                ({isPressed, isHovered}) => (
                    <>
                        <Text textAlign={'left'} fontSize={['lg', 'xl', '2xl']}
                              color={isPressed || isHovered ? 'primaryPartner' : colorMode === 'light' ? 'light.contrast' : 'dark.contrast'}>
                            {item.name}
                        </Text>
                        <HStack justifyContent={'space-between'}>
                            <Text mr={2} fontSize={'md'}
                                  color={isPressed || isHovered ? 'primaryPartner' : colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                                {playersLength + (playersLength > 1 ? ' players' : ' player')}
                            </Text>
                            <Text fontSize={'md'}
                                  color={isPressed || isHovered ? 'primaryPartner' : colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                                {'# ' + (playerIndex === -1 ? '-' : playerIndex + 1)}
                            </Text>
                        </HStack>
                        <Box position={'absolute'} left={0} top={0} bottom={0} w={['10px', '14px', '18px']}
                             bg={'primary'}/>
                    </>
                )
            }
        </Pressable>
    )
}

export default LeagueList;