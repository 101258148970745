import React, {PropsWithChildren} from 'react';
import {Flex, useColorModeValue} from 'native-base';

const AppBackground = (props: PropsWithChildren<any>) => {
    return (
        <Flex direction='column' bg={useColorModeValue('light.bg', 'dark.bg')} flexGrow={1} {...props}>
            {props.children}
        </Flex>
    );
};

export default AppBackground;