// noinspection ExceptionCaughtLocallyJS

import {createAsyncThunk} from '@reduxjs/toolkit';
import {fb_firestore} from '../../../../../App';
import {doc, getDoc, setDoc} from 'firebase/firestore';
import {StatisticSelectionType} from '../../../leagues/vPlayerBuilding/types';
import {LeagueDataType, PlayerSelectionType} from '../../../leagues/api/types';
import {possibleRoundIds, possibleRoundStates} from '../../../tournament/api/types';

interface UploadAthleteSelectionProps {
    userUid: string,
    leagueInfo: LeagueDataType,
    tournamentId: string,
    selectedRoundId: string,
    selectionList: StatisticSelectionType[]
}

export const uploadAthleteSelection = createAsyncThunk(
    'leagues/uploadAthleteSelection',
    async ({
               userUid,
               leagueInfo,
               tournamentId,
               selectedRoundId,
               selectionList
           }: UploadAthleteSelectionProps, {rejectWithValue}) => {
        try {

            //check round info
            const tournamentInfoRef = doc(fb_firestore, `tournamentsData/${tournamentId}/${selectedRoundId}/infos`);
            const tournamentInfoSnapshot = await getDoc(tournamentInfoRef);

            if (!tournamentInfoSnapshot.exists())
                throw new Error('Cannot upload - Database Error.');

            const tournamentInfo = tournamentInfoSnapshot.data() as { status: possibleRoundStates, startDate: string, endDate: string };

            if (tournamentInfo.status !== possibleRoundStates.open) {
                console.log('round is not open - cannot upload');
                throw new Error('Round is in play already. Cannot upload selection. Please reload.'); //TODO add reload button
            }

            //convert selection for database
            const selection = selectionList.reduce((acc, curr) => {
                return {
                    ...acc,
                    [curr.statistic.id]: curr.selectedAthlete?.id
                };
            }, {});

            const playerSelectionDocRef = doc(fb_firestore, `/leagues/${leagueInfo.id}/players/${userUid}/selection/`, selectedRoundId);
            await setDoc(playerSelectionDocRef, selection);

            //update league Info
            if (leagueInfo.players === undefined || leagueInfo.players.length <= 0) {
                return null;
            }

            //find the player in the array
            const playerIndex = leagueInfo.players.findIndex((p) => p.userUid === userUid);
            if (playerIndex === -1) {
                return null;
            }

            //get previous selection array
            let newSelectionArray: PlayerSelectionType[] = [...leagueInfo.players[playerIndex].selection];
            let updateObject = newSelectionArray.find((s) => s.roundId === selectedRoundId);
            if (updateObject) {
                newSelectionArray[newSelectionArray.indexOf(updateObject)] = {
                    ...updateObject,
                    selection: selectionList.map((s) => {
                        return {
                            statisticId: s.statistic.id,
                            athleteId: s.selectedAthlete?.id ?? ''
                        };
                    })
                };
            } else {
                newSelectionArray.push({
                    roundId: selectedRoundId, selection: selectionList.map((s) => {
                        return {
                            statisticId: s.statistic.id,
                            athleteId: s.selectedAthlete?.id ?? ''
                        };
                    })
                });
            }

            //get the previous players array
            let newPlayersArray = [...leagueInfo.players];
            //update the new selection for the respective player
            newPlayersArray[playerIndex] = {
                //fill in player data like name etc.
                ...leagueInfo.players[playerIndex],
                //add the new selection
                selection: newSelectionArray
            };

            //create new league object
            const newLeagueObject: LeagueDataType = {
                ...leagueInfo,
                players: newPlayersArray,
            };

            return newLeagueObject;

        } catch (e) {
            console.log('uploading player selection error');
            console.log(e.message);
            return rejectWithValue(e.message);
        }
    }
);