import React, {useRef} from 'react';
import AppBackground from '../../../../components/essential/AppBackground';
import {AthleteSelectionScreenProps} from '../../types';
import {
    Box,
    Button,
    Flex,
    Heading,
    Link,
    Text,
    useBreakpointValue,
    useColorMode,
    useTheme,
    VStack
} from 'native-base';
import {FlatList, ScrollView} from 'react-native';
import {StatisticSelectionType} from './types';
import {AthleteDataType} from '../../athletes/api/types';
import {useAppDispatch} from '../../../../app/redux/hooks';
import {setUnsavedChanges, updatePlayerInSelection} from './redux/playerBuildingSlice';
import {AthleteSelectionMatchComponent} from './components/AthleteSelectionComponents';
import {AntDesign} from '@expo/vector-icons';

const AthleteSelectionScreen = ({navigation, route}: AthleteSelectionScreenProps) => {

    const {matches, statisticSelection, isEntry} = route.params;

    //temp
    // const matches = tempMatches;
    // const statisticSelection: StatisticSelectionType = {
    //     statistic: {
    //         id: 'firstServe',
    //         displayName: 'First Serve',
    //         infoText: '',
    //         value: 'percentage',
    //         order: 1,
    //         performanceMultiplier: 1
    //     },
    //     selectedAthlete: null,
    // };

    //helper
    const dispatch = useAppDispatch();
    const {colorMode} = useColorMode();
    const {colors} = useTheme();

    //scroll ref
    const scrollRef = useRef<ScrollView>();

    //selection
    const [selectedAthlete, setSelectedAthlete] = React.useState<AthleteDataType | null>(statisticSelection.selectedAthlete);

    const playerSelectedCallback = (selectedPlayer: AthleteDataType) => {
        setSelectedAthlete(selectedPlayer);
    };

    const confirmButtonPressed = () => {
        if (!selectedAthlete) {
            // safety - but confirm should only be shown when one athlete is selected
        } else if (statisticSelection.selectedAthlete?.id === selectedAthlete.id) {
            navigation.goBack();
        } else {
            //create new selection object
            const newSelectionObj: StatisticSelectionType = {
                statistic: statisticSelection.statistic,
                selectedAthlete,
            };
            //update player in selection storage
            dispatch(setUnsavedChanges(true));
            dispatch(updatePlayerInSelection({id: statisticSelection.statistic.id, changes: newSelectionObj}));
            //navigate back to the player building screen
            navigation.goBack();
        }
    };

    const cancelSelectionCallback = () => {
        navigation.goBack();
    };

    const padding = useBreakpointValue({base: '16px', md: '24px', xl: '32px'});

    return (
        <AppBackground position='relative'>
            <ScrollView style={{height: '90vh'}} ref={ref => scrollRef.current = ref ?? undefined}>
                <VStack flexGrow={1} px={['4', '10', '16']} py={['8', '10', '16']} position='relative' pb={'170px'}>
                    <Flex alignItems='flex-start' mb={4}>
                        <Link onPress={cancelSelectionCallback}>
                            <AntDesign name='arrowleft' size={36}
                                       color={colorMode === 'light' ? colors['light']['contrast'] : colors['dark']['contrast']}/>
                        </Link>
                        <Text fontSize={['sm', 'md', 'lg']}
                              color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                            Select an athlete for
                        </Text>
                        <Heading fontSize={['4xl', '5xl', '6xl']}>
                            {statisticSelection.statistic.displayName}
                        </Heading>
                    </Flex>
                    <Box flexShrink='1' bg={colorMode === 'light' ? 'light.layoutShade' : 'dark.layoutShade'}
                         rounded={['15px', '25px', '35px']} padding={padding} pr='0'
                         maxW='768px'>
                        <FlatList
                            keyExtractor={match => match.matchId}
                            data={matches}
                            extraData={selectedAthlete}
                            renderItem={({item}) => <AthleteSelectionMatchComponent item={item} colorMode={colorMode}
                                                                                    colors={colors} isEntry={isEntry}
                                                                                    selectedAthleteId={selectedAthlete?.id}
                                                                                    playerSelectedCallback={playerSelectedCallback}/>}
                            ItemSeparatorComponent={() => <Box h={isEntry ? 2 : 16}/>}
                            style={{}}
                            contentContainerStyle={{
                                // alignItems: 'center',
                                paddingRight: '5px',
                            }}
                        />
                    </Box>
                </VStack>
            </ScrollView>
            {/* Confirm Overlay */}
            {
                selectedAthlete ?
                    <Flex position='absolute' bottom='0' left={['1px', '25px', '49px']} right={['1px', '25px', '49px']}
                          maxW={'798px'} roundedTop={['15px', '25px', '35px']}
                          bg={colorMode === 'light' ? 'light.contrast' : 'dark.contrast'}
                          p={['4', '6', '8']} alignItems='stretch' justifyContent='flex-start'>
                        <Button flexGrow='1' variant={'attention'}
                                onPress={confirmButtonPressed} mb='2'>
                            confirm
                        </Button>
                        <Text fontSize={['sm', 'md', 'lg']} px='6'
                              color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                            {`${selectedAthlete.name} for ${statisticSelection.statistic.displayName}`}
                        </Text>
                    </Flex>
                    : <></>
            }
        </AppBackground>
    );
};

//temp
const tempMatches = [
    {
        matchId: '34',
        athleteOne: {
            id: 'TomMac',
            name: 'Tomas Machac'
        },
        athleteTwo: {
            id: 'RafNad',
            name: 'Rafael Nadal'
        },
    },
    {
        matchId: '56',
        athleteOne: {
            id: 'RogFed',
            name: 'Roger Federer'
        },
        athleteTwo: {
            id: 'NovDjo',
            name: 'Novak Djokovic'
        },
    },
    {
        matchId: '78',
        athleteOne: {
            id: 'AleZve',
            name: 'Alexander Zverev'
        },
        athleteTwo: {
            id: 'TomMac2',
            name: 'Tomas Machac'
        },
    },
    {
        matchId: '910',
        athleteOne: {
            id: 'RafNad2',
            name: 'Rafael Nadal'
        },
        athleteTwo: {
            id: 'RogFed2',
            name: 'Roger Federer'
        },
    },
    {
        matchId: '1112',
        athleteOne: {
            id: 'RafNad3',
            name: 'Rafael Nadal'
        },
        athleteTwo: {
            id: 'RogFed3',
            name: 'Roger Federer'
        },
    },
    {
        matchId: '1314',
        athleteOne: {
            id: 'RafNad4',
            name: 'Rafael Nadal'
        },
        athleteTwo: {
            id: 'RogFed4',
            name: 'Roger Federer'
        },
    },
    {
        matchId: '1516',
        athleteOne: {
            id: 'RafNad5',
            name: 'Rafael Nadal'
        },
        athleteTwo: {
            id: 'RogFed5',
            name: 'Roger Federer'
        },
    },
    {
        matchId: '1718',
        athleteOne: {
            id: 'RafNad6',
            name: 'Rafael Nadal'
        },
        athleteTwo: {
            id: 'RogFed6',
            name: 'Roger Federer'
        },
    },
    {
        matchId: '1920',
        athleteOne: {
            id: 'RafNad7',
            name: 'Rafael Nadal'
        },
        athleteTwo: {
            id: 'RogFed7',
            name: 'Roger Federer'
        },
    },
    {
        matchId: '2122',
        athleteOne: {
            id: 'RafNad8',
            name: 'Rafael Nadal'
        },
        athleteTwo: {
            id: 'RogFed8',
            name: 'Roger Federer'
        },
    },
];

export default AthleteSelectionScreen;