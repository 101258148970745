// noinspection ExceptionCaughtLocallyJS

import {createAsyncThunk} from '@reduxjs/toolkit';
import {doc, setDoc, arrayUnion} from 'firebase/firestore';
import {fb_firestore} from '../../../../../App';
import {LeagueDataType} from '../types';

interface AddPlayerToLeagueProps {
    leagueInfo: LeagueDataType,
    userUid: string,
    playerName: string
}

export const addPlayerToLeague = createAsyncThunk(
    'leagues/addPlayerToLeague',
    async ({
               leagueInfo,
               userUid,
               playerName
           }: AddPlayerToLeagueProps, {rejectWithValue}) => {
        try {
            //create new player
            const leaguePlayerRef = doc(fb_firestore, '/leagues/' + leagueInfo.id + '/players/', userUid);
            const newPlayerData = {
                playerName: playerName,
            };
            await setDoc(leaguePlayerRef, newPlayerData, {merge: true});

            //update player storage
            const playerStorageRef = doc(fb_firestore, `/users/${userUid}`);
            // await setDoc(playerStorageRef, {joinedLeagues: [leagueInfo.id]}, {merge: true});
            await setDoc(playerStorageRef, {
                joinedLeagues: arrayUnion(leagueInfo.id)
            }, {merge: true});

            //update league Info
            if (leagueInfo.players === undefined) {
                throw Error('no players found');
            }

            //get the previous players array
            let newPlayersArray = [...leagueInfo.players];
            //update the new selection for the respective player
            newPlayersArray.push({
                userUid: userUid,
                playerName: playerName,
                selection: [],
                autoSelection: [],
                performance: [],
                currentScore: 0,
            });

            //create new league object
            const newLeagueObject: LeagueDataType = {
                ...leagueInfo,
                players: newPlayersArray,
            };

            return newLeagueObject;
        } catch (e) {
            console.log('adding player to league error');
            console.log(e.message);
            console.log(e.code);
            return rejectWithValue(e.message);
        }
    }
);