import React from 'react';
import {Button, Container, Heading, Text, VStack} from 'native-base';
import {sendEmailVerification, signOut} from 'firebase/auth';
import {possibleAuthComponents} from '../types';
import {LimitUserName} from 'app/helper/mainHelper';
import {useAppSelector} from '../../../app/redux/hooks';
import {fb_auth} from "../../../App";

type VerifyEmailComponentProps = {
    switchAuthComponent: (newState: possibleAuthComponents) => void,
}

const VerifyEmailComponent = ({switchAuthComponent}: VerifyEmailComponentProps) => {

    const userName = useAppSelector(state => state.auth.user.userName);

    const gotoLoginButtonCallback = () => {
        //sign out
        signOutUser().then(() => {
            switchAuthComponent(possibleAuthComponents.login);
        });
    };

    const reSendEmailVerification = async () => {
        const user = await fb_auth.currentUser;
        if (user) {
            await sendEmailVerification(user);
        }
    }

    const signOutUser = async () => {
        return signOut(fb_auth)
            .catch((error) => {
                console.log('error during sign out:');
                console.log(error.message);
                return;
            });
    };

    //check username
    let checkedUserName = LimitUserName(userName);

    return (
        <Container centerContent={true} alignItems='stretch' alignSelf='center' maxWidth='512px'>
            <VStack space='2' mb='10'>
                <Text textAlign='left'>
                    Thanks,
                </Text>
                <Heading textAlign='left' fontSize={['3xl', '4xl', '5xl']} mb='-2'>
                    {checkedUserName}
                </Heading>
                <Text textAlign='left'>
                    Reload the page after clicking on the verify email link sent to you.
                </Text>
            </VStack>
            <VStack space='2'>
                <Button onPress={reSendEmailVerification}>
                    Resend email
                </Button>
                <Button variant='secondary' onPress={gotoLoginButtonCallback}>
                    Go back to login
                </Button>
            </VStack>
        </Container>
    );
};

export default VerifyEmailComponent;