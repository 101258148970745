import {possibleRoundStates, RoundDataType} from '../../../../tournament/api/types';
import {Box, Button, ColorMode, Flex, HStack, Text} from 'native-base';
import React from 'react';
import {JoinedPlayerType} from '../../../api/types';
import {AntDesign} from '@expo/vector-icons';

interface PlayerListRoundInfoHeaderProps {
    selectedRound: RoundDataType | null,
    colorMode: ColorMode,
    colors: any,
    playerInfo: JoinedPlayerType | undefined,
    isPlayerReady: boolean,
    buildPlayerCallback: (playerInfo: JoinedPlayerType) => void,
}

export const PlayerListRoundInfoHeader = ({
                                              selectedRound,
                                              colorMode,
                                              colors,
                                              playerInfo,
                                              isPlayerReady,
                                              buildPlayerCallback
                                          }: PlayerListRoundInfoHeaderProps) => {

    if (selectedRound?.status === possibleRoundStates.open) {
        return <OpenRoundInfoHeader colorMode={colorMode} playerInfo={playerInfo} isPlayerReady={isPlayerReady}
                                    buildPlayerCallback={buildPlayerCallback} colors={colors}/>;
    }

    let text = '';
    let subtext = '';

    let borderColor = 'transparent';

    if (selectedRound === null || selectedRound?.status === possibleRoundStates.upcoming) {
        text = 'Round has not started yet';
        subtext = 'Please wait until the match schedule is available to start building your player.';
        borderColor = colorMode === 'light' ? 'light.warning' : 'dark.warning';
    }

    switch (selectedRound?.status) {
        case possibleRoundStates.playingActive:
            text = 'Round is live';
            subtext = 'The round is currently being played by the athletes.'; //TODO click to view selection
            borderColor = colorMode === 'light' ? 'light.error' : 'dark.error';
            break;
        case possibleRoundStates.completed:
            text = 'Round completed';
            subtext = 'The round has been completed and scores have been calculated';
            borderColor = colorMode === 'light' ? 'light.contrastLow' : 'dark.contrastLow';
            break;
    }

    return (
        <Box p='5' rounded={'25px'} mb='10' borderWidth='3' borderColor={borderColor}>
            <Text fontSize='xl' textAlign='left'
                  color={colorMode === 'light' ? 'light.contrast' : 'dark.contrast'}>
                {text}
            </Text>
            <Text fontSize='md' textAlign='left'
                  color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                {subtext}
            </Text>
        </Box>
    );
};

interface OpenRoundInfoHeaderProps {
    colorMode: ColorMode,
    playerInfo: JoinedPlayerType | undefined,
    isPlayerReady: boolean,
    buildPlayerCallback: (playerInfo: JoinedPlayerType) => void,
    colors: any,
}

const OpenRoundInfoHeader = ({
                                 playerInfo,
                                 isPlayerReady,
                                 buildPlayerCallback,
                                 colorMode,
                                 colors
                             }: OpenRoundInfoHeaderProps) => {

    const handleBuildPlayer = () => {
        if (playerInfo)
            buildPlayerCallback(playerInfo);
    };

    return (
        <Flex p={8} rounded={'25px'} mb='10'
              bg={isPlayerReady ? 'transparent' : colorMode === 'light' ? 'light.contrast' : 'dark.contrast'}>
            <HStack space={3} alignItems='flex-start'>
                <Text fontSize='3xl' textAlign='left' lineHeight='1em'
                      color={colorMode === 'light' ? (isPlayerReady ? 'light.contrast' : 'light.contrastPartner') : (isPlayerReady ? 'dark.contrast' : 'dark.contrastPartner')}>
                    {
                        isPlayerReady ?
                            'You are ready to play!' : 'Player building is ready!'
                    }
                </Text>
                {
                    isPlayerReady ?
                        <Box>
                            <AntDesign name='checkcircle' size={26}
                                       color={colorMode === 'light' ? colors['light']['success'] : colors['dark']['success']}/>
                        </Box>
                        :
                        <></>
                }
            </HStack>
            <Text fontSize='md' textAlign='left' mt={2}
                  color={colorMode === 'light' ? 'light.contrastMid' : 'dark.contrastMid'}>
                {
                    isPlayerReady ?
                        'Your selection is ready for the next round. If you would like to change it, click below' : 'The round is open, the selection is available, click below to begin.'
                }
            </Text>
            <Button variant={isPlayerReady ? 'default' : 'attention'} mt={8} onPress={handleBuildPlayer}>
                {
                    isPlayerReady ? 'Change selection' : 'Build Player'
                }
            </Button>
        </Flex>
    );
};
