import {createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import {RootState} from 'app/redux/store';
import {possibleTournamentDataState, TournamentDataType} from './types';
import {fetchTournamentData} from './functions/fetchTournamentData';

const tournamentDataAdapter = createEntityAdapter<TournamentDataType>({
    selectId: (tournamentData: TournamentDataType) => tournamentData.id,
});

const initialState = tournamentDataAdapter.getInitialState({
    status: possibleTournamentDataState.idle,
    error: '',
});

const tournamentDataSlice = createSlice({
    name: 'tournamentData',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchTournamentData.pending, (state) => {
            state.status = possibleTournamentDataState.loading;
        });
        builder.addCase(fetchTournamentData.fulfilled, (state, action) => {
            state.status = possibleTournamentDataState.succeeded;
            tournamentDataAdapter.upsertOne(state, action.payload as TournamentDataType);
        });
        builder.addCase(fetchTournamentData.rejected, (state, action) => {
            state.status = possibleTournamentDataState.failed;
            state.error = String(action.payload);
        });
    },
});

export const {
    selectAll: getAllTournamentData,
    selectById: getTournamentDataById,
} = tournamentDataAdapter.getSelectors<RootState>(state => state.tournamentData);

export default tournamentDataSlice.reducer;