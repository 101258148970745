import {createSlice} from '@reduxjs/toolkit';

/**
 * Auth State
 */
type authState = {
    userLoggedIn: boolean,
    user: { uid: string, userName: string, emailVerified: Boolean },
}

/**
 * Initial Auth State Object
 */
const initialState: authState = {
    userLoggedIn: false,
    user: {uid: '', userName: '', emailVerified: false},
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        userLoggedIn(state, action) {

            if (state.userLoggedIn)
                return;

            const {uid, emailVerified, userName} = action.payload;

            // console.log(`user signed in ${uid} - emailVerified? ${emailVerified}`);

            state.userLoggedIn = true;
            state.user.uid = uid;
            state.user.userName = userName;
            state.user.emailVerified = emailVerified;
        },
        userLoggedOut(state) {

            if (!state.userLoggedIn)
                return;

            state.userLoggedIn = false;

            state.user.uid = '';
            state.user.userName = '';
            state.user.emailVerified = false;
        }
    },
});

export default authSlice.reducer;

export const {userLoggedIn, userLoggedOut} = authSlice.actions;