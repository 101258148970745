import React, {PropsWithChildren} from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

//style
import {NativeBaseProvider, ColorMode, StorageManager} from 'native-base';

//custom
import {appTheme} from "../appTheme";
import {nativeBaseConfig} from "../appConfig";


/**
 * Component to wrap and provide the native-base-plugin provider
 * @param props
 * @constructor
 */
const AppThemeProvider: React.FC = (props: PropsWithChildren<any>) => {

    //the color mode storage
    const colorModeManager: StorageManager = {
        get: async () => {
            try {
                let val = await AsyncStorage.getItem('@v-fantasy-tennis-color-mode');
                return val === 'dark' ? 'dark' : 'light';
            } catch (e) {
                console.log("THEME: cannot load color mode (light, dark) from async storage. \nDefaulting to light mode")
                return 'light';
            }
        },
        set: async (value: ColorMode) => {
            try {
                await AsyncStorage.setItem('@v-fantasy-tennis-color-mode', value!);
            } catch (e) {
                console.log("THEME: cannot save color mode (light, dark) in async storage.")
            }
        },
    };

    //the component with the native base provider
    return (
        <NativeBaseProvider theme={appTheme} config={nativeBaseConfig} colorModeManager={colorModeManager}>
            {props.children}
        </NativeBaseProvider>
    );
};

export default AppThemeProvider