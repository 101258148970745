import {createAsyncThunk} from '@reduxjs/toolkit';
import {collection, getDocs, query} from 'firebase/firestore';
import {fb_firestore} from '../../../../../App';
import {AthleteDataType} from '../types';

export const getAthleteInfo = createAsyncThunk(
    'athletes/getInfo',
    async (empty, {rejectWithValue}) => {
        try {
            //query for all athletes
            const q = query(collection(fb_firestore, 'athletes'));
            const querySnapshot = await getDocs(q);

            const athletes: AthleteDataType[] = [];
            querySnapshot.forEach((doc) => {
                const athlete = doc.data();
                athletes.push({
                    id: doc.id,
                    name: athlete.name
                });
            });

            return athletes;
        } catch (e) {
            console.log('error in athlete fetch');
            console.log(e);
            return rejectWithValue(e);
        }
    }
);