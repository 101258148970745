import {Alert, AlertButton, AlertOptions, Platform} from 'react-native';

const alertPolyfill = (title: string, description: string, buttons: AlertButton[], extra: AlertOptions) => {
    window.alert([title, description].filter(Boolean).join('\n'));
    if (buttons.length > 0)
        buttons[0].onPress && buttons[0].onPress();
};

const hybridAlert = Platform.OS === 'web' ? alertPolyfill : Alert.alert;

export default hybridAlert;